import React from 'react'
import styles from './TableRegularizeInsuranceContract.module.css';
import moment from 'moment';
const TableRegularizeInsuranceContract = ({ data }) => {

    const typeCodeTranslationMap = {
        OLD: "Antigo",
        NEW: "Novo",
    };


    const actionTranslationMap = {
        CONTRACT: "Contrato",
        CANCELLATION: "Cancelamento",
    };
    return (
        <div className={styles.divContainer}>
            <div className={styles.table}>
                <div className={`${styles.row} ${styles.header}`}>
                    <div className={styles.cell}>
                        Status
                    </div>
                    <div className={styles.cell}>
                        Tipo
                    </div>
                    <div className={styles.cell}>
                        Ação
                    </div>
                    <div className={styles.cell}>
                        Status
                    </div>
                    <div className={styles.cell}>
                        Data
                    </div>
                    <div className={styles.cell}>
                        Mensagem
                    </div>
                </div>

                {data && data.map((currentData) => (
                    <div className={styles.rowCell} key={currentData?.certificateCode}>
                        <div className={styles.cell}>
                            {currentData?.certificateCode}
                        </div>
                        <div className={styles.cell}>
                            {currentData?.typeCode && typeCodeTranslationMap[currentData.typeCode]}
                        </div>
                        <div className={styles.cell}>
                            {currentData?.action && actionTranslationMap[currentData.action]}
                        </div>
                        <div className={styles.cell}>
                            {currentData.status.name}
                        </div>
                        <div className={styles.cell}>
                            {currentData?.date && moment(currentData?.date).format('DD/MM/YYYY HH:mm')}
                        </div>
                        <div className={styles.cell}>
                            {currentData?.message?.descriptionDetails}
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default TableRegularizeInsuranceContract