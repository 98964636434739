import { useContext, useEffect, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import styles from './DocumentScore.module.css';
import Tab from '../../tab/Tab';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';
import { AiFillFilePdf } from 'react-icons/ai';
import { RoleContext } from '../../hooks/HookUseContext';
import api from '../../../services/api';
import moment from 'moment';

const DocumentScore = ({ data, proposalData }) => {
    const [loading, setLoading] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const { roleValue } = useContext(RoleContext);
    const [documentScoreData, setDocumentScoreData] = useState(null);

    const getDocumentScore = async () => {
        try {
            setLoading(true)
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SCORE_DETAILS}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setLoading(false);
                setDocumentScoreData(response?.data)
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getDocumentScore();
    }, [])

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }


    const downloadBase64 = async (id, name) => {
        localStorage.setItem('n', name);
        viewPDF(id);
    }

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/QDO`, '_blank', 'noopener,noreferrer');
    }

    const documentScoreInformation = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Status</p>
                            {documentScoreData?.status?.code === "PENDING" && <strong style={{ color: "#f29339" }}>{documentScoreData?.status?.description?.toUpperCase()}</strong>}
                            {documentScoreData?.status?.code === "REJECT" && <strong style={{ color: "#900020" }}>{documentScoreData?.status?.description?.toUpperCase()}</strong>}
                            {documentScoreData?.status?.code === "ERROR" && <strong style={{ color: "#ad0026" }}>{documentScoreData?.status?.description?.toUpperCase()}</strong>}
                            {documentScoreData?.status?.code === "APPROVE" && <strong style={{ color: "#109010" }}>{documentScoreData?.status?.description?.toUpperCase()}</strong>}
                        </div>
                        <div>
                            <p>Data da consulta</p>
                            <strong>{documentScoreData?.date && moment(documentScoreData?.date).format('DD/MM/YYYY HH:mm')}</strong>
                        </div>

                        {documentScoreData?.sentNextFlow === true && (
                            <div>
                                <p>Etapa libera por limite de erros?</p>
                                <strong>SIM</strong>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.involveds}>
                    <h3>Envolvidos</h3>
                    {documentScoreData && documentScoreData?.involveds ? documentScoreData?.involveds?.map((involved) => (
                        <div className={styles.involved} key={involved?.cpfCnpj}>
                            <div>
                                <p>Nome</p>
                                <strong>{involved?.name}</strong>
                            </div>
                            <div>
                                <p>Cpf/Cnpj </p>
                                <strong>{formatCnpjCpf(involved?.cpfCnpj)}</strong>
                            </div>
                            <div>
                                <p>Score </p>
                                {Number(involved?.score?.number) >= 0 ?
                                    <strong className={`${styles.successText}`}>{involved?.score?.number}</strong> :
                                    <strong className={`${styles.errorText}`}>{involved?.score?.number}</strong>
                                }
                            </div>
                            <div>
                                <p>Status </p>
                                {involved?.score && involved?.score?.number ?
                                    <strong>{Number(involved?.score?.number) >= 0 ?
                                        <p className={`${styles.circle} ${styles.success}`}></p> :
                                        <p className={`${styles.circle} ${styles.error}`}></p>
                                    }</strong>
                                    : <strong>{involved?.score?.status}</strong>}
                            </div>
                        </div>
                    )) : 'Nenhum envolvido'}
                </div>

            </div>
        )
    }


    const documentScoreDocuments = () => {
        return (
            <div className={stylesModal.content}>
                {roleValue?.includes("DOCUMENT_SCORE_READ") && documentScoreData && documentScoreData?.documents ? documentScoreData?.documents.map((doc, index) => (
                    <div className={styles.actionButtonsPDF} key={doc?.id || index}>
                        {!loadingDocument ? (
                            <button onClick={() => downloadBase64(doc?.id, doc?.name)}>
                                <AiFillFilePdf />
                                {doc?.documentType?.description}
                            </button>
                        ) : <button disabled>Aguarde...</button>}
                    </div>
                )) : 'Nenhum documento'}
            </div>
        )
    }

    return (
        <div className={styles.documentScoreContainer}>
            {loading && <Loading />}

            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Informações',
                        'Documentos'
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{documentScoreInformation()}</>,
                        <>{documentScoreDocuments()}</>
                    ]}
                />
            </>)}
        </div>
    )
}

export default DocumentScore