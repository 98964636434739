import Container from "../container/Container";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { checkJwt, jwtDecrypt } from "../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";
import Logo from "../../../img/logo.png";
import jwt_decode from "jwt-decode";
// useContext
import { useContext } from "react";
import { RoleContext } from "../../hooks/HookUseContext";
import loginAxios from "../../../services/loginAxios";

function Header() {
  const { roleValue, setRoleValue } = useContext(RoleContext);
  const navigate = useNavigate();
  const [userActive, setUserActive] = useState(
    localStorage.getItem("token") !== undefined
  );
  const { name } = jwtDecrypt();

  const logoutProperties = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("app:token-auth");
    setRoleValue("");
    setUserActive(false);
    navigate("/login");
  }, [navigate, setRoleValue]);

  const logout = useCallback(async () => {
    let data = new URLSearchParams({
      client_id: process.env.REACT_APP_CLOAK_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLOAK_CLIENT_SECRET,
      refresh_token: localStorage.getItem("refresh_token"),
    }).toString();

    await loginAxios.post(
      `${process.env.REACT_APP_CLOAK_PATH_BASE}${process.env.REACT_APP_CLOAK_PATH_LOGOUT}`,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    logoutProperties();
  }, [logoutProperties]);

  const getRoleWithFunctinalitiesAndTokenFromApplication =
    useCallback(async () => {
      try {
        const bearer_token = `Bearer ${localStorage.getItem("token")}`;

        if (!localStorage.getItem("token")) return;
        const config = {
          headers: {
            Authorization: bearer_token,
            "Access-Control-Allow-Origin": "*",
            system: process.env.REACT_APP_SYSTEM_ID,
          },
        };
        await loginAxios
          .get(
            `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_AUTH_TOKEN_DERIVE}`,
            config
          )
          .then((response) => {
            const { permissions } = jwt_decode(response?.data.access_token);
            setRoleValue(permissions);
            localStorage.setItem("app:token-auth", response?.data.access_token);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            localStorage.clear();
            console.log(error);
            setRoleValue(null);
            setUserActive(false);
            navigate("/login");
          });
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.clear();
        console.log(error);
        console.log(error.response);
        setRoleValue(null);
        setUserActive(false);
        navigate("/login");
      }
    }, [setRoleValue, navigate]);

  const checkToken = useCallback(() => {
    checkJwt(navigate).then((response) => {
      setUserActive(response);
    });
  }, [navigate]);

  useEffect(() => {
    if (roleValue) {
      checkToken();
    } else {
      getRoleWithFunctinalitiesAndTokenFromApplication();
    }
  }, [
    navigate,
    roleValue,
    checkToken,
    getRoleWithFunctinalitiesAndTokenFromApplication,
  ]);

  return (
    <header>
      <nav className={styles.navbar}>
        <Container>
          <img src={Logo} alt="logo" />
          {userActive && (
            <ul className={styles.list}>
              {roleValue?.includes("PROPOSAL_READ") && (
                <li onClick={checkToken} className={styles.item}>
                  <NavLink
                    to="/monitoring"
                    end
                    className={({ isActive }) =>
                      isActive ? styles.active : ""
                    }
                  >
                    Monitoramento
                  </NavLink>
                </li>
              )}
              {roleValue?.includes("COMBAT_READ") && (
                <li onClick={checkToken} className={styles.item}>
                  <NavLink
                    to="/combat"
                    end
                    className={({ isActive }) =>
                      isActive ? styles.active : ""
                    }
                  >
                    Combate
                  </NavLink>
                </li>
              )}

              {roleValue?.includes("INCIDENTS_READ") && (
                <li onClick={checkToken} className={styles.item}>
                  <NavLink
                    to="/incidents"
                    end
                    className={({ isActive }) =>
                      isActive ? styles.active : ""
                    }
                  >
                    Incidentes
                  </NavLink>
                </li>
              )}

              {(roleValue?.includes("UNAVAILABILITY_READ") ||
                roleValue?.includes("STORE_READ") ||
                roleValue?.includes("COMPANY_READ")) && (
                <li className={`${styles.dropdown}`}>
                  <p className={styles.dropbtn}>Configurações</p>
                  <div className={styles.dropdownContent}>
                    {/* <NavLink to='/insurancePlan' className={({ isActive }) => isActive ? styles.active : ''}>Plano de seguros</NavLink>
                                        <NavLink to='/fromTo' className={({ isActive }) => isActive ? styles.active : ''}>De para</NavLink>
                                        <NavLink to='/parameter' className={({ isActive }) => isActive ? styles.active : ''}>Parâmetros</NavLink>
                                        <NavLink to='/product' className={({ isActive }) => isActive ? styles.active : ''}>Produtos</NavLink> */}
                    {roleValue?.includes("UNAVAILABILITY_READ") && (
                      <NavLink
                        to="/unavailability"
                        className={({ isActive }) =>
                          isActive ? styles.active : ""
                        }
                      >
                        Indisponibilidades
                      </NavLink>
                    )}
                    {((roleValue?.includes("STORE_READ") ||
                      roleValue?.includes("COMPANY_READ")) && (
                        <NavLink
                          to="/business-directory"
                          className={({ isActive }) =>
                            isActive ? styles.active : ""
                          }
                        >
                          Lojas/Companhias
                        </NavLink>
                      ))}
                  </div>
                </li>
              )}
            </ul>
          )}

          {roleValue && (
            <div className={styles.userOptions}>
              <p className={styles.leave} onClick={logout}>
                Sair
              </p>
              {name && (
                <div className={styles.tooltip}>
                  <p>{name.substr(0, 1).toUpperCase()}</p>
                  <span className={styles.tooltiptext}>
                    {name.toLowerCase()}
                  </span>
                </div>
              )}
            </div>
          )}
        </Container>
      </nav>
    </header>
  );
}

export default Header;
