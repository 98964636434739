import { useState } from 'react';
import Modal from 'react-responsive-modal';
import styles from './ViewDocumentsModal.module.css';
import { AiFillFilePdf, AiFillFileImage } from 'react-icons/ai'
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import 'react-toastify/dist/ReactToastify.min.css';
import { useEffect } from 'react';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';

// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';

function ViewDocumentsModal({ data, documentsBbc }) {
    const { roleValue } = useContext(RoleContext);
    const navigate = useNavigate();

    const [solicitations, setSolicitations] = useState(null);


    const checkTokenViewDocument = (id, isPdf) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewDocument(id, isPdf);
            }
        })
    }

    useEffect(() => {
        async function getSolicitations() {
            try {
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_SOLICITATIONS}` + data?.id, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    setSolicitations(response?.data?.object)
                })
                    .catch((error) => {
                        console.log(error)
                        setSolicitations([])
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }
        getSolicitations();
    }, [data, documentsBbc])

    async function viewDocument(id, isPdf) {
        if (isPdf) {
            window.open(`../viewpdf/${id}/SIG`, '_blank', 'noopener,noreferrer');
        } else {
            window.open(`../viewimage/${id}`, '_blank', 'noopener,noreferrer');
        }
    }

    return (
        <div className={`${styles.modalContainer} contentModal`}>
            <div className={`${styles.documents}`}>
                <div>
                    <h3 className={styles.tittle}>Documentos BBC</h3>
                    <div className={styles.actionButtonsPDF}>
                        {(roleValue?.includes("DOCUMENT_CLIENT_READ")) && documentsBbc.length > 0 && documentsBbc.map((document) => (
                            <button key={document?.id} onClick={() => checkTokenViewDocument(document?.id, true)}>
                                <AiFillFilePdf />
                                {document?.documentType?.description}
                            </button>
                        ))}
                        {(roleValue?.includes("DOCUMENT_CLIENT_READ")) && (!documentsBbc || documentsBbc.length === 0) && "Não há documentos"}
                    </div>
                </div>
                <div>
                    <h3 className={styles.tittle}>Documentos do Cliente</h3>
                    <div className={styles.actionButtonsPDF}>
                        {solicitations?.length > 0 ? solicitations?.map((solicitation) => (
                            solicitation?.signatureResponse?.length > 0 && solicitation?.signatureResponse?.map((response) => (
                                response?.signatureResponseDocument?.length > 0 && response?.signatureResponseDocument?.map((document) => (
                                    (roleValue?.includes("DOCUMENT_CLIENT_READ")) && (
                                        document?.id?.document?.extension.includes("pdf") ? (
                                            <button className={styles.pdf} key={document?.id?.document?.id} onClick={() => checkTokenViewDocument(document?.id?.document?.id, true)}>
                                                <AiFillFilePdf />
                                                {`${response?.cpf} - ${document?.id?.document?.documentType?.description}`}
                                            </button>
                                        ) : (
                                            <button className={styles.image} key={document?.id?.document?.id} onClick={() => checkTokenViewDocument(document?.id?.document?.id, false)}>
                                                <AiFillFileImage />
                                                {`${response?.cpf} - ${document?.id?.document?.documentType?.description}`}
                                            </button>

                                        )
                                    )
                                ))
                            ))
                        )) : solicitations === null ? "Carregando..." : (solicitations?.length === 0) && 'Não há documentos'}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDocumentsModal