import axios from 'axios'

const api  = axios.create({
})

api.interceptors.request.use(
    config => {
      // Do something before request is sent
  
      config.headers["Authorization"] = "Bearer " + localStorage.getItem("app:token-auth");
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

export default api;