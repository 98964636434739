import React, { useState } from 'react'
import ModalChangeStatus from '../../../modalChangeStatus/ModalChangeStatus';
import styles from './ChangeStatusSelect.module.css';
import api from '../../../../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ChangeStatusSelect = ({ item, selectOptionChangeStatus, setLoading, dataObject, setDataObject }) => {

    const [selectedValue, setSelectedValue] = useState('');
    const [tempValue, setTempValue] = useState('');
    const [modalShowConfirmation, setModalShowConfirmation] = useState(false);
    const [itemChange, setItemChange] = useState(null);
    const confirmChange = (confirm) => {
        if (confirm) {
            changeStatusAction(itemChange?.proposalCode, tempValue);
        }
        setTempValue('');
    };

    async function changeStatusAction(id, changeToStatus) {
        setSelectedValue(tempValue);
        putChangePartner(item, changeToStatus)
    }

    const handleChange = (event, item) => {
        if (event.target.value === "") {
            setItemChange(null)
            setSelectedValue(event.target.value);
            setTempValue('');
        } else {
            setItemChange(item)
            setTempValue(event.target.value);
            setModalShowConfirmation(true);
        }
    };


    const statusNameTranslate = (status) => {
        const statusTraduzidos = {
            PENDING: 'Pendente',
            SENT: 'Enviado',
            ERROR: 'Erro',
            CONCLUDED: 'Concluído'
        };

        return statusTraduzidos[status] || status;
    }

    const putChangePartner = async (data, newStatus) => {
        try {
            await api.put(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PARTNER_NOTIFIER_CHANGE_STATUS_ATTEMPTS}${data?.proposalCode}/${newStatus}`
            ).then((response) => {
                const indexToUpdate = dataObject.content.findIndex(obj => obj.proposalCode === data?.proposalCode);

                if (indexToUpdate !== -1) {
                    const updatedContent = [...dataObject.content];
                    updatedContent[indexToUpdate] = response?.data;
                    setDataObject({ ...dataObject, content: updatedContent });
                }
                toast.success("Status atualizado com sucesso!");
            }).catch((error) => {
                console.log(error)
                toast.error(error?.response?.data?.message || error?.response?.data);
            })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }
    return (
        <>
            {modalShowConfirmation === true && <ModalChangeStatus
                setOpenModal={setModalShowConfirmation}
                methodAction={confirmChange}
                itemChange={itemChange}
            />}
            <label className={styles.container}>
                <select value={selectedValue} onChange={(e) => handleChange(e, item)}>
                    <option value="">Selecione um status</option>
                    {selectOptionChangeStatus.map((option) =>
                        <option key={option} value={option}>{statusNameTranslate(option)}</option>
                    )}
                </select>
            </label>
        </>
    )
}

export default ChangeStatusSelect