import { FcHome } from 'react-icons/fc';
import styles from './Home.module.css';

function Home (){
    return (
        <div className={styles.homeContainer}>
            <FcHome/>
            Home
        </div>
    );
}

export default Home;