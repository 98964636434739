import styles from './ModalCancelContract.module.css'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import { useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai'
import api from '../../../services/api';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useRef } from 'react';


function ModalCancelContract({ setOpenModal, data, updateData, setRemoveLoading }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const buttonStartRef = useRef(null);

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }

    const cancel = (TYPE) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                cancelProposalPatch(data.id, TYPE);
            }
        })
    }

    async function cancelProposalPatch(id, TYPE) {
        setRemoveLoading(false);
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_CANCEL}${id}/${TYPE}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            toast.success(`${response?.data?.message}`);
            updateData(response?.data?.object, TYPE, 200);
            setRemoveLoading(true);
            closedModal();
        }).catch((error) => {
            setRemoveLoading(true);
            console.log(error);
            updateData(error?.response?.data?.object, TYPE, 0);
            closedModal();
            toast.error(`Deu erro! ${error?.response?.data?.message}`);
        })
    }
    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalCancelContainer} onLoad={() => buttonStartRef.current.focus()}>
                <AiOutlineWarning />
                <h2>Você está cancelando um seguro!</h2>
                <p>Você tem certeza que deseja cancelar o seguro da </p>
                <strong>proposta {data?.data?.proposalCode}?</strong>
                <div className={styles.actionsButtons}>
                    <button onClick={closedModal} selected ref={buttonStartRef} onLoadStart={() => buttonStartRef.current.focus()}>Não quero cancelar</button>
                    {<button onClick={() => cancel("ASSURANT")}>Sim, cancelar pela Assurant!</button>}
                    <button onClick={() => cancel("BBC")}>Sim, cancelar pela BBC!</button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCancelContract;