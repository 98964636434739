import React, { useState } from 'react'
import ModalChangeStatus from '../modalChangeStatus/ModalChangeStatus';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const ChangeStatusSelect = ({ item, selectOptionChangeStatus, updatedProposalStage, setLoading, updateProposalStage }) => {

    const [selectedValue, setSelectedValue] = useState('');
    const [tempValue, setTempValue] = useState('');
    const [modalShowConfirmation, setModalShowConfirmation] = useState(false);
    const [itemChange, setItemChange] = useState(null);

    const confirmChange = (confirm, itemChange) => {
        if (confirm) {
            changeStatusAction(itemChange?.id?.proposalId, tempValue, itemChange?.stages?.code);
        }
        setTempValue('');
    };

    async function changeStatusAction(id, changeToStatus, stages) {
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_CHANGE_STATUS_PROPOSAL_STAGES}${id}/${changeToStatus}/${stages}`, {
        }).then((response) => {
            toast.success('Status atualizado sucesso')
            setSelectedValue(tempValue);
            updatedProposalStage(id, stages, setLoading);
        }).catch((error) => {
            console.log(error);
            toast.error("Deu erro ao atualizar status")
        })
    }

    const handleChange = (event, item) => {
        if (event.target.value === "") {
            setItemChange(null)
            setSelectedValue(event.target.value);
            setTempValue('');
        } else {
            setItemChange(item)
            setTempValue(event.target.value);
            setModalShowConfirmation(true);
        }
    };
    return (
        <>
            {modalShowConfirmation === true && <ModalChangeStatus
                setOpenModal={setModalShowConfirmation}
                methodAction={confirmChange}
                itemChange={itemChange}
            />}
            <label style={{ width: "200px" }}>
                Alterar status para:
                <select value={selectedValue} onChange={(e) => handleChange(e, item)}>
                    <option value="">Selecione um status</option>
                    {selectOptionChangeStatus.map((option) =>
                        <option key={option?.code} value={option?.code}>{option?.name}</option>
                    )}
                </select>
            </label>
        </>
    )
}

export default ChangeStatusSelect