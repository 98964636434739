import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import styles from './pagination.module.css';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const onNext = () => {
    if(currentPage === lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if(currentPage === 1 ) return
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  let isPageOne = currentPage === 1;
  let isLastPage = currentPage === lastPage;
  return (
    <ul
      className={classnames(`${styles.pagination_container} `, { [className]: className })}
    >
      { isPageOne ?(
        <li className={classnames(`${styles.pagination_item} ${styles.disabled}`, {disabled: currentPage === 1})}onClick={onPrevious}>
          <div className={`${styles.arrow} ${styles.left} `} />
        </li>
        ) : (
        <li className={classnames(`${styles.pagination_item}`, {})}onClick={onPrevious}>
          <div className={`${styles.arrow} ${styles.left} `} />
        </li>
        )
      }
      
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key={index} className={`${styles.pagination_item} ${styles.dots}` }> &#8230;</li>;
        }

        if(pageNumber === currentPage)
          return ( <li key={index} className={classnames(`${styles.pagination_item} ${styles.selected}`, { selected: pageNumber === currentPage })} onClick={() => onPageChange(pageNumber)}> {pageNumber}</li>);
        else return ( <li key={index} className={classnames(`${styles.pagination_item} `, { })} onClick={() => onPageChange(pageNumber)}> {pageNumber}</li>);
        })}
      {isLastPage? (
        <li className={classnames(`${styles.pagination_item} ${styles.disabled}`, {})}onClick={onNext}>
          <div className={`${styles.arrow} ${styles.right} `}/>
        </li>
      ): (
        <li className={classnames(`${styles.pagination_item}`, {})}onClick={onNext}>
          <div className={`${styles.arrow} ${styles.right} `}/>
        </li>
      )}
      
    </ul>
  );
};

export default Pagination;