import { useContext, useEffect, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import Tab from '../../tab/Tab';
import ModalCancelContract from '../cancelContractModal/ModalCancelContract';
import styles from './InsuranceContract.module.css';
import { AiFillFilePdf } from 'react-icons/ai';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../../hooks/HookUseContext';
import api from '../../../services/api';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';
import moment from 'moment';

const InsuranceContract = ({
    data,
    stage,
    proposalData,
    setOpenCancelContractModal,
    openCancelContractModal,
    updateDataAfterCancelContract,
    setRemoveLoading }) => {

    const { roleValue } = useContext(RoleContext);
    const [documentsBbc, setDocumentsBbc] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/INC`, '_blank', 'noopener,noreferrer');
    }

    const checkTokenViewPDF = (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewPDF(id);
            }
        })
    }

    async function getDocuments() {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                const jsonData = response.data;
                const updatedData = jsonData.filter(item => item.documentType.code === "BSP");
                setDocumentsBbc(updatedData);

            })
                .catch((error) => {
                    setDocumentsBbc([])
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getDocuments();
    }, [])

    const viewDocuments = () => {
        return (
            <div className={`${stylesModal.content} ${styles.actionButtonsPDF}`}>
                {documentsBbc && documentsBbc.length > 0 ? documentsBbc?.map((document) => (
                    <div key={document?.id}>
                        {roleValue?.includes("DOCUMENT_READ") && (
                            <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id)}>
                                <AiFillFilePdf />
                                {document?.documentType?.description}
                            </button>
                        )}
                        {roleValue?.includes("DOCUMENT_SPECIFIC_READ") &&
                            (document?.documentType?.code === "BSP" ||
                                document?.documentType?.code === "TSP") &&
                            (
                                <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id)}>
                                    <AiFillFilePdf />
                                    {document?.documentType?.description}
                                </button>
                            )
                        }
                    </div>
                )) : <button disabled className={styles.disabled}>Não há documentos</button>}
            </div>
        );
    }


    const actionsButtonInsuranceContract = () => {
        const insuranceCancelStage = data?.proposalStages?.find(work =>
            (work?.status?.code?.toLowerCase() === 'wait' || work?.status?.code?.toLowerCase() === 'disabled') 
            && work?.stages?.code === "INSURANCE_CANCEL" 
        );
        return (
            <div className={styles.actionsButtons}>
                {data?.proposalStages?.map((work) => (
                    work?.status?.code?.toLowerCase() === 'complete'
                    && work?.stages?.code === "INSURANCE_CONTRACT"
                    && insuranceCancelStage
                    && (roleValue?.includes("INSURANCE_CONTRACT_DELETE")) && (
                        <button key={work?.id?.proposalId} onClick={() => setOpenCancelContractModal(true)}>
                            Cancelar contrato
                        </button>
                    )
                ))}
            </div>
        )
    }

    const dataCancelContractInformation = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformation}>
                        <div>
                            <p>Produto</p>
                            <strong>{data?.insurancePlan?.product?.code}</strong>
                        </div>
                        <div>
                            <p>Seguro</p>
                            <strong>{data?.insurancePlan?.typePlan?.description}</strong>
                        </div>
                        <div>
                            <p>Data de início da vigência</p>
                            <strong>{data?.validityPeriodStart ? moment(data?.validityPeriodStart).format('DD/MM/YYYY') : ''}</strong>
                        </div>
                        <div>
                            <p>Número de certificado</p>
                            <strong>{data?.certificateCode}</strong>
                        </div>
                        <div>
                            <p>Valor do seguro</p>
                            <strong>{data?.data?.financingAmount && data?.data?.financingAmount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Prêmio do seguro</p>
                            <strong>{data?.premiumValue && data?.premiumValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Valor da parcela</p>
                            <strong>{data?.data?.installmentValue && data?.data?.installmentValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Quantidade de parcelas</p>
                            <strong>{data?.data?.installmentAmount}</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.insuranceContractContainer}>
            {loading && <Loading />}
            {openCancelContractModal && (<ModalCancelContract setOpenModal={setOpenCancelContractModal} data={data} updateData={updateDataAfterCancelContract} setRemoveLoading={setRemoveLoading} />)}
            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Seguro',
                        'Documentos'
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{dataCancelContractInformation()}</>,
                        <>{viewDocuments()}</>
                    ]}
                />
                <div className={styles.actions}>
                    {actionsButtonInsuranceContract()}
                </div>
            </>)}
        </div>
    )
}

export default InsuranceContract