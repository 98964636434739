// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_homeContainer__wshHP{\r\n    margin: 1em;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: center;\r\n    flex-direction: column;\r\n}\r\n.Home_homeContainer__wshHP svg{\r\n    width: 20px;\r\n    height: 20px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/pages/home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".homeContainer{\r\n    margin: 1em;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: center;\r\n    flex-direction: column;\r\n}\r\n.homeContainer svg{\r\n    width: 20px;\r\n    height: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeContainer": "Home_homeContainer__wshHP"
};
export default ___CSS_LOADER_EXPORT___;
