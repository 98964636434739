import styles from './Incidents.module.css';
import { AiOutlineFileSearch } from 'react-icons/ai';
import InputFilter from '../../../../form/InputFilter/InputFilter';
import { useEffect, useState } from 'react';
import api from '../../../../../services/api';
import { checkJwt } from '../../../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../../../layout/loading/Loading';
import Pagination from '../../../../layout/pagination/Pagination';
import ModalMessage from '../../modalMessage/ModalMessage';
import InteractiveListProposal from '../../interactiveListProposal/InteractiveListProposal';
import Tab from '../../../../tab/Tab';


const Incidents = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [proposalCode, setProposalCode] = useState('');
    const [dataRegistration, setDataRegistration] = useState(null);
    const [pageable, setPageable] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [searchNoError, setSearchNoError] = useState(false);
    const [searchClickButtonIndividual, setSearchClickButtonIndividual] = useState(false);


    const openModalEvent = (message) => {
        setMessage(message);
        setModalOpen(true);
    }

    const handleCheckboxChange = () => {
        setSearchNoError(!searchNoError);
    };


    const getAllIncidents = async (page) => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS}proposals/?page=${page}&size=${10}`, {
        }).then((response) => {
            setDataRegistration(response?.data?.content);
            setPageable(response?.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setDataRegistration(null);
            toast.error(error?.response?.data?.message || error?.response?.data);
            setLoading(false);
        })
    }

    const getIncident = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS}${proposalCode}/${searchNoError}`, {
        }).then((response) => {
            setDataRegistration(response?.data);
            setPageable(null);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setDataRegistration(null);
            toast.error(error?.response?.data?.message || error?.response?.data);
            setLoading(false);
        })
    }

    const submit = (event) => {
        event?.preventDefault();
        handleSubmit();
    }

    const handleSubmit = () => {
        checkJwt(navigate).then((response) => {
            if (response) {
                if (proposalCode) {
                    setSearchClickButtonIndividual(true);
                    getIncident();
                }
                else {
                    setSearchClickButtonIndividual(false);
                    setCurrentPage(0);
                    getAllIncidents(0);
                }
            }
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getAllIncidents(currentPage);
            }
        })
    }, [])


    const handleCurrentPage = (page) => {
        setCurrentPage(page);
        getAllIncidents(page);

    }

    const updateProposalStage = (stage) => {
        setDataRegistration((prevData) => {
            const newData = prevData.map((data) => {
                return {
                    ...data,
                    proposalStages: data?.proposalStages?.map((stages) => {
                        if (data?.data?.proposalCode === stage?.proposalCode && stages?.stages?.code === stage?.stages?.code && data?.data?.proposalCode === stage?.proposalCode) {
                            return {
                                ...stages,
                                ...stage, // Atualiza todo o estágio
                            };
                        }
                        return stages;
                    }),
                };
            });
            return newData;
        });
    };


    const incidentScreen = () => {
        return (
            <>
                {modalOpen === true && <ModalMessage setOpenModal={setModalOpen} message={message} />}
                <div className={`${styles.card} ${styles.column}`}  >
                    <h2>Filtro</h2>
                    <div className={styles.filter}>
                        <form onSubmit={submit}>
                            <InputFilter
                                type="text" name={"proposalNumber"} text="Num. da proposta" required={searchNoError} maxLength={10} value={proposalCode} handleOnChange={(e) => setProposalCode(e.target.value)} />
                            <button >
                                <AiOutlineFileSearch />
                                Buscar
                            </button>
                            <div className={styles.checkboxContainer}>
                                Pesquisar sem erro?
                                <div className={styles.checkboxWrapper} >
                                    Não
                                    <input
                                        className={styles.checkCustom}
                                        type="checkbox"
                                        name="isWithError"
                                        id="isWithError"
                                        checked={searchNoError}
                                        onChange={handleCheckboxChange}
                                    />
                                    Sim
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`${styles.card}`}  >
                    {loading && <Loading />}
                    {!loading && <>
                        <h2>Incidentes</h2>
                        <div className="wrapper">
                            {dataRegistration && (
                                <>
                                    <InteractiveListProposal
                                        list={dataRegistration}
                                        openModalEvent={openModalEvent}
                                        searchNoError={(searchClickButtonIndividual === true && searchNoError === true && proposalCode ? true : false)}
                                    />
                                    {pageable && (
                                        <Pagination
                                            className={styles.pagination_bar}
                                            currentPage={currentPage === 0 ? 1 : currentPage + 1}
                                            totalCount={pageable.totalElements ? pageable.totalElements : []}
                                            pageSize={pageable.size ? pageable.size : 1}
                                            onPageChange={page => handleCurrentPage(page - 1)}
                                        />
                                    )}
                                </>

                            )}
                        </div>
                    </>}
                </div>
            </>
        )
    }


    return (
        <div className={styles.incidentsToContainer}>
            {incidentScreen()}
        </div>


    )
}

export default Incidents