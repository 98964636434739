import { checkJwt } from "../../../../../helpers/Jwt-helper";
import api from "../../../../../services/api";
import { getCompaniesList } from "../../tableCompany/tableCompany/services/companyService";
import { toast } from "react-toastify";

export const getCompaniesListToStore = async (setData, setLoading) => {
  getCompaniesList(setData, setLoading);
};

export const handleSearch = (
  setDataFilter,
  filters,
  setCurrentPage,
  navigate
) => {
  checkJwt(navigate).then((response) => {
    setDataFilter({ filters });
    setCurrentPage(0);
  });
};

export const handleInputChange = (e, setFilters, filters) => {
  const { name, value } = e.target;
  setFilters({
    ...filters,
    [name]: value,
  });
};
export const handleCurrentPage = (page, setCurrentPage, navigate) => {
  checkJwt(navigate).then((response) => {
    if (response) {
      setCurrentPage(page);
    }
  });
};

export const getStores = async (
  page,
  name,
  cnpj,
  origin,
  companyId,
  active,
  setData,
  setLoading
) => {
  try {
    setData([]);
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${
          process.env.REACT_APP_PARTNER_NOTIFIER_STORES
        }?page=${page}&size=${10}`,
        {
          params: {
            name,
            cnpj,
            origin,
            companyId,
            active,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setData([]);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const activeOrDesactive = async (
  setData,
  currentData,
  data,
  active,
  setSelected
) => {
  try {
    await api
      .put(
        `${
          process.env.REACT_APP_MONITORING_BASE_URL
        }${process.env.REACT_APP_PARTNER_NOTIFIER_STORES_ACTIVATE_OR_DESACTIVATE.replace(
          "{id}",
          currentData?.id
        ).replace("{active}", active)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        console.log(data);
        const updatedList = getListUpdated(response, data);
        setSelected(active);
        setData(updatedList);
      })
      .catch((error) => {
        console.log(error);
        setSelected(!active);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
    setSelected(!active);
  }
};

export const updateStore = async (setData, data, formData, currentData) => {
  try {
    await api
      .patch(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_STORES_UPDATE}`,
        {
          id: currentData?.id,
          name: formData?.name,
          cnpj: formData?.cnpj?.replace(/\D/g, ""),
          origin: formData?.origin,
          companyId: formData?.companyId,
          description: formData?.description,
          userId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        const updatedList = getListUpdated(response, data);
        setData(updatedList);
        toast.success("Atualizado");
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const createStore = async (
  formData,
  closedModal,
  getStores,
  setData,
  setLoading
) => {
  try {
    await api
      .post(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_STORES_CREATE}`,
        {
          name: formData?.name,
          cnpj: formData?.cnpj?.replace(/\D/g, ""),
          origin: formData?.origin,
          companyId: formData?.companyId,
          description: formData?.description,
          userId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        getStores(0, null, null, null, null, null, setData, setLoading);
        closedModal();
        toast.success("Criado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const fieldsValidation = (formData, setErrors) => {
  const newErrors = {};
  if (!formData?.name) newErrors.name = true;
  if (!formData?.origin) newErrors.origin = true;
  if (!formData?.companyId) newErrors.companyId = true;
  if (!formData?.description) newErrors.description = true;
  if (!formData?.cnpj) newErrors.cnpj = true;
  if (formData?.cnpj?.length < 18) newErrors.cnpj = true;

  setErrors(newErrors);

  return newErrors;
};

function getListUpdated(response, data) {
  const updatedObject = response?.data;
  console.log(response?.data);
  const updatedList = data?.content?.map((item) => {
    if (item.id === updatedObject.id) {
      return updatedObject;
    }
    return item;
  });
  return {
    ...data,
    content: updatedList,
  };
}

const handleError = (error) => {
  if (error.response && error.response.data) {
    let errorMessage = error?.response?.data?.message;

    if (!errorMessage) {
      errorMessage = error?.response?.data;
      console.log(error?.response?.data);
    }
    toast.error(`
              ${errorMessage}
          `);
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error(error?.response?.data);
    console.error("Erro desconhecido:", error);
  }
};
