import React, { useState } from "react";
import Toggle from "../../../../form/toggle/Toggle";
import { activeOrDesactive } from "../services/companyActionsService";

const ActionActivateOrDesactivate = ({ currentData, data, setData }) => {
  const [selected, setSelected] = useState(
    currentData?.deletedAt === null ? true : false
  );
  return (
    <>
      <Toggle
        onChange={(e) =>
          activeOrDesactive(
            setData,
            currentData,
            data,
            e.target.checked,
            setSelected
          )
        }
        checked={selected}
      />
    </>
  );
};

export default ActionActivateOrDesactivate;
