import React, { useContext, useEffect, useState } from 'react';
import { RiAddCircleLine, RiIndeterminateCircleLine } from 'react-icons/ri';
import styles from './InteractiveList.module.css';
import DocumentScore from '../DocumentScore';
import VehicleInspection from '../VehicleInspection';
import VehiclePlateConsult from '../VehiclePlateConsult';
import DocumentScoreDataCadastral from '../DocumentScoreDataCadastral';
import moment from 'moment';
import api from '../../../../services/api';
import { RoleContext } from '../../../hooks/HookUseContext';
import { checkJwt } from '../../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DigitalSignature from '../DigitalSignature';
import InsuranceContract from '../InsuranceContract';
import ReloadStages from '../reloadStages/ReloadStages';
import Loading from '../../../layout/loading/Loading';
import ModalRegularizeInsuranceContract from '../modalRegularizeInsuranceContract/ModalRegularizeInsuranceContract';
import renderIcon from '../../../../helpers/IconHelper';
import AttachDocumentsButton from '../attachDocumentsButton/AttachDocumentsButton';
import ChangeStatusSelect from '../changeStatusSelect/ChangeStatusSelect';


const InteractiveList = ({ list, openModalEvent, isChangeStatus = false }) => {
    const { roleValue } = useContext(RoleContext);
    const navigate = useNavigate();

    const [openItems, setOpenItems] = useState([]);
    const [listProposalStages, setListProposalStages] = useState([])
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [dataProposalStages, setDataProposalStages] = useState();



    const [selectOptionChangeStatus, setSelectOptionChangeStatus] = useState([]);




    const openModalRegularizeEvent = (item) => {
        setDataProposalStages(item);
        setModalOpen(true);
    }

    const toggleItem = (index) => {
        if (openItems.includes(index)) {
            setOpenItems(openItems.filter((item) => item !== index));
        } else {
            setOpenItems([...openItems, index]);
        }
    };

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }

    const reprocess = (id, stages) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                reprocessProposalPatch(id, stages)
            }
        })
    }

    const updatedProposalStage = (id, stages, setLoading) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getUpdatedProposalStage(id, stages, setLoading)
            }
        })
    }

    const updateProposalStage = (updatedStage) => {
        setListProposalStages((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item?.stages?.code === updatedStage?.stages?.code && item?.proposalCode === updatedStage?.proposalCode) {
                    return {
                        ...item,
                        ...updatedStage,
                    };
                }
                return item;
            });
            return updatedList;
        });
    };

    async function reprocessProposalPatch(id, stages) {
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_REPROCESS}${id}/${stages}`, {
        }).then((response) => {
            toast.success('Etapa reprocessada com sucesso')
            updateProposalStage(response?.data);
        }).catch((error) => {
            console.log(error);
            toast.error("Deu erro ao enviar etapa para reprocessamento")
        })
    }


    async function getUpdatedProposalStage(id, stages, setLoading) {
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_GET_UPDATED}${id}/${stages}`, {
        }).then((response) => {
            updateProposalStage(response?.data);
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message || "Deu erro ao recuperar stage")
            setLoading(false);
        })
    }
    async function getStatusToChange() {
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_CHANGE_STATUS}`, {
        }).then((response) => {
            setSelectOptionChangeStatus(response?.data);
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
        })
    }

    useEffect(() => {
        // Se list for uma Promise, aguarde a resolução antes de definir o estado
        if (list instanceof Promise) {
            list.then(result => {
                if (Array.isArray(result)) {
                    setListProposalStages(result);
                    setLoading(false);
                }
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        } else if (Array.isArray(list)) {
            setLoading(false);
        }

        if (roleValue?.includes("CHANGE_STATUS_PROPOSAL_STAGE") && roleValue?.includes("STATUS_READ") && isChangeStatus)
            getStatusToChange();

    }, []);

    const reprocessRender = (item) => {
        return (
            <>
                {
                    (item?.status?.code?.toLowerCase() === 'error')
                        && roleValue?.includes("INCIDENTS_REPROCESS_CREATE") &&
                        (
                            (item?.stages?.code === "VEHICLE_PLATE_CONSULT") ||
                            (item?.stages?.code === "VEHICLE_INSPECTION") ||
                            (item?.stages?.code === "DOCUMENT_SCORE_DATA_CADASTRAL") ||
                            (item?.stages?.code === "DOCUMENT_SCORE") ||
                            (item?.active === true && item?.stages?.code === "DIGITAL_SIGNATURE") ||
                            (item?.active === true && item?.stages?.code === "INSURANCE_CONTRACT")
                        )
                        ? (
                            <button className={styles.reprocesStage} onClick={() => reprocess(item?.id?.proposalId, item?.stages?.code)}>
                                Reprocessar
                            </button>
                        ) : <button disabled>Reprocessar</button>
                }
            </>
        )
    }


    const data = (item) => {
        return (
            <div className={styles.informationContainer}>
                <div className={styles.informationItem}>
                    Etapa:
                    <strong>{item?.stages?.description}</strong>
                </div>
                <div className={styles.informationItem}>
                    Data:
                    <strong>{item?.stageDate && moment(item?.stageDate).format('DD/MM/YYYY HH:mm')}</strong>
                </div>
                <div className={styles.informationItem} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer", maxWidth: "450px" }} onClick={() => openModalEvent(item?.message.descriptionDetails)}>
                    mensagem:
                    <strong>{item?.status?.code === "ERROR" && item?.message?.descriptionDetails}</strong>
                </div>
                <div className={`${styles.informationItem} ${styles[item?.status?.code.toLowerCase()]} ${styles.iconText}`}>
                    Status:
                    <strong style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1em"
                    }}>{renderIcon(item?.status?.code.toLowerCase())} {item?.status?.name}</strong>
                </div>
                {!isChangeStatus &&
                    <div className={styles.informationItem}>
                        {reprocessRender(item)}
                    </div>
                }
                {
                    item?.stages?.code === "INSURANCE_CONTRACT" &&
                    roleValue?.includes("INCIDENTS_REGULARIZE_CREATE") && !isChangeStatus && (
                        <div className={styles.informationItem}>
                            <button onClick={() => openModalRegularizeEvent(item)}>Regularizar</button>
                        </div>
                    )
                }
                {roleValue?.includes("INCIDENTS_REGULARIZE_ATTACH_DOCUMENTS_FUNCAO_CREATE") && !isChangeStatus && (
                    <div className={styles.informationItem}>
                        {(
                            item?.status?.code === "ERROR" ||
                            item?.status?.code === "COMPLETE"
                        ) &&
                            <AttachDocumentsButton
                                item={item}
                                updateProposalStage={updateProposalStage}
                            />
                        }
                    </div>
                )}

                {isChangeStatus && selectOptionChangeStatus && selectOptionChangeStatus.length > 0 && (
                    <div className={styles.selectOptions}>
                        <ChangeStatusSelect
                            item={item}
                            selectOptionChangeStatus={selectOptionChangeStatus}
                            updatedProposalStage={updatedProposalStage}
                            setLoading={setLoading}
                            updateProposalStage={updateProposalStage}
                        />
                    </div>
                )}

            </div>
        )
    }
    return (
        <ul className={styles.listContainer}>
            {modalOpen === true && <ModalRegularizeInsuranceContract
                setOpenModal={setModalOpen}
                dataProposalStages={dataProposalStages}
            />}



            {loading && <Loading />}
            {!loading && Array.isArray(listProposalStages) && listProposalStages.map((item, index) => (
                <li key={index}>
                    <div className={styles.information}>
                        {openItems.includes(index) ? (
                            <>
                                <RiIndeterminateCircleLine onClick={() => toggleItem(index)} />
                                {data(item)}
                            </>

                        ) : (
                            < >
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    {!isChangeStatus ? <RiAddCircleLine onClick={() => toggleItem(index)} /> : <br />}

                                    <div className={styles.informationItem}>
                                        <ReloadStages item={item} updatedProposalStage={updatedProposalStage} />
                                    </div>
                                </div>
                                {data(item)}
                            </>
                        )}


                        {openItems.includes(index) && (
                            <div className={styles.renderItem}>
                                {item?.stages?.code === "DOCUMENT_SCORE" && (
                                    <DocumentScore
                                        item={item}
                                        formatCnpjCpf={formatCnpjCpf}
                                        openModalEvent={openModalEvent}
                                    />
                                )}
                                {item?.stages?.code === "DOCUMENT_SCORE_DATA_CADASTRAL" && (
                                    <DocumentScoreDataCadastral
                                        item={item}
                                        formatCnpjCpf={formatCnpjCpf}
                                        openModalEvent={openModalEvent}
                                    />
                                )}

                                {item?.stages?.code === "VEHICLE_INSPECTION" && (
                                    <VehicleInspection
                                        item={item}
                                        openModalEvent={openModalEvent}
                                    />
                                )}
                                {item?.stages?.code === "VEHICLE_PLATE_CONSULT" && (
                                    <VehiclePlateConsult
                                        item={item}
                                        openModalEvent={openModalEvent}
                                    />
                                )}
                                {item?.stages?.code === "DIGITAL_SIGNATURE" && (
                                    <DigitalSignature
                                        item={item}
                                        formatCnpjCpf={formatCnpjCpf}
                                        openModalEvent={openModalEvent}
                                    />
                                )}
                                {item?.stages?.code === "INSURANCE_CONTRACT" && (
                                    <InsuranceContract
                                        item={item}
                                        formatCnpjCpf={formatCnpjCpf}
                                        openModalEvent={openModalEvent}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default InteractiveList;