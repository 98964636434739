// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalMessage_container__Byr7v .ModalMessage_tittle__gco8s {\r\n    color: #1c7932;\r\n    font-size: 20px;\r\n    margin-bottom: 1em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/pages/incidents/modalMessage/ModalMessage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".container .tittle {\r\n    color: #1c7932;\r\n    font-size: 20px;\r\n    margin-bottom: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ModalMessage_container__Byr7v",
	"tittle": "ModalMessage_tittle__gco8s"
};
export default ___CSS_LOADER_EXPORT___;
