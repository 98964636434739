import { BrowserRouter as Router } from "react-router-dom"
import Container from "./components/layout/container/Container";
import Header from './components/layout/header/Header'
import IdleTimerContainer from "./components/idleTimer/IdleTimerContainer";
import { ToastContainer } from 'react-toastify';
import Routers from "./routes/Routers";

import { HookUseContext } from "./components/hooks/HookUseContext";

function App() {
  return (
    <HookUseContext>
      <Router>
        <ToastContainer />
        <IdleTimerContainer />
        <Header />
        <Container customClass="minHeight">
          <Routers />
        </Container>
      </Router>
    </HookUseContext>
  );
}

export default App;
