// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_loaderContainer__spW00{\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    place-items: center;\r\n    padding-bottom: 2em;\r\n    margin:  0 auto;\r\n}\r\n\r\n.Loading_loader__OB2kk{\r\n    width: 50px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/layout/loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".loaderContainer{\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    place-items: center;\r\n    padding-bottom: 2em;\r\n    margin:  0 auto;\r\n}\r\n\r\n.loader{\r\n    width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": "Loading_loaderContainer__spW00",
	"loader": "Loading_loader__OB2kk"
};
export default ___CSS_LOADER_EXPORT___;
