import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import styles from './ModalMonitoring.module.css'
import { IoIosArrowForward } from 'react-icons/io'
import { AiOutlineReload, AiFillFilePdf } from 'react-icons/ai'
import ModalCancelContract from '../cancelContractModal/ModalCancelContract';
import api from '../../../services/api';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom'
import InputFilter from '../../form/InputFilter/InputFilter';
import SelectFilter from '../../form/SelectFilter/SelectFilter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../layout/loading/Loading';
import { useEffect } from 'react';
import ViewDocumentsModal from '../viewDocumentsModal/ViewDocumentsModal';
import moment from 'moment';
import ViewSubscribersModal from '../viewSubscribersModal/ViewSubscribersModal';

// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';
import Inspection from '../inspection/Inspection';
import Plate from '../plate/Plate';
import DigitalSiginature from '../digitalSignature/DigitalSiginature';
import InsuranceCancel from '../insuranceCancel/InsuranceCancel';
import InsuranceContract from '../insuranceContract/InsuranceContract';
import SendingEmail from '../sendingEmail/SendingEmail';
import DocumentScore from '../documentScore/DocumentScore';
import DocumentScoreDataCadastral from '../documentScoreDataCadastral/DocumentScoreDataCadastral';
import AddressValidation from '../addressValidation/AddressValidation';

function ModalMonitoring({ setOpenModal, setModalData, currentData, renderIcon, updateTable, stage, stageCode, currentStatus }) {
    const { roleValue } = useContext(RoleContext);

    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [openCancelContractModal, setOpenCancelContractModal] = useState(false);

    const [data, setData] = useState(currentData);
    const [originalData] = useState(currentData);

    const [removeLoading, setRemoveLoading] = useState(true);

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }
    function closedModal() {
        if (currentData !== originalData) {
            updateTable();
        }
        setOpen(false);
        setOpenModal(false);
    }
    function updateDataAfterCancelContract(response, TYPE, status) {
        if (status === 200) {
            for (let i = 0; i < currentData?.proposalStages?.length; i++) {
                if (currentData?.proposalStages[i]?.stages?.code === "INSURANCE_CONTRACT") {
                    currentData.proposalStages[i].status.code = 'disabled';
                    currentData.proposalStages[i].active = false;
                }

                if (currentData?.proposalStages[i]?.stages?.code === "INSURANCE_CANCEL") {
                    currentData.proposalStages[i].status.code = 'complete';
                }

            }

            currentData.cancellation = {
                refundValue: response.refundValue || "",
                origin: { code: TYPE }
            }
            setModalData(response);
            setData(response);
            return;
        }

        for (let i = 0; i < currentData?.proposalStages?.length; i++) {
            if (currentData?.proposalStages[i]?.stages?.code === "INSURANCE_CONTRACT") {
                currentData.proposalStages[i].active = false;
            }

            if (currentData?.proposalStages[i]?.stages?.code === "INSURANCE_CANCEL") {
                currentData.proposalStages[i].status.code = 'error';
            }
        }
        setModalData(response);
        setData(response);
    }


    const reprocess = (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                for (let i = 0; i < data?.proposalStages?.length; i++) {
                    if (data?.proposalStages[i]?.stages?.code === "INSURANCE_CANCEL" && data?.proposalStages[i]?.active) {
                        setOpenCancelContractModal(true);
                        return;
                    }
                }
                reprocessProposalPatch(id)
            }
        })
    }

    async function reprocessProposalPatch(id) {
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_REPROCESS}${id}`, {
        }).then((response) => {
            toast.success('Etapa reprocessada com sucesso')
            closedModal();
            updateTable();
        }).catch((error) => {
            console.log(error);
            toast.error("Deu erro ao enviar etapa para reprocessamento")
        })
    }

    async function reprocessStageNotActiveProposalPatch(id) {
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_REPROCESS}${id}/${stageCode}`, {
        }).then((response) => {
            toast.success('Etapa reprocessada com sucesso')
            closedModal();
            updateTable();
        }).catch((error) => {
            console.log(error);
            toast.error("Deu erro ao enviar etapa para reprocessamento")
        })
    }


    const reprocessStageNotActiveRender = () => {
        return (
            <>
                {data?.proposalStages?.map((work, index) => (
                    work?.stages?.code === stageCode &&
                    (stageCode === "DOCUMENT_SCORE_DATA_CADASTRAL" || stageCode === "DOCUMENT_SCORE") &&
                    work?.status?.code?.toLowerCase() === 'error' &&
                    !work?.active &&
                    roleValue?.includes("STAGE_NOT_ACTIVATE_REPROCESS_CREATE") && (
                        <div key={work?.id?.proposalId} className={`${styles[work?.stages?.code?.toLowerCase()]}`}>
                            <button className={styles.reprocesStage} onClick={() => reprocessStageNotActiveProposalPatch(work?.id?.proposalId)}>
                                <AiOutlineReload />
                                Reprocessar
                            </button>
                        </div>
                    )
                ))}
            </>
        )
    }

    const reprocessRender = () => {
        return (
            <>
                {data?.proposalStages?.map((work, index) => (
                    (work?.status?.code?.toLowerCase() === 'error' || (work?.status?.code?.toLowerCase() === 'rejected' && work?.stages?.code !== "DIGITAL_SIGNATURE"))
                        && work?.active && (work?.stages?.code === stageCode)
                        &&
                        (
                            (roleValue?.includes("INSURANCE_CONTRACT_DELETE") && work?.stages?.code === "INSURANCE_CANCEL") ||
                            (roleValue?.includes("VEHICLE_PLATE_CONSULT_REPROCESS_CREATE") && work?.stages?.code === "VEHICLE_PLATE_CONSULT") ||
                            (roleValue?.includes("VEHICLE_INSPECTION_REPROCESS_CREATE") && work?.stages?.code === "VEHICLE_INSPECTION") ||
                            (roleValue?.includes("DOCUMENT_SCORE_DATA_CADASTRAL_REPROCESS_CREATE") && work?.stages?.code === "DOCUMENT_SCORE_DATA_CADASTRAL") ||
                            (roleValue?.includes("DOCUMENT_SCORE_REPROCESS_CREATE") && work?.stages?.code === "DOCUMENT_SCORE") ||
                            (roleValue?.includes("DIGITAL_SIGNATURE_REPROCESS_CREATE") && work?.stages?.code === "DIGITAL_SIGNATURE") ||
                            (roleValue?.includes("ADDRESS_VALIDATION_REPROCESS_CREATE") && work?.stages?.code === "ADDRESS_VALIDATION") ||
                            (roleValue?.includes("SENDING_EMAIL_REPROCESS_CREATE") && work?.stages?.code === "SENDING_EMAIL") ||
                            (roleValue?.includes("INSURANCE_CONTRACT_REPROCESS_CREATE") && work?.stages?.code === "INSURANCE_CONTRACT")
                        )
                        ? (
                            <div key={work?.id?.proposalId} className={` ${styles[work?.stages?.code?.toLowerCase()]}`}>
                                <button className={styles.reprocesStage} onClick={() => reprocess(work?.id?.proposalId)}>
                                    <AiOutlineReload />
                                    Reprocessar
                                </button>
                            </div>
                        ) : ''
                ))}
            </>
        )
    }

    const proposalData = () => {
        return (
            <div className={`${styles.content} ${styles.columnInformation}`}>
                <div className={styles.rowsInformation}>
                    <div>
                        <p>Tipo produto</p>
                        <strong>{data?.data?.productType?.description}</strong>
                    </div>
                    <div>
                        <p>Proposta</p>
                        <strong>{data?.data?.proposalCode}</strong>
                    </div>
                    <div>
                        <p>CPF/CNPJ</p>
                        <strong>{data?.data?.insuredCpfCnpj && formatCnpjCpf(data?.data?.insuredCpfCnpj)}</strong>
                    </div>
                    <div>
                        <p>Núm. do contrato</p>
                        <strong>{data?.data?.contractCode}</strong>
                    </div>
                    <div>
                        <p>Origem</p>
                        <strong>{data?.data?.dataOrigin?.description}</strong>
                    </div>
                </div>
                {reprocessRender()}
                {reprocessStageNotActiveRender()}
            </div>
        )
    }


    return (
        <Modal open={open} center onClose={closedModal}
            classNames={
                {
                    modal: `${styles.modalContainer}`,

                }

            }>

            {!removeLoading && <Loading />}
            {removeLoading && (
                <div className={styles.modalContainer}>
                    <div className={`${styles.footerTittle}`}>
                        <h5 className={`${styles.tittle} `}>
                            {stage}
                        </h5>
                        <div className={styles.statusStage}>
                            <div className={`${styles[currentStatus?.code.toLowerCase()]}`} style={{ borderBottom: "none" }}>
                                <p>
                                    <strong className={styles.statusIcon}>
                                        {renderIcon(currentStatus?.code.toLowerCase())}
                                        {currentStatus?.name}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    {(
                        "INSURANCE_CONTRACT" === stageCode
                    ) &&
                        <>
                            <InsuranceContract
                                data={data}
                                stage={stage}
                                proposalData={proposalData}
                                setOpenCancelContractModal={setOpenCancelContractModal}
                                openCancelContractModal={openCancelContractModal}
                                updateDataAfterCancelContract={updateDataAfterCancelContract}
                                setRemoveLoading={setRemoveLoading} />
                        </>}
                        {(
                        "SENDING_EMAIL" === stageCode
                    ) &&
                        <>
                            <SendingEmail
                                data={data}
                                proposalData={proposalData}
                                setOpenCancelContractModal={setOpenCancelContractModal}
                                openCancelContractModal={openCancelContractModal}
                                updateDataAfterCancelContract={updateDataAfterCancelContract}
                                setRemoveLoading={setRemoveLoading} />
                        </>}
                    {
                        "INSURANCE_CANCEL" === stageCode && (
                            <>
                                <InsuranceCancel
                                    data={data}
                                    stage={stage}
                                    proposalData={proposalData}
                                    setRemoveLoading={setRemoveLoading}
                                    currentData={currentData}
                                    setData={setData}
                                    updateDataAfterCancelContract={updateDataAfterCancelContract}
                                    openCancelContractModal={openCancelContractModal}
                                    setOpenCancelContractModal={setOpenCancelContractModal} />
                            </>
                        )
                    }
                    {(
                        ("DIGITAL_SIGNATURE" === stageCode || "MANUAL_SIGNATURE" === stageCode) && (
                            <>
                                <DigitalSiginature
                                    data={data}
                                    stage={stage}
                                    proposalData={proposalData}
                                    styles={styles}
                                    roleValue={roleValue}
                                    setModalData={setModalData}
                                    setData={setData}
                                    setRemoveLoading={setRemoveLoading}
                                    updateTable={updateTable}
                                    closedModal={closedModal}
                                />
                            </>
                        )
                    )}
                    {(
                        "VEHICLE_INSPECTION" === stageCode
                    ) && <>
                            <Inspection data={data} stage={stage} proposalData={proposalData} />
                        </>}
                    {(
                        "VEHICLE_PLATE_CONSULT" === stageCode
                    ) && <>
                            <Plate data={data} stage={stage} proposalData={proposalData} />
                        </>}

                    {(
                        "DOCUMENT_SCORE_DATA_CADASTRAL" === stageCode
                    ) && <>
                            <DocumentScoreDataCadastral data={data} proposalData={proposalData} />
                        </>}
                    {(
                        "DOCUMENT_SCORE" === stageCode
                    ) && <>
                            <DocumentScore data={data} proposalData={proposalData} />
                        </>}
                    {(
                        "ADDRESS_VALIDATION" === stageCode
                    ) && <>
                            <AddressValidation data={data} proposalData={proposalData} />
                        </>}


                </div>
            )}

        </Modal >
    );
}

export default ModalMonitoring;