import jwt_decode from "jwt-decode";
import loginAxios from "../services/loginAxios";

let isLoggedIn = localStorage.getItem("token") !== undefined;


export function jwtDecrypt() {
  if (localStorage.getItem("token")) {
    return jwt_decode(localStorage.getItem("token"));
  }
  return false;
}


export async function checkJwt(navigate) {
  console.warn("Checando Token");
  if (!localStorage.getItem("token")) {
    navigate("/login")
    return false;
  };
  await validateToken(navigate);

  if (!isLoggedIn) {
    removeTokenAndRedirect(navigate);
  }

  return isLoggedIn;
}

function removeTokenAndRedirect(navigate) {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("app:token-auth");
  isLoggedIn = false;
  navigate("/login")
}

async function validateToken(navigate) {
  await loginAxios.get(`${process.env.REACT_APP_CLOAK_PATH_BASE}${process.env.REACT_APP_CLOAK_PATH_VALIDATE_TOKEN}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  }).then((response) => {
    isLoggedIn = true;
  })
    .catch((error) => {
      console.warn("Dando refresh token");
      refreshToken(navigate);
    })
}

async function refreshToken(navigate) {
  let data = new URLSearchParams({
    client_id: process.env.REACT_APP_CLOAK_CLIENT_ID,
    refresh_token: localStorage.getItem('refresh_token'),
    grant_type: "refresh_token",
    client_secret: process.env.REACT_APP_CLOAK_CLIENT_SECRET,
  }).toString();

  // usa o mesmo path do login para fazer o refresh do token
  await loginAxios.post(`${process.env.REACT_APP_CLOAK_PATH_BASE}${process.env.REACT_APP_CLOAK_PATH_LOGIN}`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => {
    if (response.data && response.data.access_token) {
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("expires_in", response.data.expires_in);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      const { email, name } = jwtDecrypt();
      localStorage.setItem("user", JSON.stringify({ email, name }));
    }
    getRefreshTokenFromApplication(navigate);
  }).catch((error) => {
    isLoggedIn = false;
    removeTokenAndRedirect(navigate);
  })
}

//recuperar o token da aplicação após dar um refresh no token do keycloack
async function getRefreshTokenFromApplication(navigate) {
  try {
    const bearer_token = `Bearer ${localStorage.getItem('token')}`;

    if (!localStorage.getItem('token')) return;

    const config = {
      headers: {
        Authorization: bearer_token,
        "Access-Control-Allow-Origin": "*",
        "system": process.env.REACT_APP_SYSTEM_ID
      }
    };
    await loginAxios.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_AUTH_TOKEN_DERIVE}`, config
    ).then((response) => {
      localStorage.setItem("app:token-auth", response?.data.access_token);
      isLoggedIn = true;
    })
      .catch((error) => {
        console.log(error)
        localStorage.removeItem("app:token-auth");
        isLoggedIn = false;
        removeTokenAndRedirect(navigate);
      })
  } catch (error) {
    console.log(error)
    console.log(error.response)
    localStorage.removeItem("app:token-auth");
    isLoggedIn = false;
    removeTokenAndRedirect(navigate);
  }
}
