import { useContext, useEffect, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import Tab from '../../tab/Tab';
import styles from './InsuranceCancel.module.css';
import moment from 'moment';
import { RoleContext } from '../../hooks/HookUseContext';
import SelectFilter from '../../form/SelectFilter/SelectFilter';
import InputFilter from '../../form/InputFilter/InputFilter';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { AiFillFilePdf } from 'react-icons/ai';
import ModalCancelContract from '../cancelContractModal/ModalCancelContract';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';


const InsuranceCancel = ({
    data,
    stage,
    proposalData,
    setRemoveLoading,
    currentData,
    setData,
    updateDataAfterCancelContract,
    openCancelContractModal,
    setOpenCancelContractModal }) => {

    const { roleValue } = useContext(RoleContext);
    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [listBank, setListBank] = useState([]);
    const [documentsBbc, setDocumentsBbc] = useState(null);
    const navigate = useNavigate();

    let currentCancelData = {}
    if (currentData?.cancellation) {
        currentCancelData = {
            bank: {
                id: currentData?.cancellation?.bank?.id || '',
                name: currentData?.cancellation?.bank?.description || '',
            },
            account: currentData?.cancellation?.account || '',
            agency: currentData?.cancellation?.agencyNumber || ''
        }
    }
    const [cancelData, setCancelData] = useState(currentCancelData);


    async function patchSubmitCancel() {
        // setRemoveLoading(false);
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_CANCELLATION}`, {
            "proposalId": currentData?.proposalStages[0]?.id?.proposalId,
            "bankId": cancelData?.bank?.id,
            "account": cancelData?.account,
            "agency": cancelData?.agency
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },

            }).then((response) => {
                setData((prevData) => ({
                    ...prevData,
                    cancellation: {
                        refundValue: currentData?.cancellation?.refundValue,
                        bank: { id: cancelData?.bank?.id, description: cancelData?.bank?.name },
                        account: cancelData?.account,
                        agencyNumber: cancelData?.agency,
                        origin: { id: currentData?.cancellation?.origin?.id, code: currentData?.cancellation?.origin?.code, description: currentData?.cancellation?.origin?.description }
                    }
                }));
                toast.success('Informações salvas com sucesso');
                //setRemoveLoading(true);
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error ao salvar as informações');
                //setRemoveLoading(true);
            })
    }

    const submit = (event) => {
        event.preventDefault();
        setShowEdit(false);
        checkJwt(navigate).then((response) => {
            if (response) {
                patchSubmitCancel();
            }
        })
    }

    function handleChange(event) {
        setCancelData({ ...cancelData, [event.target.name]: event.target.value })
    }

    function handleSelectStage(event) {
        setCancelData({
            ...cancelData, bank: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }


    function showForm() {
        return (
            <div className={styles.showForm}>
                <form onSubmit={submit}>
                    <SelectFilter text={"Banco"} name={"bank"} options={listBank} handleOnChange={handleSelectStage} value={cancelData?.bank?.id || ''} textDefault={currentData?.bank?.description || ''} required={true} />
                    <InputFilter type="text" name={"agency"} text="Agência" handleOnChange={handleChange} required={true} value={cancelData?.agency || ''} />
                    <InputFilter type="text" name={"account"} text="Conta Corrente" handleOnChange={handleChange} required={true} value={cancelData?.account || ''} />
                    <button>Salvar</button>
                </form>
            </div>
        )
    }

    async function getBanks() {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_BANKS}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                let optionsBanks = [];
                for (let i = 0; i < response?.data?.content.length; i++) {
                    optionsBanks.push(
                        {
                            id: response?.data?.content[i].id,
                            name: response?.data?.content[i].description
                        }
                    )
                }
                setListBank(optionsBanks)
            })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getBanks();
        return () => clearTimeout();
    }, [])

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/INS`, '_blank', 'noopener,noreferrer');
    }

    const checkTokenViewPDF = (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewPDF(id);
            }
        })
    }

    const renderInformationCancel = (work) => {
        return (
            <>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformation}>
                        <div>
                            <p>Origem do cancelamento</p>
                            <strong>
                                {work?.cancellation?.origin?.description}
                            </strong>
                        </div>
                        <div>
                            <p>Valor da restituição</p>
                            <strong>
                                {work?.cancellation?.refundValue && work?.cancellation?.refundValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </strong>
                        </div>
                        <div>
                            <p>Data do cancelamento</p>
                            <strong>{work?.cancellationDate ? moment(work?.cancellationDate).format('DD/MM/YYYY') : ''}</strong>
                        </div>
                    </div>
                </div>
                <br />
                {work?.cancellation?.bank?.description && work?.cancellation?.account && work?.cancellation?.agencyNumber && !showEdit ? (
                    <div className={styles.columnInformation}>
                        <div className={styles.rowsInformation}>

                            <div>
                                <p>Banco</p>
                                <strong>{work?.cancellation?.bank?.description}</strong>
                            </div>
                            <div>
                                <p>Agência</p>
                                <strong>{work?.cancellation?.agencyNumber}</strong>
                            </div>
                            <div>
                                <p>Conta Corrente</p>
                                <strong>{work?.cancellation?.account}</strong>
                            </div>
                        </div>

                        {(roleValue?.includes("CANCELLATION_READ")) && (
                            <>
                                <button className={styles.buttonEdit} onClick={() => setShowEdit(true)}>Editar</button>
                                {showEdit && (
                                    showForm()
                                )}

                            </>
                        )}
                    </div>
                ) : (
                    (roleValue?.includes("CANCELLATION_READ")) && (
                        showForm()
                    )
                )}
            </>
        );
    }

    const dataCancelInformation = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformation}>
                        <div>
                            <p>Produto</p>
                            <strong>{data?.insurancePlan?.product?.code}</strong>
                        </div>
                        <div>
                            <p>Seguro</p>
                            <strong>{data?.insurancePlan?.typePlan?.description}</strong>
                        </div>
                        <div>
                            <p>Data de início da vigência</p>
                            <strong>{data?.validityPeriodStart ? moment(data?.validityPeriodStart).format('DD/MM/YYYY') : ''}</strong>
                        </div>
                        <div>
                            <p>Número de certificado</p>
                            <strong>{data?.certificateCode}</strong>
                        </div>
                        <div>
                            <p>Valor do seguro</p>
                            <strong>{data?.data?.financingAmount && data?.data?.financingAmount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Prêmio do seguro</p>
                            <strong>{data?.premiumValue && data?.premiumValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Valor da parcela</p>
                            <strong>{data?.data?.installmentValue && data?.data?.installmentValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </div>
                        <div>
                            <p>Quantidade de parcelas</p>
                            <strong>{data?.data?.installmentAmount}</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    async function getDocuments() {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setDocumentsBbc(response?.data);

            })
                .catch((error) => {
                    setDocumentsBbc([])
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getDocuments();
    }, [])
    return (
        <div className={styles.insuranceCancelContainer}>
            {loading && <Loading />}
            {openCancelContractModal && (<ModalCancelContract setOpenModal={setOpenCancelContractModal} data={data} updateData={updateDataAfterCancelContract} setRemoveLoading={setRemoveLoading} />)}
            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Seguro',
                        'Cancelamento',
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{dataCancelInformation()}</>,
                        <div className={stylesModal.content}>
                            {data?.proposalStages?.map((work, index) => (
                                work?.status?.code?.toLowerCase() === 'complete'
                                    && work?.stages?.code === "INSURANCE_CANCEL"
                                    && data?.cancellation?.origin?.code === "BBC" ? (
                                    <div key={work?.id?.proposalId}>
                                        {renderInformationCancel(data)}
                                    </div>
                                ) : ''
                            ))}</div>,
                    ]}
                />
            </>)}
        </div>
    )
}

export default InsuranceCancel