import styles from './InputFilter.module.css'

function InputFilter({ type, text, name, placeholder, handleOnChange, value, required = true, maskType = '', maxLength = null }) {
    function mask(i, t) {
        var v = i?.target?.value;

        if (isNaN(v[v.length - 1])) {
            i.target.value = v.substring(0, v.length - 1);
            return;
        }

        if (t === "data") {
            i.target.setAttribute("maxlength", "10");
            if (v.length === 2 || v.length === 5) i.target.value += "/";
        }

        if (t === "cpf") {
            i.target.setAttribute("maxlength", "14");
            if (v.length === 3 || v.length === 7) i.target.value += ".";
            if (v.length === 11) i.target.value += "-";
        }

        if (t === "cnpj") {
            i.target.setAttribute("maxlength", "18");
            if (v.length === 2 || v.length === 6) i.target.value += ".";
            if (v.length === 10) i.target.value += "/";
            if (v.length === 15) i.target.value += "-";
        }

        if (t === "cep") {
            i.target.setAttribute("maxlength", "9");
            if (v.length === 5) i.target.value += "-";
        }

        if (t === "cpf_cnpj") {
            v = v.replace(/\D/g, "")

            i.target.setAttribute("pattern", "([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})"); // eslint-disable-line
            if (v.length <= 14) { //CPF

                //Coloca um ponto entre o terceiro e o quarto dígitos
                v = v.replace(/(\d{3})(\d)/, "$1.$2")

                //Coloca um ponto entre o terceiro e o quarto dígitos
                //de novo (para o segundo bloco de números)
                v = v.replace(/(\d{3})(\d)/, "$1.$2")

                //Coloca um hífen entre o terceiro e o quarto dígitos
                v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

            } else { //CNPJ
                i.target.setAttribute("maxlength", "18");
                //Coloca ponto entre o segundo e o terceiro dígitos
                v = v.replace(/^(\d{2})(\d)/, "$1.$2")

                //Coloca ponto entre o quinto e o sexto dígitos
                /* eslint-disable-line */
                v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

                //Coloca uma barra entre o oitavo e o nono dígitos
                /* eslint-disable-line */
                v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")

                //Coloca um hífen depois do bloco de quatro dígitos
                v = v.replace(/(\d{4})(\d)/, "$1-$2")

                v = v.substring(0, v.length - 1)
            }
            i.target.value = v;
        }

        if (t === "tel") {
            if (v[0] === 9) {
                i.target.setAttribute("maxlength", "10");
                if (v.length === 5) i.target.value += "-";
            } else {
                i.target.setAttribute("maxlength", "9");
                if (v.length === 4) i.target.value += "-";
            }
        }
    }
    return (
        <div className={styles.formControl}>
            <label htmlFor={name}>{text}</label>
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                required={required}
                autoComplete={"on"}
                onInput={(e) => mask(e, maskType)}
                maxLength={maxLength}
            />
        </div>
    );
}

export default InputFilter;