import styles from './Input.module.css'

function Input({ type, text, name, placeholder, handleOnChange, value, required = true, maxLength = 20, max = 9999999999, maskType = null }) {

    const mask = (e, maskType = null) => {
        if (maskType === null) return;

        let phone = e?.target?.value;
        if (maskType === "phone") {
            if(phone === "") phone = "+55";
            phone = phone.replace(/\D/g, "")
            phone = phone.replace(/^(\d)/, "+$1")
            phone = phone.replace(/(.{3})(\d)/, "$1($2")
            phone = phone.replace(/(.{6})(\d)/, "$1)$2")
            if (phone.length === 12) {
                phone = phone.replace(/(.{1})$/, "-$1")
            } else if (phone.length === 13) {
                phone = phone.replace(/(.{2})$/, "-$1")
            } else if (phone.length === 14) {
                phone = phone.replace(/(.{3})$/, "-$1")
            } else if (phone.length === 15) {
                phone = phone.replace(/(.{4})$/, "-$1")
            } else if (phone.length > 15) {
                phone = phone.replace(/(.{4})$/, "-$1")
            }
            phone = phone.replace("+55", "55 ")
            phone = phone.replace(")", ") ")
            e.target.value = phone
        }
    }


    return (
        <div className={styles.formControl}>
            <label htmlFor={name}>{text}</label>
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                required={required}
                autoComplete={"on"}
                maxLength={maxLength}
                max={max}
                onInput={(e) => mask(e, maskType)}
            />
        </div>
    );
}

export default Input;