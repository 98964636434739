import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import styles from "./EditStore.module.css";
import { formatCnpjCpf } from "../../../../../../helpers/Format";
import {
  fieldsValidation,
  getCompaniesListToStore,
  updateStore,
} from "../../services/storeService";
import Loading from "../../../../../layout/loading/Loading";

const EditStore = ({ setOpenModal, data, allData, setData }) => {
  const [open, setOpen] = useState(true);
  const [companies, setCompanies] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: data.name || "",
    origin: data.origin || "",
    companyId: data?.company?.id || "",
    description: data.description || "",
    cnpj: formatCnpjCpf(data.cnpj) || "",
  });
  const [errors, setErrors] = useState({});

  function closedModal() {
    setOpen(false);
    setOpenModal(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = fieldsValidation(formData, setErrors);

    if (Object.keys(newErrors).length === 0) {
      updateStore(setData, allData, formData, data);
    }
  };

  useEffect(() => {
    getCompaniesListToStore(setCompanies, setLoading);
  }, []);

  return (
    <Modal
      open={open}
      center
      onClose={closedModal}
      showCloseIcon={false}
      classNames={{
        modal: `${styles.modalContainer}`,
      }}
    >
      {loading && <Loading />}
      {!loading && (
        <div className={styles.modalEditContainer}>
          <h2 className={styles.modalTitle}>Editar Loja</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Nome
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`${styles.input} ${errors.name ? styles.error : ""}`}
                maxLength={50}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="origin" className={styles.label}>
                Origem
              </label>
              <input
                type="text"
                id="origin"
                name="origin"
                value={formData.origin}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.origin ? styles.error : ""
                }`}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyId" className={styles.label}>
                Companhia
              </label>
              <select
                id="companyId"
                name="companyId"
                value={formData.companyId}
                onChange={handleChange}
                className={`${styles.select} ${
                  errors.companyId ? styles.error : ""
                }`}
              >
                {companies &&
                  companies?.length > 0 &&
                  companies?.map((company) => (
                    <option key={company?.id} value={company?.id}>
                      {company?.description}
                    </option>
                  ))}
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="description" className={styles.label}>
                Descrição
              </label>
              <input
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.description ? styles.error : ""
                }`}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="cnpj" className={styles.label}>
                CNPJ
              </label>
              <input
                type="text"
                id="cnpj"
                name="cnpj"
                value={formatCnpjCpf(formData.cnpj)}
                onChange={handleChange}
                className={`${styles.input} ${errors.cnpj ? styles.error : ""}`}
              />
            </div>

            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={closedModal}
              >
                Cancelar
              </button>
              <button type="submit" className={styles.submitButton}>
                Salvar
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default EditStore;
