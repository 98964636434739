import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './ModalMessage.module.css';
const ModalMessage = ({ setOpenModal, message }) => {
    const [open, setOpen] = useState(true);

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }
    return (
        <Modal open={open} center onClose={closedModal}>
            {message ? (
                <div className={styles.container}>
                    <h2 className={styles.tittle}>Mensagem de erro</h2>
                    {message}
                </div>
            ) : "Nenhuma mensagem de erro registrada!"}
        </Modal >
    )
}

export default ModalMessage