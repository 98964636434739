import { useState } from "react"

const ResetButton = ({ data, handleReset }) => {

    const [loading, setLoading] = useState(false);
    return (
        <>
            {!loading ? (
                <button onClick={() => handleReset(data, setLoading)}>Resetar</button>

            ) : <button disabled>Aguarde...</button>}
        </>
    )
}

export default ResetButton