import React, { useEffect, useState } from 'react'
import api from '../../../services/api';
import Loading from '../../layout/loading/Loading';
import moment from 'moment';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';

const VehicleInspection = ({ item, openModalEvent }) => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const getIncidents = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT}`,
            {
                params: {
                    code: item?.stages?.code,
                    proposalId: item?.id?.proposalId
                }
            }, {
        }).then((response) => {
            setData(response?.data.inspectionDataTablesDto)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null); setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getIncidents();
            }
        })
    }, [])

    return (
        <>
            {loading && <Loading />}
            {!loading && (<>
                <h2>{item?.stages?.description}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Cliente
                        </div>
                        <div className="cell cell-tittle">
                            Telefone
                        </div>
                        <div className="cell cell-tittle">
                            Placa
                        </div>
                        <div className="cell cell-tittle">
                            Message
                        </div>
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                        <div className="cell cell-tittle">
                            Atualizado em
                        </div>
                    </div>

                    <div className="row">
                        <div className="cell  cell-text">
                            <strong>Cliente </strong>
                            {data?.scoreNotificationDto?.customer}
                        </div>
                        <div className="cell cell-text">
                            <strong>Telefone </strong>
                            {data?.registrationDto?.cellPhone}
                        </div>
                        <div className="cell cell-text">
                            <strong>Placa </strong>
                            {data?.registrationDto?.plate}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.registrationDto?.message?.descriptionDetails)}>
                            <strong>Mensagem </strong>
                            {data?.registrationDto?.message?.descriptionDetails}
                        </div>
                        <div className="cell cell-text">
                            <strong>Status </strong>
                            {data?.registrationDto?.status}
                        </div>
                        <div className="cell cell-text">
                            <strong>Criado em </strong>
                            {data?.registrationDto?.createdAt && moment(data?.registrationDto?.createdAt).format('DD/MM/YYYY HH:mm')}

                        </div>
                        <div className="cell cell-text">
                            <strong>Atualizado em </strong>
                            {data?.registrationDto?.updatedAt && moment(data?.registrationDto?.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>

                <h2>{"Notificação"}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Status função
                        </div>
                        <div className="cell cell-tittle">
                            Descrição
                        </div>
                        <div className="cell cell-tittle">
                            Messagem
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                        <div className="cell cell-tittle">
                            Atualizado em
                        </div>
                    </div>

                    <div className="row">
                        <div className="cell cell-text">
                            <strong>Status </strong>
                            {data?.notificationDto?.status}
                        </div>
                        <div className="cell cell-text">
                            <strong>Status função </strong>
                            {data?.notificationDto?.funcaoStatus}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.notificationDto?.message?.description)}>
                            <strong>Descrição </strong>
                            {data?.notificationDto?.message?.description}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.notificationDto?.message?.descriptionDetails)}>
                            <strong>Mensagem </strong>
                            {data?.notificationDto?.message?.descriptionDetails}
                        </div>
                        <div className="cell cell-text">
                            <strong>Criado em </strong>
                            {data?.registrationDto?.createdAt && moment(data?.registrationDto?.createdAt).format('DD/MM/YYYY HH:mm')}

                        </div>
                        <div className="cell cell-text">
                            <strong>Atualizado em </strong>
                            {data?.registrationDto?.updatedAt && moment(data?.registrationDto?.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>
            </>)}
        </>
    )
}

export default VehicleInspection