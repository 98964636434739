import { IdleTimerProvider } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'


// useContext
import { useContext } from 'react';
import { RoleContext } from '../hooks/HookUseContext';

function IdleTimerContainer() {
    const navigate = useNavigate();
    const { setRoleValue } = useContext(RoleContext);
    const onIdle = () => {
        let nameUrl = window.location.pathname;
        if (nameUrl === '/login') return;

        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("app:token-auth");
        localStorage.clear();
        setRoleValue(null)
        navigate("/login");
    }

    return (
        <>
            <IdleTimerProvider
                timeout={process.env.REACT_APP_INACTIVE * 1000 * 60}
                onIdle={onIdle}
            >
            </IdleTimerProvider>
        </>
    )
}

export default IdleTimerContainer;
