import { useContext, useEffect, useState } from "react";
import styles from "./TableCompanyActions.module.css";
import "react-toastify/dist/ReactToastify.min.css";
import { useNavigate } from "react-router-dom";
import {
  getCompaniesAction,
  handleCurrentPage,
  handleInputChange,
  handleSearch,
} from "./services/companyActionsService";

import { FiEdit2 } from "react-icons/fi";
import CreateCompanyActions from "./modals/createCompanyActions/CreateCompanyActions";
import EditCompanyActions from "./modals/editCompanyActions/EditCompanyActions";
import Pagination from "../../../layout/pagination/Pagination";
import { RoleContext } from "../../../hooks/HookUseContext";
import Loading from "../../../layout/loading/Loading";
import ActionActivateOrDesactivate from "./actionActivateOrDesactivate/ActionActivateOrDesactivate";

const TableCompanyActions = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const { roleValue } = useContext(RoleContext);
  const [dataFilter, setDataFilter] = useState({});

  const [filters, setFilters] = useState({
    authUrl: "",
    notificationUrl: "",
    active: "",
  });

  useEffect(() => {
    getCompaniesAction(
      currentPage,
      dataFilter?.filters?.authUrl || null,
      dataFilter?.filters?.notificationUrl || null,
      dataFilter?.filters?.active || null,
      setData,
      setLoading
    );
  }, [currentPage, dataFilter]);

  const openModalEdit = (data) => {
    setCurrentData(data);
    setModalOpenEdit(true);
  };

  return (
    <div className={styles.divContainer}>
      {modalOpenEdit === true && (
        <EditCompanyActions
          setOpenModal={setModalOpenEdit}
          data={currentData}
          allData={data}
          setData={setData}
        />
      )}
      {modalOpenCreate === true && (
        <CreateCompanyActions
          setData={setData}
          setOpenModal={setModalOpenCreate}
          getCompaniesAction={getCompaniesAction}
        />
      )}
      <h2 className={styles.titleFilter}>Filtros</h2>
      <div className={styles.actions}>
        <div className={styles.filter}>
          <label>
            Auth url
            <input
              name="authUrl"
              type="text"
              placeholder="Auth url"
              value={filters.authUrl}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            />
          </label>
          <label>
            Notificação url
            <input
              name="notificationUrl"
              type="text"
              placeholder="Notificação url"
              value={filters.notificationUrl}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            />
          </label>
          <label>
            Status
            <select
              name="active"
              value={filters.active}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            >
              <option value="">Todos</option>
              <option value={true}>Ativos</option>
              <option value={false}>Inativos</option>
            </select>
          </label>
          <button
            onClick={() =>
              handleSearch(setDataFilter, filters, setCurrentPage, navigate)
            }
          >
            Buscar
          </button>
        </div>
        <div className={styles.filter}>
          {roleValue?.includes("COMPANY_CREATE") && (
            <button onClick={() => setModalOpenCreate(true)}>
              Criar action
            </button>
          )}
        </div>
      </div>
      {loading && <Loading />}
      {!loading && (
        <table className={styles.TableCompanyActions}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Auth url</th>
              <th>Notificação url</th>
              <th>Ações</th>
              {roleValue?.includes("COMPANY_DELETE") && (
                <th>Desativar/Ativar</th>
              )}
            </tr>
          </thead>
          <tbody>
            {data && data?.length === 0 && (
              <tr className={styles.noData}>
                <td>Não há dados</td>
              </tr>
            )}
            {data &&
              data?.content?.length > 0 &&
              data?.content?.map((currentData, index) => (
                <tr key={currentData?.id}>
                  <td>
                    <strong>Id</strong>
                    {currentData?.id}
                  </td>
                  <td>
                    <strong>Auth url</strong>
                    {currentData?.authUrl}
                  </td>
                  <td>
                    <strong>Notificação url</strong>
                    {currentData?.notificationUrl}
                  </td>
                  <td>
                    <strong>Ações</strong>
                    {roleValue?.includes("COMPANY_UPDATE") && (
                      <button
                        className={styles.buttonEdit}
                        onClick={() => openModalEdit(currentData)}
                      >
                        <FiEdit2 />
                        Editar
                      </button>
                    )}
                  </td>
                  {roleValue?.includes("COMPANY_DELETE") && (
                    <td>
                      <strong>Desativar/Ativar</strong>
                      <ActionActivateOrDesactivate
                        currentData={currentData}
                        data={data}
                        setData={setData}
                      />
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {data && data?.content?.length > 0 && (
        <Pagination
          className={styles.pagination_bar}
          currentPage={currentPage === 0 ? 1 : currentPage + 1}
          totalCount={data?.totalElements ? data?.totalElements : []}
          pageSize={pageSize ? pageSize : 1}
          onPageChange={(page) =>
            handleCurrentPage(page - 1, setCurrentPage, navigate)
          }
        />
      )}
    </div>
  );
};

export default TableCompanyActions;
