import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import styles from "./CreateCompany.module.css";
import Loading from "../../../../../layout/loading/Loading";
import {
  createCompany,
  fieldsValidation,
  getCompaniesActionList,
  getuserAuthsList,
} from "../../tableCompany/services/companyService";

const CreateCompany = ({ setOpenModal, getCompanies, setData }) => {
  const [open, setOpen] = useState(true);
  const [userAuths, setUserAuths] = useState();
  const [companyActions, setCompanyActions] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    description: "",
    userAuthId: "",
    companyActionId: "",
  });
  const [errors, setErrors] = useState({});

  function closedModal() {
    setOpen(false);
    setOpenModal(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    const newErrors = fieldsValidation(formData, setErrors);

    if (Object.keys(newErrors).length === 0) {
      createCompany(formData, closedModal, getCompanies, setData, setLoading);
    }
  };

  useEffect(() => {
    getCompaniesActionList(setCompanyActions, setLoading);
    getuserAuthsList(setUserAuths, setLoading);
  }, []);

  return (
    <Modal
      open={open}
      center
      onClose={closedModal}
      showCloseIcon={false}
      classNames={{
        modal: `${styles.modalContainer}`,
      }}
    >
      {loading && <Loading />}
      {!loading && (
        <div className={styles.modalEditContainer}>
          <h2 className={styles.modalTitle}>Criar Companhia</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Código
              </label>
              <input
                type="text"
                id="code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                className={`${styles.input} ${errors.code ? styles.error : ""}`}
                maxLength={50}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="origin" className={styles.label}>
                Descrição
              </label>
              <input
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.description ? styles.error : ""
                }`}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyId" className={styles.label}>
                User Auth
              </label>
              <select
                id="userAuthId"
                name="userAuthId"
                value={formData.userAuthId}
                onChange={handleChange}
                className={`${styles.select} ${
                  errors.userAuthId ? styles.error : ""
                }`}
              >
                <option value=""> Selecione um Auth</option>
                {userAuths &&
                  userAuths?.length > 0 &&
                  userAuths?.map((user) => (
                    <option key={user?.id} value={user?.id}>
                      {user?.username}
                    </option>
                  ))}
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyActionId" className={styles.label}>
                Company Action
              </label>
              <select
                id="companyActionId"
                name="companyActionId"
                value={formData.companyActionId}
                onChange={handleChange}
                className={`${styles.select} ${
                  errors.companyActionId ? styles.error : ""
                }`}
              >
                <option value=""> Selecione um Company Action</option>
                {companyActions &&
                  companyActions?.length > 0 &&
                  companyActions?.map((company) => (
                    <option key={company?.id} value={company?.id}>
                      {company?.authUrl}
                    </option>
                  ))}
              </select>
            </div>

            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={closedModal}
              >
                Cancelar
              </button>
              <button type="submit" className={styles.submitButton}>
                Criar
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default CreateCompany;
