import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { checkJwt } from '../../../helpers/Jwt-helper';
import api from '../../../services/api';
import InputFilter from '../../form/InputFilter/InputFilter';
import Container from '../../layout/container/Container';
import Loading from '../../layout/loading/Loading';
import CreateUnavailabilityModal from '../../project/createUnavailabilityModal/CreateUnavailabilityModal';
import TableUnavailability from '../../project/tableUnavailability/TableUnavailability';
import styles from './Unavailability.module.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Unavailability = () => {

    const [modalOpenCreate, setModalOpenCreate] = useState(false);
    const [filterUnavailability, setFilterUnavailability] = useState({});
    const [removeLoading, setRemoveLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataTable, setDataTable] = useState([])

    const MINUTE_MS = process.env.REACT_APP_RELOAD_TABLE * 1000 * 60;

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(0);
                if (filterUnavailability.periodTo && filterUnavailability.periodOf) {
                    if (filterUnavailability.periodOf >= filterUnavailability.periodTo) {
                        toast.error(`a data "Periodo (de)" tem que ser menor que a data "Periodo (até)"`);
                        return;
                    } else {
                        getUnavailabilitySearch();
                        return;
                    }
                } else if (filterUnavailability.periodTo || filterUnavailability.periodOf) {
                    toast.warning(`Preencha às duas datas, por favor!`);
                    return;
                } else {
                    getUnavailability();
                    return;
                }
            }
        })
    }

    function handleChange(event) {
        setFilterUnavailability({ ...filterUnavailability, [event.target.name]: event.target.value })
    }

    function updateData() {
        getUnavailability();
    }


    async function getUnavailability() {
        try {
            setRemoveLoading(false);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_UNAVAILABILITY}?page=${currentPage}&size=${10}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
            }).then((response) => {
                setDataTable(response.data);
                setRemoveLoading(true);
            })
                .catch((error) => {
                    setDataTable([])
                    console.log(error)
                    setRemoveLoading(true)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }


    async function getUnavailabilitySearch() {
        try {
            setRemoveLoading(false);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_UNAVAILABILITY_SEARCH}?page=${currentPage}&size=${10}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
                params: {
                    startDate: filterUnavailability.periodOf ? moment(filterUnavailability.periodOf).format('DD/MM/YYYY') : '',
                    endDate: filterUnavailability.periodTo ? moment(filterUnavailability.periodTo).format('DD/MM/YYYY') : '',
                }
            }).then((response) => {
                setDataTable(response.data);
                setRemoveLoading(true);
            })
                .catch((error) => {
                    setDataTable([])
                    console.log(error)
                    setRemoveLoading(true)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getUnavailability();
        // eslint-disable-next-line   
    }, [currentPage, MINUTE_MS])
    return (
        <div className={styles.unavailabilityContainer}>
            {modalOpenCreate === true && <CreateUnavailabilityModal setOpenModal={setModalOpenCreate} updateData={updateData} />}
            <div className={`${styles.card} ${styles.column}`}  >
                <h2>Filtro por período</h2>
                <div className={styles.filter}>
                    <form onSubmit={handleSubmit}>
                        <InputFilter type="datetime-local" name={"periodOf"} text="Periodo (de)" handleOnChange={handleChange} required={false} value={filterUnavailability.periodOf || ''} />
                        <InputFilter type="datetime-local" name={"periodTo"} text="Periodo (até)" handleOnChange={handleChange} required={false} value={filterUnavailability.periodTo || ''} />
                        <button>
                            <AiOutlineSearch />
                            Buscar
                        </button>
                    </form>
                </div>
            </div>
            <div className={styles.card}>
                <h2>Indisponibilidades</h2>
                <div className={styles.buttons}>
                    <button className={styles.new} onClick={() => setModalOpenCreate(true)}><GrAdd />Novo Período</button>
                </div>
                {!removeLoading && <Loading />}
                <Container>
                    {removeLoading && dataTable && (
                        <TableUnavailability object={dataTable} handleCurrentPage={setCurrentPage} currentPage={currentPage} updateData={updateData} />
                    )}
                </Container>
            </div>
        </div>
    )
}

export default Unavailability