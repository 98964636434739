import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './ModalChangeStatus.module.css';
import { CiWarning } from 'react-icons/ci';
const ModalChangeStatus = ({ setOpenModal, methodAction, itemChange }) => {
    const [open, setOpen] = useState(true);

    function closedModal(isChange) {
        methodAction(isChange, itemChange)
        setOpen(false);
        setOpenModal(false);
    }
    return (
        <Modal open={open} center onClose={() => { }} closeIcon={true} styles={{
            modal: {
                width: 'auto',
                minWidth: '300px',
                maxWidth: '300px',
                minHeight: '200px',
                maxHeight: '200px',
                height: 'auto'
            }

        }}>
            <div className={styles.modalContainer}>
                <CiWarning className={styles.warningIcon} />
                <span>Você deseja alterar o status?</span>
                <div className={styles.buttonContainer}>
                    <button className={styles.actionButton} onClick={() => closedModal(true)}>
                        Sim
                    </button>
                    <button className={styles.cancelButton} onClick={() => closedModal(false)}>
                        Não
                    </button>
                </div>
            </div>
        </Modal >
    )
}

export default ModalChangeStatus