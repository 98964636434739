import { useEffect, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import Tab from '../../tab/Tab';
import stylesDigital from './DigitalSignature.module.css';
import ChangeSignatureToManualModal from '../changeSignatureToManual/ChangeSignatureToManualModal';
import RejectProposalModal from '../rejectProposalModal/RejectProposalModal';
import api from '../../../services/api';
import { AiFillFilePdf } from 'react-icons/ai';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import ViewSubscribersModal from '../viewSubscribersModal/ViewSubscribersModal';
import ViewDocumentsModal from '../viewDocumentsModal/ViewDocumentsModal';

const DigitalSiginature = ({
    data,
    stage,
    proposalData,
    styles,
    roleValue,
    setModalData,
    setData,
    setRemoveLoading,
    updateTable,
    closedModal }) => {



    const [openChangeSignatureToManualModal, setOpenChangeSignatureToManualModal] = useState(false);
    const [openRejectProposalModal, setOpenRejectProposalModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameTabs, setNameTabs] = useState([])
    const [infoTabs, setInfoTabs] = useState([])
    const [documentsBbc, setDocumentsBbc] = useState(null);
    const [viewSignature, setViewSignature] = useState(null);
    const navigate = useNavigate();

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/SIG`, '_blank', 'noopener,noreferrer');
    }

    const checkTokenViewPDF = (id) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewPDF(id);
            }
        })
    }

    const viewSignatureDocumentsAction = () => {
        return (
            <div className={styles.buttonsActions}>
                {roleValue?.includes("DOCUMENT_CLIENT_READ") && documentsBbc &&
                    <>
                        <ViewDocumentsModal data={data} documentsBbc={documentsBbc} />
                    </>
                }
            </div>
        )
    }
    const viewSignatureAction = () => {
        return (
            <div className={`${styles.buttonsActions} ${styles.content}`}>
                {data?.proposalStages?.map((work, index) => (
                    work?.status?.code?.toLowerCase() !== 'pending'
                        && work?.stages?.code === "DIGITAL_SIGNATURE" &&
                        roleValue?.includes("HOLDER_READ") ? (
                        <div key={index}>
                            <ViewSubscribersModal data={data} updateTable={updateTable} />
                        </div>
                    ) : ''
                ))}
            </div>
        )
    }

    const viewDocumentsNotSignature = () => {
        return (
            <div className={`${stylesDigital.actionButtonsPDF} ${styles.content}`}>
                {documentsBbc && documentsBbc.length > 0 ? documentsBbc?.map((document) => (
                    <div key={document?.id} className={styles.teste}>
                        {roleValue?.includes("DOCUMENT_READ") && (
                            <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id)}>
                                <AiFillFilePdf />
                                {document?.documentType?.description}
                            </button>
                        )}
                        {roleValue?.includes("DOCUMENT_SPECIFIC_READ") &&
                            (document?.documentType?.code === "BSP" ||
                                document?.documentType?.code === "TSP") &&
                            (
                                <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id)}>
                                    <AiFillFilePdf />
                                    {document?.documentType?.description}
                                </button>
                            )
                        }
                    </div>
                )) : <button disabled className={styles.disabled}>Não há documentos</button>}
            </div>
        );
    }


    async function getDocuments() {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                const jsonData = response.data;
                const updatedData = jsonData.filter(item => item.documentType.code !== "BSP");
                setDocumentsBbc(updatedData);

            })
                .catch((error) => {
                    setDocumentsBbc([])
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }



    const actionsButtonsSignature = () => {
        return (
            <div className={`${styles.actionsButtons}`}>
                {data?.proposalStages?.map((work) => (
                    work?.status?.code?.toLowerCase() !== 'complete'
                    && work?.status?.code?.toLowerCase() !== 'canceled'
                    && work?.stages?.code === "DIGITAL_SIGNATURE"
                    && work?.active
                    && (roleValue?.includes("DIGITAL_SIGNATURE_TO_MANUAL_CREATE")) && (
                        <button key={work?.id?.proposalId} onClick={() => setOpenChangeSignatureToManualModal(true)}>
                            Definir Assinatura manual
                        </button>
                    )))}

                {data?.proposalStages?.map((work, index) => (
                    work?.status?.code?.toLowerCase() !== 'complete'
                    && work?.status?.code?.toLowerCase() !== 'canceled'
                    && work?.status?.code?.toLowerCase() !== 'rejected'
                    && work?.stages?.code === "DIGITAL_SIGNATURE"
                    && work?.active &&
                    (roleValue?.includes("PROPOSAL_REJECT_UPDATE")) && data?.data?.dataOrigin?.code === "FUNCAO" && (
                        <button key={work?.id?.proposalId} onClick={() => setOpenRejectProposalModal(true)}>
                            Rejeitar proposta
                        </button>
                    )))}
            </div>
        )
    }

    useEffect(() => [
        getDocuments()
    ], [])
    useEffect(() => {
        if (roleValue?.includes("DOCUMENT_CLIENT_READ") || roleValue?.includes("HOLDER_READ")) {
            setViewSignature(true)

        } else {
            setViewSignature(false)
        }
    }, [])


    useEffect(() => {

        if (viewSignature !== null) {
            let tabs = ['Proposta', 'Documentos']
            let info = [<>{proposalData()}</>, <>{viewDocumentsNotSignature()}</>]
            if (viewSignature) {
                tabs = ['Proposta', 'Assinantes', 'Documentos']
                info = [<>{proposalData()}</>, <>{viewSignatureAction()}</>, <>{viewSignatureDocumentsAction()}</>]
            }
            setNameTabs(tabs)
            setInfoTabs(info);
        }
    }, [documentsBbc, viewSignature])


    function updateChangeSignature(response, status) {
        if (response) {
            setModalData(response);
            setData(response);
            updateTable();
            closedModal()
        }
    }
    function updateAfterRejectProposal(response, status) {
        if (response) {
            console.log(response)
            setModalData(response);
            setData(response);
            updateTable();
            closedModal();
        }
    }

    return (
        <div className={stylesDigital.digitalSignatureContainer}>

            {loading && <Loading />}

            {openChangeSignatureToManualModal && (<ChangeSignatureToManualModal setOpenModal={setOpenChangeSignatureToManualModal} data={data} updateData={updateChangeSignature} setRemoveLoading={setRemoveLoading} />)}
            {openRejectProposalModal && (<RejectProposalModal setOpenModal={setOpenRejectProposalModal} data={data} updateData={updateAfterRejectProposal} setRemoveLoading={setRemoveLoading} />)}
            {!loading && (<>
                <Tab
                    tabsName={nameTabs}
                    tabsInfo={infoTabs}
                />
                <div className={stylesDigital.actions}>
                    {actionsButtonsSignature()}
                </div>
            </>)}
        </div>
    )
}

export default DigitalSiginature