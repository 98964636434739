import { useContext, useEffect, useState } from 'react';
import Tab from '../../tab/Tab';
import styles from './Inspection.module.css';
import stylesModal from '../../project/tableMonitoringModal/ModalMonitoring.module.css';
import api from '../../../services/api';
import Loading from '../../layout/loading/Loading';
import { AiFillFilePdf } from 'react-icons/ai';
import moment from 'moment';
import { RoleContext } from '../../hooks/HookUseContext';
import { useNavigate } from 'react-router-dom';


const Inspection = ({ data, stage, proposalData }) => {
    const { roleValue } = useContext(RoleContext);
    const [loading, setLoading] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const [dataInspection, setDataInspection] = useState(null);
    const history = useNavigate();
    const getInspection = async () => {
        try {
            setLoading(true)
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INSPECTION_INFORMATION}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setLoading(false);
                setDataInspection(response?.data)
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const getBase64Pdf = async (id, name) => {
        localStorage.setItem('n', name);
        viewPDF(id);
    }

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/INP/${data?.id}`, '_blank', 'noopener,noreferrer');
    }

    function phoneMask(phone) {
        if (!phone) return;
        if (!phone) return phone;

        phone = phone.replace(/\D/g, "");

        if (phone.length <= 10) {
            phone = phone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        } else if (phone.length === 11) {
            phone = phone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
        } else {
            phone = phone.replace(/^(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3-$4");
        }

        return phone;
    }

    useEffect(() => {

        getInspection();
        return () => clearTimeout();
    }, [])

    const inspectionInformation = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformationInspection}>
                        <div>
                            <p>Placa</p>
                            <strong>{dataInspection?.plate?.toString()?.toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Chassi</p>
                            <strong>{dataInspection?.chassi?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Telefone</p>
                            <strong>{dataInspection?.cellPhone ? phoneMask(dataInspection?.cellPhone) : ''}</strong>
                        </div>

                        <div>
                            <p>Região</p>
                            <strong>{dataInspection?.region?.toString().toUpperCase()}</strong>

                        </div>
                        <div>
                            <p>País</p>
                            <strong>{dataInspection?.country?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Uf</p>
                            <strong>{dataInspection?.uf?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Ano de fabricação</p>
                            <strong>{dataInspection?.yearManufacture?.toString().toUpperCase()}</strong>

                        </div>


                        <div>
                            <p>Combustível</p>
                            <strong>{dataInspection?.fuel?.toString().toUpperCase()}</strong>
                        </div>


                        <div>
                            <p>Modelo</p>
                            <strong>{dataInspection?.model?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Ano do modelo</p>
                            <strong>{dataInspection?.yearModel?.toString().toUpperCase()}</strong>

                        </div>
                        <div>
                            <p>Tipo do veículo</p>
                            <strong>{dataInspection?.vehicleType?.toString().toUpperCase()}</strong>
                        </div>

                        <div>
                            <p>Chassi reprogramado</p>
                            <strong>{dataInspection?.rescheduledChassis?.toString().toUpperCase() === "S" && "SIM"}</strong>
                            <strong>{dataInspection?.rescheduledChassis?.toString().toUpperCase() === "N" && "NÃO"}</strong>
                            <strong>{
                                dataInspection?.rescheduledChassis?.toString().toUpperCase() !== "S"
                                && dataInspection?.rescheduledChassis?.toString().toUpperCase() !== "N"
                                && dataInspection?.rescheduledChassis?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Chapeamento ano mês</p>
                            <strong>{dataInspection?.platingYearMonth?.toString().toUpperCase()}</strong>

                        </div>
                        <div>
                            <p>Categoria do veículo</p>
                            <strong>{dataInspection?.vehicleCategory?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Cmt</p>
                            <strong>{dataInspection?.cmt?.toString().toUpperCase()}</strong>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    const inspectionStatus = () => {
        return (
            <>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformationInspection}>
                        <div>
                            <p>Protocolo</p>
                            <strong>{dataInspection?.protocol?.toString()}</strong>
                        </div>
                        <div>
                            <p>Data de criação</p>
                            <strong>{dataInspection?.protocolCreatedAt ? moment(dataInspection?.protocolCreatedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</strong>
                        </div>
                    </div>
                </div>
                <div className={stylesModal.content}>
                    {dataInspection && dataInspection?.statuses ? dataInspection?.statuses.map((status, index) => (
                        <div className={styles.list} key={index}>
                            <p><strong>Valor:</strong> {status?.value}</p>
                            <p><strong>Criado em:</strong>  {status?.createdAt ? moment(status?.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''} </p>
                        </div>
                    )) : 'Nenhum status'}
                </div>
            </>
        );
    };

    const inspectionRestriction = () => {
        return (
            <div className={stylesModal.content}>
                {dataInspection && dataInspection?.lastCccurrenceRestriction ? dataInspection?.lastCccurrenceRestriction.map((restriction, index) => (
                    <div key={index} className={styles.list}>
                        <p>Restrição {index + 1}</p>
                        <strong>{restriction}</strong>
                    </div>
                )) : 'Nenhuma restrição'}
            </div>
        )
    }

    const inspectionDocuments = () => {
        return (
            <div className={stylesModal.content}>
                {roleValue?.includes("DOCUMENT_INSPECTION_READ") && dataInspection && dataInspection?.documentResponseDtos ? dataInspection?.documentResponseDtos.map((doc, index) => (
                    <div className={styles.actionButtonsPDF} key={doc?.id?.documentType?.id || index}>
                        {!loadingDocument ? (

                            <button onClick={() => getBase64Pdf(doc?.id?.documentType?.id, doc?.name)}>
                                <AiFillFilePdf />
                                {doc?.id?.documentType?.description}
                            </button>
                        ) : <button disabled>Aguarde...</button>}
                    </div>
                )) : 'Nenhum documento'}


            </div>
        )
    }
    return (
        <div className={styles.inspectionContainer}>
            {loading && <Loading />}

            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Informações',
                        'Documentos',
                        'Status',
                        'Restrições']}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{inspectionInformation()}</>,
                        <>{inspectionDocuments()}</>,
                        <>{inspectionStatus()}</>,
                        <>{inspectionRestriction()}</>]}
                />
            </>)}
        </div>
    )
}

export default Inspection