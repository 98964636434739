import Pagination from "../../layout/pagination/Pagination"
import styles from './TableUnavailability.module.css';

import { FiEdit2 } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { useState } from 'react';
import DeleteGenericModal from '../deleteGenericModal/DeleteGenericModal';
import api from '../../../services/api';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useNavigate } from 'react-router-dom';
import EditUnavailabilityModal from "../editUnavailabilityModal/EditUnavailabilityModal";
import moment from 'moment';
const TableUnavailability = ({ object, handleCurrentPage, currentPage, updateData }) => {

    const navigate = useNavigate();
    const [modalOpenDelete, setModalOpenDelete] = useState(false);
    const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [currentData, setCurrentData] = useState(null);

    let PageSize = object?.size;
    let currentTableData = object?.content || object || [];

    const openModalDelete = (data) => {
        setCurrentData(data);
        setModalOpenDelete(true);
    }

    const openModalEdit = (data) => {
        setCurrentData(data);
        setModalOpenEdit(true);
    }
    const deleteUnavailability = (data) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                api.delete(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_UNAVAILABILITY}/${data?.id}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    toast.success(`${response?.data?.message}`);
                    updateData();
                }).catch((error) => {
                    console.log(error);
                    toast.error(`Deu erro! ${error?.response?.data?.message}`);
                })
            }
        })
    }
    return (
        <div className={styles.divContainer}>
            {modalOpenDelete === true && <DeleteGenericModal setOpenModal={setModalOpenDelete} textTittle={"Deletando um período!"} textMessage={"Você irá deletar um período, tem certeza disso?"} deleteEvent={deleteUnavailability} data={currentData} />}
            {modalOpenEdit === true && <EditUnavailabilityModal setOpenModal={setModalOpenEdit} data={currentData} updateData={updateData} />}
            <table className={styles.tableUnavailabilityContainer}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Periodo (de)</th>
                        <th>Periodo (até)</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTableData && currentTableData?.length === 0 && (
                        <tr className={styles.noData}>
                            <td>Não há dados</td>
                        </tr>
                    )}
                    {currentTableData && currentTableData?.length > 0 && (
                        currentTableData.map((data) => (
                            <tr key={data.id}>
                                <td>
                                    <strong>Id</strong>
                                    {data?.id}
                                </td>
                                <td>
                                    <strong>Periodo (de)</strong>
                                    {data?.startDate ? moment(data?.startDate).format('DD/MM/YYYY HH:mm') : ''}
                                </td>
                                <td>
                                    <strong>Periodo (até)</strong>
                                    {data?.endDate ? moment(data?.endDate).format('DD/MM/YYYY HH:mm') : ''}
                                </td>
                                <td>
                                    <strong>Descrição</strong>
                                    {data?.description}
                                </td>
                                <td>
                                    <strong>Ações</strong>
                                    <div className={styles.buttons}>
                                        <button className={styles.edit} onClick={() => openModalEdit(data)}><FiEdit2 />Editar</button>
                                        <button className={styles.delete} onClick={() => openModalDelete(data)}><AiFillDelete />Deletar</button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <Pagination
                className={styles.pagination_bar}
                currentPage={currentPage === 0 ? 1 : currentPage + 1}
                totalCount={object.totalElements ? object.totalElements : []}
                pageSize={PageSize ? PageSize : 1}
                onPageChange={page => handleCurrentPage(page - 1)}
            />
        </div>
    )
}

export default TableUnavailability