// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Message_message__iJpdJ{\r\n    width: auto;\r\n    padding:  1em;\r\n    border:  1px solid #000;\r\n    margin: 0 auto;\r\n    text-align: center;\r\n    margin: 1em;\r\n    border-radius: 5px;\r\n}\r\n\r\n.Message_sucess__Io-T4{\r\n    color: #155724;\r\n    background-color: #d4edda;\r\n    border-color: #c3e6cb;\r\n}\r\n\r\n.Message_error__AXXTz{\r\n    color: #721c24;\r\n    background-color: #f8d7da;\r\n    border-color: #f5c6cb;\r\n}", "",{"version":3,"sources":["webpack://./src/components/layout/Message/Message.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".message{\r\n    width: auto;\r\n    padding:  1em;\r\n    border:  1px solid #000;\r\n    margin: 0 auto;\r\n    text-align: center;\r\n    margin: 1em;\r\n    border-radius: 5px;\r\n}\r\n\r\n.sucess{\r\n    color: #155724;\r\n    background-color: #d4edda;\r\n    border-color: #c3e6cb;\r\n}\r\n\r\n.error{\r\n    color: #721c24;\r\n    background-color: #f8d7da;\r\n    border-color: #f5c6cb;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "Message_message__iJpdJ",
	"sucess": "Message_sucess__Io-T4",
	"error": "Message_error__AXXTz"
};
export default ___CSS_LOADER_EXPORT___;
