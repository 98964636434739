import { useContext, useEffect, useState } from 'react';
import styles from './DocumentScoreDataCadastral.module.css';
import Tab from '../../tab/Tab';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';
import { AiFillFilePdf } from 'react-icons/ai';
import { RoleContext } from '../../hooks/HookUseContext';
import Loading from '../../layout/loading/Loading';
import api from '../../../services/api';
import moment from 'moment';
import ClearSaleChart from '../clearSaleChart/ClearSaleChart';
import ModalDetailsDocumentDataCadastral from '../modalDetailsDocumentDataCadastral/ModalDetailsDocumentDataCadastral';

const DocumentScoreDataCadastral = ({ data, proposalData }) => {
    const [loading, setLoading] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const { roleValue } = useContext(RoleContext);
    const [documentScoreDataCadastral, setDocumentScoreDataCadastral] = useState(null);

    const [modalDetailDataCadastral, setModalDetailDataCadastral] = useState(false)
    const onCloseModalDetailDataCadastral = () => setModalDetailDataCadastral(false);
    const [currentCpfCnpj, setCurrentCpfCnpj] = useState('');
    const [currentName, setCurrentName] = useState('');
    const [documents, setDocuments] = useState(null);
    const [type, setType] = useState(null);

    const getDocumentScoreDataCadastral = async () => {
        try {
            setLoading(true)
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SCORE_DATA_CADASTRAL_DETAILS}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setLoading(false);
                setDocumentScoreDataCadastral(response?.data)
                setDocuments(response?.data?.documents);
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const downloadBase64 = async (id, name) => {
        localStorage.setItem('n', name);
        viewPDF(id);
    }

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/CLS`, '_blank', 'noopener,noreferrer');
    }

    useEffect(() => {
        getDocumentScoreDataCadastral();
    }, [])

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }

    const formatarCEP = (input) => {
        const numericOnly = input.replace(/\D/g, '');

        if (numericOnly?.length === 8) {
            return `${numericOnly?.substring(0, 5)}-${numericOnly?.substring(5)}`;
        } else {
            return null;
        }
    };

    const openModalDetail = (cpf, name, type) => {
        setType(type);
        setCurrentCpfCnpj(cpf)
        setCurrentName(name);
        setModalDetailDataCadastral(true)
    }


    const documentScoreDataCadastralInformation = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformation}>
                        <div>
                            <p>Data da consulta</p>
                            <strong>{documentScoreDataCadastral?.date && moment(documentScoreDataCadastral?.date).format('DD/MM/YYYY HH:mm')}</strong>
                        </div>
                        <div>
                            <p>status</p>
                            <strong>{documentScoreDataCadastral?.statusInfo}</strong>
                        </div>
                        {documentScoreDataCadastral?.sentNextFlow === true && (
                            <div>
                                <p>Etapa libera por limite de erros?</p>
                                <strong>SIM</strong>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.involveds}>
                    <h3>Envolvidos</h3>
                    {documentScoreDataCadastral && documentScoreDataCadastral?.involveds ? documentScoreDataCadastral?.involveds?.map((involved) => (
                        <div className={styles.involved} key={involved?.cpfCnpj}>
                            <div className={styles.one}>
                                <div >
                                    <p>Nome </p>
                                    <strong>{involved?.name}</strong>
                                </div>
                                <div >
                                    <p>Cpf/Cnpj </p>
                                    <strong>{involved?.cpfCnpj && formatCnpjCpf(involved?.cpfCnpj)}</strong>
                                </div>
                                <div >
                                    <p>Cep</p>
                                    <strong>{involved?.address?.zipCode && formatarCEP(involved?.address?.zipCode)}</strong>
                                </div>
                            </div>
                            <div className={styles.two}>
                                <div >
                                    <p>Rua</p>
                                    <strong>{involved?.address?.street}</strong>
                                </div>
                                <div >
                                    <p>Distrito</p>
                                    <strong>{involved?.address?.district}</strong>
                                </div>
                                <div >
                                    <p>Complemento</p>
                                    <strong>{involved?.address?.complement}</strong>
                                </div>

                            </div>

                            <div className={styles.three}>
                                <div >
                                    <p>Cidade</p>
                                    <strong>{involved?.address?.city}</strong>
                                </div>
                                <div >
                                    <p>Estado</p>
                                    <strong>{involved?.address?.state}</strong>
                                </div>
                                <div >
                                    <p>Motivo</p>
                                    <strong>{involved?.score?.status}</strong>
                                </div>
                                <div style={{ display: "flex", flexWrap: 'wrap' }}>
                                    <div>
                                        <p>Score </p>
                                        <strong>{involved?.score?.number && involved?.score?.number.replace(".", ",")}</strong>
                                    </div>
                                    <div>
                                        {involved?.score?.number &&
                                            <div className={`chart`}><ClearSaleChart score={involved?.score?.number} /></div>
                                        }
                                    </div>
                                </div>
                                {roleValue?.includes("SCORE_DATA_DETAILS_DETAIL_READ") &&
                                    <div className={styles.buttons}>
                                        <button onClick={() => openModalDetail(involved?.cpfCnpj, involved?.name, "Insights")} className={styles.btnDetails}>Insights</button>
                                        <button onClick={() => openModalDetail(involved?.cpfCnpj, involved?.name, "Ratings")} className={styles.btnDetails}>Ratings</button>
                                    </div>
                                }
                            </div>


                        </div>
                    )) : 'Nenhum envolvido'}
                </div>

            </div>
        )
    }


    const documentScoreDataCadastralDocuments = () => {
        return (
            <div className={stylesModal.content}>
                {roleValue?.includes("DOCUMENT_SCORE_DATA_READ") && documents && documents.length > 0 ? documents.map((doc, index) => (
                    <div className={styles.actionButtonsPDF} key={doc?.id || index}>
                        {!loadingDocument ? (
                            <button onClick={() => downloadBase64(doc?.id, doc?.name)}>
                                <AiFillFilePdf />
                                {doc?.cpfCnpj ? doc?.cpfCnpj + " - " + doc?.documentType?.description : doc?.documentType?.description}
                            </button>
                        ) : <button disabled>Aguarde...</button>}
                    </div>
                )) : 'Nenhum documento'}
            </div>
        )
    }

    return (
        <div className={styles.documentScoreDataCadastralContainer}>
            {modalDetailDataCadastral === true &&
                <ModalDetailsDocumentDataCadastral
                    open={modalDetailDataCadastral}
                    onCloseModal={onCloseModalDetailDataCadastral}
                    proposalId={data?.id}
                    currentCpfCnpj={currentCpfCnpj}
                    setDocuments={setDocuments}
                    currentName={currentName}
                    type={type}
                />}
            {loading && <Loading />}
            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Informações',
                        'Documentos',
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{documentScoreDataCadastralInformation()}</>,
                        <>{documentScoreDataCadastralDocuments()}</>,
                    ]}
                />
            </>)}
        </div>
    )
}

export default DocumentScoreDataCadastral