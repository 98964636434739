import { useContext, useEffect, useState } from "react";
import styles from "./TableShops.module.css";
import "react-toastify/dist/ReactToastify.min.css";
import ActionActivateOrDesactivate from "./actionActivateOrDesactivate/ActionActivateOrDesactivate";
import Pagination from "../../../layout/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import Loading from "../../../layout/loading/Loading";
import {
  getCompaniesListToStore,
  getStores,
  handleCurrentPage,
  handleInputChange,
  handleSearch,
} from "./services/storeService";
import { formatCnpjCpf } from "../../../../helpers/Format";
import { RoleContext } from "../../../hooks/HookUseContext";
import { FiEdit2 } from "react-icons/fi";
import EditStore from "./modals/editStore/EditStore";
import CreateStore from "./modals/createStore/CreateStore";

const TableShops = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState();
  const { roleValue } = useContext(RoleContext);
  const [dataFilter, setDataFilter] = useState({});
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const [filters, setFilters] = useState({
    name: "",
    cnpj: "",
    origin: "",
    companyId: "",
    active: "",
  });

  useEffect(() => {
    getCompaniesListToStore(setCompanies, setLoading);
  }, []);

  useEffect(() => {
    getStores(
      currentPage,
      dataFilter?.filters?.name || null,
      dataFilter?.filters?.cnpj
        ? dataFilter?.filters?.cnpj?.replace(/\D/g, "")
        : null,
      dataFilter?.filters?.origin || null,
      dataFilter?.filters?.companyId || null,
      dataFilter?.filters?.active || null,
      setData,
      setLoading
    );
  }, [currentPage, dataFilter]);

  const openModalEdit = (data) => {
    setCurrentData(data);
    setModalOpenEdit(true);
  };

  return (
    <div className={styles.divContainer}>
      {modalOpenEdit === true && (
        <EditStore
          setOpenModal={setModalOpenEdit}
          data={currentData}
          allData={data}
          setData={setData}
        />
      )}
      {modalOpenCreate === true && (
        <CreateStore
          getStores={getStores}
          setData={setData}
          setOpenModal={setModalOpenCreate}
        />
      )}

      <h2 className={styles.titleFilter}>Filtros</h2>
      <div className={styles.actions}>
        <div className={styles.filter}>
          <label>
            Nome Loja
            <input
              name="name"
              type="text"
              placeholder="Nome da companhia"
              value={filters.name}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            />
          </label>
          <label>
            CNPJ
            <input
              name="cnpj"
              type="text"
              placeholder="Cnpj"
              value={formatCnpjCpf(filters.cnpj)}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            />
          </label>
          <label>
            Origem
            <input
              name="origin"
              type="text"
              placeholder="Origem"
              value={filters.origin}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            />
          </label>
          <label>
            Status
            <select
              name="active"
              value={filters.active}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            >
              <option value="">Todos</option>
              <option value={true}>Ativos</option>
              <option value={false}>Inativos</option>
            </select>
          </label>
          <label>
            Companhia
            <select
              name="companyId"
              value={filters.companyId}
              onChange={(e) => handleInputChange(e, setFilters, filters)}
            >
              <option value="">Todos</option>
              {companies &&
                companies?.length > 0 &&
                companies?.map((company) => (
                  <option key={company?.id} value={company?.id}>
                    {company?.description}
                  </option>
                ))}
            </select>
          </label>
          <button
            onClick={() =>
              handleSearch(setDataFilter, filters, setCurrentPage, navigate)
            }
          >
            Buscar
          </button>
        </div>
        <div className={styles.filter}>
          {roleValue?.includes("STORE_CREATE") && (
            <button onClick={() => setModalOpenCreate(true)}>Criar Loja</button>
          )}
        </div>
      </div>
      {loading && <Loading />}
      {!loading && (
        <table className={styles.tableShopsContainer}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Cnpj</th>
              <th>Ações</th>
              {roleValue?.includes("STORE_DELETE") && <th>Desativar/Ativar</th>}
            </tr>
          </thead>
          <tbody>
            {data && data?.content?.length === 0 && (
              <tr className={styles.noData}>
                <td>Não há dados</td>
              </tr>
            )}
            {data &&
              data?.content?.length > 0 &&
              data?.content?.map((currentData, index) => (
                <tr key={currentData?.id}>
                  <td>
                    <strong>Id</strong>
                    {currentData?.id}
                  </td>
                  <td>
                    <strong>Nome</strong>
                    {currentData?.name}
                  </td>
                  <td>
                    <strong>Descrição</strong>
                    {currentData?.description}
                  </td>
                  <td>
                    <strong>Descrição</strong>
                    {formatCnpjCpf(currentData?.cnpj)}
                  </td>
                  <td>
                    <strong>Ações</strong>
                    {roleValue?.includes("STORE_UPDATE") && (
                      <button
                        className={styles.buttonEdit}
                        onClick={() => openModalEdit(currentData)}
                      >
                        <FiEdit2 />
                        Editar
                      </button>
                    )}
                  </td>
                  {roleValue?.includes("STORE_DELETE") && (
                    <td>
                      <strong>Desativar/Ativar</strong>
                      <ActionActivateOrDesactivate
                        currentData={currentData}
                        data={data}
                        setData={setData}
                      />
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {data && data?.content?.length > 0 && (
        <Pagination
          className={styles.pagination_bar}
          currentPage={currentPage === 0 ? 1 : currentPage + 1}
          totalCount={data?.totalElements ? data?.totalElements : []}
          pageSize={pageSize ? pageSize : 1}
          onPageChange={(page) =>
            handleCurrentPage(page - 1, setCurrentPage, navigate)
          }
        />
      )}
    </div>
  );
};

export default TableShops;
