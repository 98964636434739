import GaugeChart from 'react-gauge-chart'

const ClearSaleChart = ({ score }) => {

    function normalizeValue(x) {
        // Valor mínimo e máximo originais
        const minValue = 0;
        const maxValue = 100;
    
        // Fórmula de normalização
        const normalizedValue = (x - minValue) / (maxValue - minValue);
    
        return normalizedValue;
    }    
    
    return (
        <>
            <GaugeChart
                id="gauge-chart5"
                animate={false}
                percent={normalizeValue(score)}
                hideText={true}
                needleColor="#345243"
                colors={["#9cc5a3", " #f4da01" , "#e42a13"]}

            />
        </>
    )
}

export default ClearSaleChart