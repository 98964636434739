// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangeStatusSelect_container__e0Tjd {\r\n    width: 200px;\r\n}\r\n\r\n.ChangeStatusSelect_container__e0Tjd select {\r\n    padding: 1em;\r\n    border: 1px solid #DFE6EE;\r\n    padding-right: 50px!important;\r\n}\r\n\r\n.ChangeStatusSelect_container__e0Tjd select:focus{\r\n    box-shadow: 0 0 0 0;\r\n    border: 0 none;\r\n    outline: 0;\r\n}", "",{"version":3,"sources":["webpack://./src/components/pages/incidents/screens/partnerScreen/changeStatusSelect/ChangeStatusSelect.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,UAAU;AACd","sourcesContent":[".container {\r\n    width: 200px;\r\n}\r\n\r\n.container select {\r\n    padding: 1em;\r\n    border: 1px solid #DFE6EE;\r\n    padding-right: 50px!important;\r\n}\r\n\r\n.container select:focus{\r\n    box-shadow: 0 0 0 0;\r\n    border: 0 none;\r\n    outline: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ChangeStatusSelect_container__e0Tjd"
};
export default ___CSS_LOADER_EXPORT___;
