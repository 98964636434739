import { checkJwt } from "../../../../../../helpers/Jwt-helper";
import api from "../../../../../../services/api";
import { toast } from "react-toastify";

export const handleSearch = (
  setDataFilter,
  filters,
  setCurrentPage,
  navigate
) => {
  checkJwt(navigate).then((response) => {
    setDataFilter({ filters });
    setCurrentPage(0);
  });
};

export const handleInputChange = (e, setFilters, filters) => {
  const { name, value } = e.target;
  setFilters({
    ...filters,
    [name]: value,
  });
};

export const handleCurrentPage = (page, setCurrentPage, navigate) => {
  checkJwt(navigate).then((response) => {
    if (response) {
      setCurrentPage(page);
    }
  });
};
export const getCompanies = async (
  page,
  code,
  description,
  active,
  setData,
  setLoading
) => {
  try {
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${
          process.env.REACT_APP_PARTNER_NOTIFIER_COMPANIES
        }?page=${page}&size=${10}`,
        {
          params: {
            code,
            description,
            active,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const getCompaniesList = async (setData, setLoading) => {
  try {
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_COMPANIES_LIST}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const getCompaniesActionList = async (setData, setLoading) => {
  try {
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_COMPANY_ACTIONS}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const getuserAuthsList = async (setData, setLoading) => {
  try {
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const activeOrDesactive = async (
  setData,
  currentData,
  data,
  active,
  setSelected
) => {
  try {
    await api
      .put(
        `${
          process.env.REACT_APP_MONITORING_BASE_URL
        }${process.env.REACT_APP_PARTNER_NOTIFIER_COMPANY_ACTIVATE_OR_DESACTIVATE.replace(
          "{id}",
          currentData?.id
        ).replace("{active}", active)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        console.log(data);
        const updatedList = getListUpdated(response, data);
        setSelected(active);
        setData(updatedList);
      })
      .catch((error) => {
        console.log(error);
        setSelected(!active);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
    setSelected(!active);
  }
};
export const createCompany = async (
  formData,
  closedModal,
  getCompanies,
  setData,
  setLoading
) => {
  try {
    await api
      .post(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_COMPANIES_CREATE}`,
        {
          code: formData?.code,
          description: formData?.description,
          userAuthId: formData?.userAuthId,
          companyActionId: formData?.companyActionId,
          userId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        getCompanies(0, null, null, null, setData, setLoading);
        closedModal();
        toast.success("Criado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateCompany = async (setData, data, formData, currentData) => {
  try {
    await api
      .patch(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_COMPANIES_UPDATE}`,
        {
          id: currentData?.id,
          code: formData?.code,
          description: formData?.description,
          userAuthId: formData?.userAuthId,
          companyActionId: formData?.companyActionId,
          userId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        const updatedList = getListUpdated(response, data);
        setData(updatedList);
        toast.success("Atualizado");
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const fieldsValidation = (formData, setErrors) => {
  const newErrors = {};
  if (!formData?.code) newErrors.code = true;
  if (!formData?.description) newErrors.description = true;
  if (!formData?.userAuthId) newErrors.userAuthId = true;
  if (!formData?.companyActionId) newErrors.companyActionId = true;

  setErrors(newErrors);

  return newErrors;
};

function getListUpdated(response, data) {
  const updatedObject = response?.data;
  console.log(response?.data);
  const updatedList = data?.content?.map((item) => {
    if (item.id === updatedObject.id) {
      return updatedObject;
    }
    return item;
  });
  return {
    ...data,
    content: updatedList,
  };
}

const handleError = (error) => {
  if (error.response && error.response.data) {
    let errorMessage = error?.response?.data?.message;

    if (!errorMessage) {
      errorMessage = error?.response?.data;
      console.log(error?.response?.data);
    }
    toast.error(`
              ${errorMessage}
          `);
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error(error?.response?.data);
    console.error("Erro desconhecido:", error);
  }
};
