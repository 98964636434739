import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../layout/pagination/Pagination';
import ModalMessage from '../../modalMessage/ModalMessage';
import styles from './AutomaticReprocessingScreen.module.css';
import { useContext, useEffect, useState } from 'react';
import { RoleContext } from "../../../../hooks/HookUseContext";
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { checkJwt } from '../../../../../helpers/Jwt-helper';
import InputFilter from '../../../../form/InputFilter/InputFilter';
import Loading from '../../../../layout/loading/Loading';
import { AiOutlineFileSearch } from 'react-icons/ai';
import moment from 'moment';
import renderIcon from '../../../../../helpers/IconHelper';
import ResetButton from '../../resetButton/ResetButton';


const AutomaticReprocessingScreen = () => {

    const navigate = useNavigate();
    const [proposalCode, setProposalCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [dataObject, setDataObject] = useState();
    const [pageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { roleValue } = useContext(RoleContext);

    const openModalEvent = (message) => {
        setMessage(message);
        setModalOpen(true);
    }

    const getAllProposalStages = async (page) => {
        try {
            setLoading(true);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PROPOSAL_STAGES_ERROR}?page=${page}&size=${10}`,
                {
                    params: {
                        "proposalCode": proposalCode,
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
            }).then((response) => {
                setDataObject(response?.data);
                setLoading(false);
            })
                .catch((error) => {

                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const putResetProposalStages = async (data, setLoading) => {
        try {
            setLoading(true);
            await api.put(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PROPOSAL_STAGES_ERROR_RESET_ATTEMPTS}${data?.proposal?.data.proposalCode}/${data?.stages?.code}`
            ).then((response) => {
                const indexToUpdate = dataObject.content.findIndex(obj =>
                    obj?.proposal?.data?.proposalCode === data?.proposal?.data.proposalCode &&
                    obj?.stages?.code === data?.stages?.code
                );

                if (indexToUpdate !== -1) {
                    const updatedContent = [...dataObject.content];
                    updatedContent[indexToUpdate] = response?.data;
                    setDataObject({ ...dataObject, content: updatedContent });
                }
                setLoading(false);
                toast.success("Tentativas resetado com sucesso");
            }).catch((error) => {
                console.log(error)
                setLoading(false);
                toast.error(error?.response?.data?.message || error?.response?.data);
            })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const handleCurrentPage = (page) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(page);
                getAllProposalStages(page);
            }
        })
    }


    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getAllProposalStages(currentPage);
            }
        })
    }, [])

    const submit = (event) => {
        event?.preventDefault();
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(0);
                getAllProposalStages(0);
            }
        })

    }

    const handleReset = (data, setLoading) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                putResetProposalStages(data, setLoading);
            }
        })

    }
    return (
        <>
            {roleValue?.includes("INCIDENTS_AUTOMATIC_REPROCESSING_READ") && (
                <div className={styles.proposalStagesContainer}>
                    {modalOpen === true && <ModalMessage setOpenModal={setModalOpen} message={message} />}
                    <div className={`${styles.card} ${styles.column}`}  >
                        <h2>Filtro</h2>
                        <div className={styles.filter}>
                            <form onSubmit={submit}>
                                <InputFilter
                                    type="text"
                                    name={"proposalNumber"}
                                    text="Num. da proposta"
                                    required={false}
                                    maxLength={10}
                                    value={proposalCode}
                                    handleOnChange={(e) => setProposalCode(e.target.value)} />
                                <button >
                                    <AiOutlineFileSearch />
                                    Buscar
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.column}`}  >
                        {loading && <Loading />}
                        {!loading && (
                            <>
                                <table className={styles.partnerToContainer}>
                                    <thead>
                                        <tr>
                                            <th>Proposta</th>
                                            <th>Stage</th>
                                            <th>Status</th>
                                            <th>Data da Stage</th>
                                            <th>tentativas</th>
                                            <th>Mensagem</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataObject && dataObject?.content && dataObject?.content?.length === 0 && (
                                            <tr className={styles.noData}>
                                                <td>Não há dados</td>
                                            </tr>
                                        )}
                                        {dataObject && dataObject?.content && dataObject?.content?.length > 0 && (
                                            dataObject?.content.map((data, index) => (
                                                <tr key={index} className={styles.division}>
                                                    <td>
                                                        <strong>Proposta </strong>
                                                        {data?.proposal?.data?.proposalCode}
                                                    </td>
                                                    <td>
                                                        <strong>Stage </strong>
                                                        {data?.stages?.description}
                                                    </td>
                                                    <td className={`${styles[data?.status?.code?.toLowerCase()]}`}>
                                                        <strong>Status </strong>
                                                        <strong style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: ".1em"
                                                        }}>{renderIcon(data?.status?.code?.toLowerCase())} {data?.status?.name}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>Data da Stage </strong>
                                                        {data?.stageDate && moment(data?.stageDate).format('DD/MM/YYYY HH:mm')}
                                                    </td>
                                                    <td>
                                                        <strong>Tentativas </strong>
                                                        {data?.attempts}
                                                    </td>
                                                    <td onClick={() => openModalEvent(data?.message?.descriptionDetails)}>
                                                        <div style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            maxWidth: "100px",
                                                            cursor: "pointer"
                                                        }}>
                                                            <strong>Mensagem </strong>
                                                            {data?.status?.code === "ERROR" && data?.message?.descriptionDetails}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {roleValue?.includes("INCIDENTS_AUTOMATIC_REPROCESSING_RESET_ATTEMPTS_UPDATE") && (
                                                            <>
                                                                <ResetButton
                                                                    data={data}
                                                                    handleReset={handleReset}
                                                                />

                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                <Pagination
                                    className={styles.pagination_bar}
                                    currentPage={currentPage === 0 ? 1 : currentPage + 1}
                                    totalCount={dataObject?.totalElements ? dataObject?.totalElements : []}
                                    pageSize={pageSize ? pageSize : 1}
                                    onPageChange={page => handleCurrentPage(page - 1)}
                                />
                            </>
                        )}

                    </div>
                </div>
            )}

        </>
    )
}

export default AutomaticReprocessingScreen