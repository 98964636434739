// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__kY9D3{\r\n    width: 100%;\r\n    max-width: 1200px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.Container_minHeight__K3q7B{\r\n    min-height: 75%;\r\n}\r\n\r\n.Container_start__SL\\+zP{\r\n    justify-content: flex-start;\r\n}\r\n\r\n.Container_end__vGLGR{\r\n    justify-content: end;\r\n}\r\n.Container_column__SwQXA{\r\n    flex-direction: column;\r\n    justify-content:  flex-start;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/layout/container/Container.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,sBAAsB;IACtB,4BAA4B;AAChC","sourcesContent":[".container{\r\n    width: 100%;\r\n    max-width: 1200px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.minHeight{\r\n    min-height: 75%;\r\n}\r\n\r\n.start{\r\n    justify-content: flex-start;\r\n}\r\n\r\n.end{\r\n    justify-content: end;\r\n}\r\n.column{\r\n    flex-direction: column;\r\n    justify-content:  flex-start;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__kY9D3",
	"minHeight": "Container_minHeight__K3q7B",
	"start": "Container_start__SL+zP",
	"end": "Container_end__vGLGR",
	"column": "Container_column__SwQXA"
};
export default ___CSS_LOADER_EXPORT___;
