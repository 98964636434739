import styles from './EditUnavailabilityModal.module.css';

import { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Input from '../../form/input/Input';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { useNavigate } from 'react-router-dom'
import { checkJwt } from '../../../helpers/Jwt-helper';
import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const EditUnavailabilityModal = ({ setOpenModal, data, updateData }) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [unavailability, setUnavailability] = useState(data);


    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }

    function handleChange(event) {
        setUnavailability({ ...unavailability, [event.target.name]: event.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(unavailability?.startDate >= unavailability?.endDate){
            toast.error(`a data "Periodo (de)" tem que ser menor que a data "Periodo (até)"`);
            return;
        }

        checkJwt(navigate).then((response) => {
            if (response) {
                const data = {
                    id: unavailability?.id,
                    startDate: unavailability?.startDate,
                    endDate: unavailability?.endDate,
                    description: unavailability?.description,
                }
                api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SETTINGS_UNAVAILABILITY}`, data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    toast.success(`${response?.data?.message}`);
                    updateData();
                    closedModal();
                }).catch((error) => {
                    console.log(error);
                    toast.error(`Deu erro! ${error?.response?.data?.message}`);
                })
                console.log(data);
            }
        })
    }

    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalEditContainer}>
                <h2>Editar</h2>
                <form onSubmit={handleSubmit}>
                    <Input type="datetime-local" name={"startDate"} text="Periodo (de)" handleOnChange={handleChange} value={unavailability?.startDate || ''} />
                    <Input type="datetime-local" name={"endDate"} text="Periodo (até)" handleOnChange={handleChange} value={unavailability?.endDate || ''} />
                    <Input type="text" name={"description"} text="Descrição" handleOnChange={handleChange} maxLength={50} value={unavailability?.description || ''} />
                    <SubmitButton text="Salvar" position="right" />
                </form>
            </div>
        </Modal>
    )
}

export default EditUnavailabilityModal