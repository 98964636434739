import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './ViewPdf.module.css';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import { FaFileDownload } from 'react-icons/fa';
// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';
import { useLocation } from 'react-router-dom';

export default function ViewPdf() {
    const { roleValue } = useContext(RoleContext);
    const canvasRef = useRef();

    const [pdfRef, setPdfRef] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { id, type, dataId } = useParams();
    const [url, setUrl] = useState(null);
    const [typeDocument, setTypeDocument] = useState(null);
    const [base64, setBase64] = useState(null);
    const [name, setName] = useState(null);

    const pdfjsLib = window['pdfjs-dist/build/pdf'];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js';

    const downloadPDF = () => {
        const linkSource = `data:application/pdf;base64,${base64}`;
        const downloadLink = document.createElement("a");
        const fileName = `${name}`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const renderPage = useCallback((pageNum, pdf = pdfRef) => {
        pdf && pdf.getPage(pageNum).then(function (page) {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = canvasRef.current;
            canvas.height = viewport?.height;
            canvas.width = viewport?.width;
            const renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
            };
            page.render(renderContext);
        });
    }, [pdfRef]);


    const getDocument = useCallback(async () => {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT_BY_ID}/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setUrl(`data:application/pdf;base64,${response?.data?.content}`);
                setTypeDocument(response?.data?.documentType?.code);
                setName(response?.data?.name);
                setBase64(response?.data?.content);
            })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
            console.log(error.response);
        }
    }, [id])


    const downloadBase64 = async (id, name, path) => {
        try {
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${path}${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setUrl(`data:application/pdf;base64,${response?.data}`);
                setTypeDocument(null);
                setName(name);
                setBase64(response?.data);
            })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        if (type === "QDO") {
            downloadBase64(id, localStorage.getItem("n"), process.env.REACT_APP_SCORE_DOCUMENT)
        } else if (type === "CLS") {
            downloadBase64(id, localStorage.getItem("n"), process.env.REACT_APP_SCORE_DATA_CADASTRAL_DOCUMENT)
        }
        else if (type === "PLA") {
            downloadBase64(id, localStorage.getItem("n"), process.env.REACT_APP_INSPECTION_PLATE_DOCUMENT + dataId + "/")
        } else if (type === "INP") {
            downloadBase64(id, localStorage.getItem("n"), process.env.REACT_APP_INSPECTION_INFORMATION_DOCUMENT + dataId + "/")
        }
        else if (type === "SIG" || type === "INS" || type === "INC") {
            getDocument();
        }
        renderPage(currentPage, pdfRef);
    }, [pdfRef, currentPage, renderPage, getDocument]);

    useEffect(() => {
        if (url) {

            const loadingTask = pdfjsLib.getDocument(url);
            loadingTask.promise.then(loadedPdf => {
                setPdfRef(loadedPdf);
            }, function (reason) {
                console.error(reason);
            });
        }
    }, [url, pdfjsLib]);

    const nextPage = () => pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

    const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    const renderPdf = () => {
        return (
            <>
                <div className={styles.buttons}>
                    <button onClick={prevPage}>Página anterior</button>
                    <button onClick={nextPage}>Próxima página</button>
                    {currentPage && pdfRef?.numPages && <>
                        {currentPage} - {pdfRef?.numPages}
                    </>}
                    {roleValue?.includes("DOCUMENT_PDF_COPY") && base64 && name && <button className={styles.download} onClick={downloadPDF}><FaFileDownload />Baixar</button>}
                </div>
                <div className={styles.containerCanvas}>
                    <canvas ref={canvasRef}></canvas>
                </div>
                <div className={styles.buttons}>
                    <button onClick={prevPage}>Página anterior</button>
                    <button onClick={nextPage}>Próxima página</button>
                    {currentPage && pdfRef?.numPages && <>
                        {currentPage} - {pdfRef?.numPages}
                    </>}
                </div>
            </>
        )
    }
    return (

        <div className={styles.container}>
            {url ? (
                <>
                    {(roleValue?.includes("DOCUMENT_READ") || roleValue?.includes("DOCUMENT_CLIENT_READ")) ? (
                        <>
                            {renderPdf()}
                        </>
                    ) : (roleValue?.includes("DOCUMENT_SPECIFIC_READ") &&
                        (typeDocument === "BSP" ||
                            typeDocument === "TSP")) ? (
                        <>
                            {renderPdf()}
                        </>
                    ) : 'Sem permisão para visualizar o documento!'}
                </>
            ) : "Carregando..."}

        </div>
    )
}


