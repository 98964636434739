import styles from './deleteGenericModal.module.css';
import { AiOutlineWarning } from 'react-icons/ai'
import { Modal } from  'react-responsive-modal'
import { useState } from 'react';

const DeleteGenericModal = ({setOpenModal, textTittle, textMessage, deleteEvent, data}) => {

    const [open, setOpen] = useState(true);

    const cancel = () => {
        setOpenModal(false);
    }
    function closedModal(){
        setOpen(false);
        setOpenModal(false);
    }
  return (
    <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
        {
            modal: `${styles.modalContainer}`,
        }
    }>  
    <div className={styles.modalDeleteContainer}>
        <div className={styles.top}>
            <AiOutlineWarning/>
            <div className={styles.messages}>
                <h2>{textTittle}</h2>
                <p>{textMessage}</p>
            </div>
        </div>
        <div className={styles.actionsButtons}>
            <button onClick={cancel}>Cancelar</button>
            <button onClick={() => deleteEvent(data)}>Deletar</button>
        </div>
    </div>
</Modal>
  )
}

export default DeleteGenericModal;