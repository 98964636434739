import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './ModalDetailsDocumentDataCadastral.module.css';
import moment from 'moment/moment';
import Tab from '../../tab/Tab';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';
import api from '../../../services/api';
import Loading from '../../layout/loading/Loading';
import { RoleContext } from '../../hooks/HookUseContext';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

const ModalDetailsDocumentDataCadastral = ({ open, onCloseModal, proposalId, currentCpfCnpj, setDocuments, currentName, type }) => {
    const [loading, setLoading] = useState(false);
    const [loadingAttach, setLoadingAttach] = useState(false);
    const { roleValue } = useContext(RoleContext);
    const [insights, setInsights] = useState([])
    const [ratings, setRatings] = useState([])
    const [dataDocumentDataCadastral, setDataDocumentDataCadastral] = useState();
    const [docs, setDocs] = useState();

    const getDetails = async () => {
        try {
            setLoading(true)
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SCORE_DATA_CADASTRAL_DETAILS_DETAIL}${proposalId}/${currentCpfCnpj}/${type}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setLoading(false);
                setInsights(response?.data?.insights);
                setRatings(response?.data?.ratings)
                setDataDocumentDataCadastral(response?.data);
                setDocs(response?.data?.documents)
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    const postAttachDetail = async () => {
        try {
            setLoadingAttach(true)
            await api.post(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SCORE_DATA_CADASTRAL_ATTACH_DETAIL}${dataDocumentDataCadastral?.transactionId}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setDocs(response?.data);
                toast.success("Documento anexo com sucesso!")
                setLoadingAttach(false);
            })
                .catch((error) => {
                    setLoadingAttach(false);
                    toast.error(error?.response?.data?.message || error?.response?.data)
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getDetails();
    }, [])
    useEffect(() => {
        return () => {
            if (docs && docs.length > 0) {
                setDocuments(docs);
            }
        }
    }, [docs])

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }

    const insightsF = () => {
        return (
            <div className={styles.content}>
                {insights && insights.length > 0 ? insights.map((insight, index) => (
                    <div key={index} className={styles.columnInformation}>
                        <div className={styles.rowsInformation}>
                            <div>
                                <p>Code</p>
                                <strong>{insight?.code}</strong>
                            </div>
                            <div>
                                <p>Descrição</p>
                                <strong>{insight?.description}</strong>
                            </div>
                            <div>
                                <p>Tipo</p>
                                <strong>{insight?.type}</strong>
                            </div>
                            <div>
                                <p>Categoria</p>
                                <strong>{insight?.category}</strong>
                            </div>
                            <div>
                                <p>Relevância</p>
                                <strong>{insight?.relevance}</strong>
                            </div>
                            <div>
                                <p>relacionado a</p>
                                {insight?.relatedToLegend && insight?.relatedToLegend?.map((related, index) => (
                                    <div key={index}>
                                        <strong>{related}</strong>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )) : "Nenhum Insight"}
            </div>
        )
    }
    const ratingsF = () => {
        return (
            <div className={styles.content}>
                {ratings && ratings.length > 0 ? ratings.map((rating, index) => (
                    <div key={index} className={styles.columnInformation}>
                        <div className={styles.rowsInformation}>
                            <div>
                                <p>Valor</p>
                                <strong>{rating?.value}</strong>
                            </div>
                            <div>
                                <p>Razão</p>
                                <strong>{rating?.legend}</strong>
                            </div>
                            <div>
                                <p>Criado em</p>
                                <strong>{rating?.createdAt && moment(rating?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</strong>
                            </div>
                            <div>
                                <p>relacionado a</p>
                                {rating?.relatedToLegend && rating?.relatedToLegend?.map((related, index) => (
                                    <div key={index}>
                                        <strong>{related}</strong>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )) : "Nenhum Raiting"}
            </ div>
        )
    }

    return (
        <Modal open={open} onClose={onCloseModal} center closeIcon={false}>
            <div className={styles.modalInformationQuodOrClearSaleContainer}>
                {loading && <Loading />}

                {!loading && (
                    <>
                        <div className={styles.subContainer}>
                            <div className={styles.informations}>
                                <div>
                                    <p>Nome</p>
                                    <strong>{currentName}</strong>
                                </div>
                                <div>
                                    <p>Cpf/Cnpj</p>
                                    <strong>{formatCnpjCpf(currentCpfCnpj)}</strong>
                                </div>

                            </div>
                            {roleValue?.includes("SCORE_DATA_ATTACH_TO_FUNCAO_CREATE") &&
                                <>
                                    {type === "Ratings" &&
                                        <>

                                            {!loadingAttach && <button onClick={postAttachDetail}>Anexar ao Função</button>}
                                            {loadingAttach && <button disabled>Aguarde...</button>}
                                        </>
                                    }
                                </>
                            }


                        </div>

                        {type === "Insights" && (
                            <>
                                {insightsF()}
                            </>
                        )}

                        {type === "Ratings" && (
                            <>
                                {ratingsF()}
                            </>
                        )}
                    </>
                )}
            </div>
        </Modal >
    )
}

export default ModalDetailsDocumentDataCadastral