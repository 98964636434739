import { useContext, useEffect, useState } from "react";
import api from "../../../services/api";
import Loading from "../../layout/loading/Loading";
import moment from "moment";
import { checkJwt } from "../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";
import ModalRegularizeDigitalSignature from "./modalRegularizeDigitalSignature/ModalRegularizeDigitalSignature";
import { RoleContext } from "../../hooks/HookUseContext";

const DigitalSignature = ({ item, formatCnpjCpf, openModalEvent }) => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [cpfCnpj, setCpfCnjp] = useState();
    const [unicoId, setUnicoId] = useState();
    const { roleValue } = useContext(RoleContext);
    const openModalRegularizeEvent = (cpfCnpj, unicoId) => {
        setUnicoId(unicoId);
        setCpfCnjp(cpfCnpj);
        setModalOpen(true);
    }


    const getIncidents = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT}`,
            {
                params: {
                    code: item?.stages?.code,
                    proposalId: item?.id?.proposalId
                }
            }, {
        }).then((response) => {
            setData(response?.data.signatureRegistrationResponse)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null); setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getIncidents();
            }
        })
    }, [])
    return (
        <> {
            modalOpen === true && <ModalRegularizeDigitalSignature
                setOpenModal={setModalOpen}
                dataProposalStages={item}
                cpfCnpj={cpfCnpj}
                unicoId={unicoId}
            />}
            {loading && <Loading />}
            {!loading && (<>
                <h2>{item?.stages?.description}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Função status
                        </div>
                        <div className="cell cell-tittle">
                            Descrição
                        </div>
                        <div className="cell cell-tittle">
                            Mensagem
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                        <div className="cell cell-tittle">
                            Atualizado em
                        </div>
                    </div>

                    <div className="row">
                        <div className="cell  cell-text">
                            <strong>Status </strong>
                            {data?.signatureSolicitation?.code}
                        </div>
                        <div className="cell cell-text">
                            <strong>Função status </strong>
                            {data?.signatureSolicitation?.funcaoStatus}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.signatureSolicitation?.message?.description)}>
                            <strong>Descrição </strong>
                            {data?.signatureSolicitation?.message?.description}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.signatureSolicitation?.message?.descriptionDetails)}>
                            <strong>Mensagem </strong>
                            {data?.signatureSolicitation?.message?.descriptionDetails}
                        </div>
                        <div className="cell cell-text">
                            <strong>Criado em </strong>
                            {data?.signatureSolicitation?.createdAt && moment(data?.signatureSolicitation?.createdAt).format('DD/MM/YYYY HH:mm')}

                        </div>
                        <div className="cell cell-text">
                            <strong>Atualizado em </strong>
                            {data?.signatureSolicitation?.updatedAt && moment(data?.signatureSolicitation?.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>
                {data && data?.holderList && data?.holderList?.length > 0 && (
                    <>
                        <h2 style={{ fontSize: "20px" }}>Titulares </h2>
                        <div className="table">
                            <div className="row header">
                                <div className="cell cell-tittle">
                                    Cpf
                                </div>
                                <div className="cell cell-tittle">
                                    Nome
                                </div>
                                <div className="cell cell-tittle">
                                    Status
                                </div>
                                <div className="cell cell-tittle">
                                    Descrição
                                </div>
                                <div className="cell cell-tittle">
                                    Score
                                </div>
                                <div className="cell cell-tittle">
                                    Tipo Cliente
                                </div>
                                <div className="cell cell-tittle">
                                    Criado em
                                </div>
                                <div className="cell cell-tittle">
                                    Atualizado em
                                </div>
                                <div className="cell cell-tittle">
                                </div>
                            </div>

                            {data && data?.holderList && data?.holderList?.length > 0 && data?.holderList?.map((holder, index) => (
                                <div className="row" key={index}>
                                    <div className="cell  cell-text">
                                        <strong>Cpf </strong>
                                        {holder?.cpf && formatCnpjCpf(holder?.cpf)}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Nome </strong>
                                        {holder?.name}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Status</strong>
                                        {holder?.status}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Descrição</strong>
                                        {holder?.description}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Score</strong>
                                        {holder?.score}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Tipo Cliente</strong>
                                        {holder?.involved === "HOLDER_PROPONENT" && "Proponente"}
                                        {holder?.involved === "HOLDER_CLIENT" && "Cliente"}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Criado em </strong>
                                        {holder?.createdAt && moment(holder?.createdAt).format('DD/MM/YYYY HH:mm')}

                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Atualizado em </strong>
                                        {holder?.updatedAt && moment(holder?.updatedAt).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                    <div className="cell cell-text" >
                                        {roleValue?.includes("INCIDENTS_REGULARIZE_CREATE") && holder.notification ? (
                                            <button onClick={() => openModalRegularizeEvent(holder?.cpf, holder?.unicoId)}>Regularizar</button>
                                        ) : <button disabled>Regularizar</button>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}


                {data && data?.subscriberList && data?.subscriberList?.length > 0 && (
                    <>
                        <h2 style={{ fontSize: "20px" }}>Assinantes </h2>
                        <div className="table">
                            <div className="row header">
                                <div className="cell cell-tittle">
                                    Cpf
                                </div>
                                <div className="cell cell-tittle">
                                    Nome
                                </div>
                                <div className="cell cell-tittle">
                                    Descrição
                                </div>
                                <div className="cell cell-tittle">
                                    Score
                                </div>
                                <div className="cell cell-tittle">
                                    Criado em
                                </div>
                                <div className="cell cell-tittle">
                                    Atualizado em
                                </div>
                                <div className="cell cell-tittle">
                                </div>
                            </div>

                            {data && data?.subscriberList && data?.subscriberList?.length > 0 && data?.subscriberList?.map((subscriber, index) => (
                                <div className="row" key={index}>
                                    <div className="cell  cell-text">
                                        <strong>Cpf </strong>
                                        {subscriber?.cpf && formatCnpjCpf(subscriber?.cpf)}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Nome </strong>
                                        {subscriber?.name}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Descrição</strong>
                                        {subscriber?.description}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Score</strong>
                                        {subscriber?.score}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Criado em </strong>
                                        {subscriber?.createdAt && moment(subscriber?.createdAt).format('DD/MM/YYYY HH:mm')}

                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Atualizado em </strong>
                                        {subscriber?.updatedAt && moment(subscriber?.updatedAt).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                    <div className="cell cell-text" >
                                        {roleValue?.includes("INCIDENTS_REGULARIZE_CREATE") && subscriber.notification ? (
                                            <button onClick={() => openModalRegularizeEvent(subscriber?.cpf, subscriber?.unicoId)}>Regularizar</button>
                                        ) : <button disabled>Regularizar</button>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

            </>)}
        </>
    )
}

export default DigitalSignature