import React, { useEffect, useState } from 'react'
import Loading from '../../layout/loading/Loading'
import styles from './AddressValidation.module.css';
import Tab from '../../tab/Tab';
import api from '../../../services/api';

const AddressValidation = ({ data, proposalData }) => {
    const [loading, setLoading] = useState(false);
    const [addressValidationData, setSddressValidationData] = useState([1])

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }
    const documentScoreDataCadastralInformation = () => {
        return (
            <div className={`contentModal `}>
                <div className={styles.involveds}>
                    <h3>Titular(es)</h3>
                    {addressValidationData && addressValidationData?.holders && addressValidationData?.holders?.length > 0 ? addressValidationData?.holders.map((involved) => (
                        <div className={styles.involved} key={involved?.cpf}>
                            <div className={styles.grid}>
                                <div className={styles.information}>
                                    <div>
                                        <p>Nome </p>
                                        <strong>{involved?.name}</strong>
                                    </div>
                                    <div >
                                        <p>Cpf </p>
                                        <strong>{involved?.cpf && formatCnpjCpf(involved?.cpf)}</strong>
                                    </div>
                                </div>
                                <div className={styles.typeDocContainer}>
                                    <p>Tipo de documento </p>
                                    <strong>{involved?.typeDocDescription}</strong>
                                    <div className={styles.boxCheck}>
                                        <div className={styles.check}>
                                            <p>Deve anexar <input type="checkbox" disabled checked={involved?.mustAttach} /></p>
                                        </div>
                                        <div className={styles.check}>
                                            <p>Anexou <input type="checkbox" disabled checked={involved?.attached} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={styles.message}>
                                <strong>Atenção: </strong>
                                {involved?.messageIsAttached}
                            </p>
                        </div>
                    )) : 'Nenhum Titular'}

                    <h3>Assinante(s)</h3>
                    {addressValidationData && addressValidationData?.subscriber && addressValidationData?.subscriber?.length > 0 ? addressValidationData?.subscriber.map((involved) => (
                        <div className={styles.involved} key={involved?.cpf}>
                            <div className={styles.grid}>
                                <div className={styles.information}>
                                    <div>
                                        <p>Nome </p>
                                        <strong>{involved?.name}</strong>
                                    </div>
                                    <div >
                                        <p>Cpf </p>
                                        <strong>{involved?.cpf && formatCnpjCpf(involved?.cpf)}</strong>
                                    </div>
                                </div>
                                <div className={styles.typeDocContainer}>
                                    <p>Tipo de documento </p>
                                    <strong>{involved?.typeDocDescription}</strong>
                                    <div className={styles.boxCheck}>
                                        <div className={styles.check}>
                                            <p>Deve anexar <input type="checkbox" disabled checked={involved?.mustAttach} /></p>
                                        </div>
                                        <div className={styles.check}>
                                            <p>Anexou <input type="checkbox" disabled checked={involved?.attached} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={styles.message}>
                                <strong>Atenção: </strong>
                                {involved?.messageIsAttached}
                            </p>
                        </div>
                    )) : 'Nenhum Assinante'}
                </div>

            </div>
        )
    }

    const getAllInvolveds = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_ADDRESS_VALIDATION_STAGE}${data?.id}`, {
        }).then((response) => {
            setSddressValidationData(response?.data)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        getAllInvolveds();
    }, [])

    return (
        <div className={styles.addressValidationContainer}>
            {loading && <Loading />}
            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Informações',
                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{documentScoreDataCadastralInformation()}</>,
                    ]}
                />
            </>)}
        </div>
    )
}

export default AddressValidation