import React, { useEffect, useState } from 'react'
import api from '../../../services/api';
import Loading from '../../layout/loading/Loading';
import moment from 'moment';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';

const DocumentScore = ({ item, formatCnpjCpf, openModalEvent }) => {
    const navigate = useNavigate();
    const [envolved] = useState("Envolvidos");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);


    const getIncidents = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT}`,
            {
                params: {
                    code: item?.stages?.code,
                    proposalId: item?.id?.proposalId
                }
            }, {
        }).then((response) => {
            setData(response?.data.quodRegistrationResponseDto)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null); setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getIncidents();
            }
        })
    }, [])

    return (
        <>
            {loading && <Loading />}

            {!loading && (<>

                <h2>{item?.stages?.description}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Função status
                        </div>
                        <div className="cell cell-tittle">
                            Descrição
                        </div>
                        <div className="cell cell-tittle">
                            Mensagem
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                        <div className="cell cell-tittle">
                            Atualizado em
                        </div>
                    </div>

                    <div className="row">
                        <div className="cell  cell-text">
                            <strong>Status </strong>
                            {data?.scoreNotificationDto?.status}
                        </div>
                        <div className="cell cell-text">
                            <strong>Função status </strong>
                            {data?.scoreNotificationDto?.funcaoStatus}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.scoreNotificationDto?.message?.description)}>
                            <strong>Descrição </strong>
                            {data?.scoreNotificationDto?.message?.description}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.scoreNotificationDto?.message?.descriptionDetails)}>
                            <strong>Mensagem </strong>
                            {data?.scoreNotificationDto?.message?.descriptionDetails}
                        </div>
                        <div className="cell cell-text">
                            <strong>Criado em </strong>
                            {data?.scoreNotificationDto?.createdAt && moment(data?.scoreNotificationDto?.createdAt).format('DD/MM/YYYY HH:mm')}

                        </div>
                        <div className="cell cell-text">
                            <strong>Atualizado em </strong>
                            {data?.scoreNotificationDto?.updatedAt && moment(data?.scoreNotificationDto?.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>

                {data && data.quodDtos && (
                    <>
                        <h2 style={{ fontSize: "20px" }}>{envolved}</h2>
                        <div className="table">
                            <div className="row header">
                                <div className="cell cell-tittle">
                                    Documento
                                </div>
                                <div className="cell cell-tittle">
                                    Recomendação
                                </div>
                                <div className="cell cell-tittle">
                                    Status
                                </div>
                                <div className="cell cell-tittle">
                                    Status processo
                                </div>
                                <div className="cell cell-tittle">
                                    Descrição processo
                                </div>
                                <div className="cell cell-tittle">
                                    Criado em
                                </div>
                                <div className="cell cell-tittle">
                                    Atualizado em
                                </div>
                            </div>
                            {data && data.quodDtos && data.quodDtos.map((quod) => (
                                <div className="row" key={quod.id}>
                                    <div className="cell cell-text" style={{ whiteSpace: 'nowrap' }}>
                                        <strong>Documento </strong>
                                        {quod?.document && formatCnpjCpf(quod?.document)}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Recomendação </strong>
                                        {quod?.recommendation}
                                    </div>
                                    <div className="cell cell-text" style={{ whiteSpace: 'nowrap' }}>
                                        <strong>Status </strong>
                                        {quod?.status}
                                    </div>
                                    <div className="cell cell-text" style={{ whiteSpace: 'nowrap' }}>
                                        <strong> Status processo </strong>
                                        {quod?.processStatus}
                                    </div>
                                    <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(quod?.processDescription)}>
                                        <strong> Descrição processo </strong>
                                        {quod?.processDescription}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong> Criado em </strong>
                                        {quod?.createdAt && moment(quod?.createdAt).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                    <div className="cell cell-text" style={{ whiteSpace: 'nowrap' }}>
                                        <strong> Atualizado em </strong>
                                        {quod?.updatedAt && moment(quod?.updatedAt).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </>
                )}

            </>)}
        </>
    )
}

export default DocumentScore