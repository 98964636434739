import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import Tab from '../../tab/Tab';
import styles from './AllDocumentsModal.module.css';
import { AiFillFileImage, AiFillFilePdf, AiOutlineReload } from 'react-icons/ai';
import { RoleContext } from '../../hooks/HookUseContext';
import api from '../../../services/api';
import Loading from '../../layout/loading/Loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const AllDocumentsModal = ({ setOpenModal, data }) => {

    const [open, setOpen] = useState(true);
    const { roleValue } = useContext(RoleContext);
    const [documents, setDocuments] = useState();
    const [loading, setLoading] = useState();
    const [loadingReprocess, setLoadingReprocess] = useState(false);
    const [viewButtonReprocessBlocked, setViewButtonReprocessBlocked] = useState(true);

    const [tabName, setTabName] = useState([]);
    const [tabInfo, setTabInfo] = useState([]);
    const history = useNavigate();


    async function reprocessProposalBlocked(id) {
        setLoadingReprocess(true)
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_REPROCESS_BLOCKED}${id}`, {
        }).then((response) => {
            toast.success('Proposta reprocessada com sucesso');
            setLoadingReprocess(false);
            setViewButtonReprocessBlocked(false);
        }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message || "Deu erro ao enviar Proposta para reprocessamento");
            setLoadingReprocess(false);
        })
    }

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }

    async function viewDocument(id, isPdf, type) {
        if (isPdf) {
            window.open(`../viewpdf/${id}/${type}`, '_blank', 'noopener,noreferrer');
        } else {
            window.open(`../viewimage/${id}`, '_blank', 'noopener,noreferrer');
        }
    }

    async function viewPDF(id, type) {
        window.open(`../viewpdf/${id}/${type}/${data?.id}`, '_blank', 'noopener,noreferrer');
    }
    const signatureDocuments = () => {
        return (
            <div className={styles.documentsContainer}>
                {(roleValue?.includes("DOCUMENT_READ") || roleValue?.includes("DOCUMENT_SPECIFIC_READ")) && (
                    <div>
                        <h5 className={styles.tittle}>BBC</h5>
                        <div className={styles.documents}>
                            {documents && documents?.documentBbcList && documents?.documentBbcList?.map((doc) => (
                                <div key={doc?.id}>
                                    {doc?.documentType?.code !== "BSP" && (
                                        <button onClick={() => viewDocument(doc?.id, true, "SIG")}>
                                            <AiFillFilePdf />
                                            {doc?.documentType?.description}
                                        </button >
                                    )}
                                </div>
                            ))}
                            {(!documents || !documents?.documentBbcList || documents?.documentBbcList?.length === 0) && "Nenhum documento"}
                        </div >
                    </div>
                )
                }

                {
                    roleValue?.includes("DOCUMENT_CLIENT_READ") && (
                        <div>
                            <h5 className={styles.tittle}>Clientes</h5>
                            <div className={styles.documents}>
                                {documents && documents?.documentClientSignature && documents?.documentClientSignature?.map((doc) => (
                                    <div key={doc?.id?.document?.id}>
                                        {
                                            doc?.id?.document?.extension.includes("pdf") ? (
                                                <button className={styles.pdf} onClick={() => viewDocument(doc?.id?.document?.id, true, "SIG")} >
                                                    <AiFillFilePdf />
                                                    {`${doc?.id?.document?.documentType?.description}`}
                                                </button>
                                            ) : (
                                                <button className={styles.image} onClick={() => viewDocument(doc?.id?.document?.id, false, null)}>
                                                    <AiFillFileImage />
                                                    {`${doc?.id?.document?.documentType?.description}`}
                                                </button>
                                            )
                                        }
                                    </div>
                                ))}
                                {(!documents || !documents?.documentClientSignature || documents?.documentClientSignature?.length === 0) && "Nenhum documento"}
                            </div>
                        </div>
                    )
                }
            </div >
        )
    }
    const insuranceDocuments = () => {
        return (
            <div className={styles.documentsContainer}>
                <div>
                    {(roleValue?.includes("DOCUMENT_READ") || roleValue?.includes("DOCUMENT_SPECIFIC_READ")) && (
                        <div className={styles.documents}>
                            <div className={styles.documents}>
                                {documents && documents?.documentBbcList && documents?.documentBbcList?.map((doc) => (
                                    <div key={doc?.id}>
                                        {doc?.documentType?.code === "BSP" && (
                                            < button onClick={() => viewDocument(doc?.id, true, "INS")}>
                                                <AiFillFilePdf />
                                                {doc?.documentType?.description}
                                            </button >
                                        )}
                                    </div>
                                ))}
                                {(!documents || !documents?.documentBbcList || documents?.documentBbcList?.length === 0) && "Nenhum documento"}
                            </div >
                        </div>
                    )}
                </div>
            </div>
        )
    }
    const vehicleDocuments = () => {
        return (
            <div className={styles.documentsContainer}>
                {roleValue?.includes("DOCUMENT_PLATE_READ") && (
                    <div>

                        <h5 className={styles.tittle}>Consulta de placa</h5>
                        <div className={styles.documents}>
                            <div className={styles.documents}>
                                {documents && documents?.documentVecPlateList && documents?.documentVecPlateList?.map((doc) => (
                                    < button key={doc?.id?.documentType?.id} onClick={() => {
                                        localStorage.setItem('n', doc?.name);
                                        viewPDF(doc?.id?.documentType?.id, "PLA")
                                    }}>
                                        <AiFillFilePdf />
                                        {doc?.id?.documentType?.description}
                                    </button >
                                ))}
                                {(!documents || !documents?.documentVecPlateList || documents?.documentVecPlateList?.length === 0) && "Nenhum documento"}
                            </div >
                        </div>
                    </div>
                )}
                {roleValue?.includes("DOCUMENT_INSPECTION_READ") && (
                    <div>
                        <h5 className={styles.tittle}>Vistoria</h5>
                        <div className={styles.documents}>
                            {documents && documents?.documentVecInspectionList && documents?.documentVecInspectionList?.map((doc) => (
                                < button key={doc?.id?.documentType?.id} onClick={() => {
                                    localStorage.setItem('n', doc?.name);
                                    viewPDF(doc?.id?.documentType?.id, "INP")
                                }}>
                                    <AiFillFilePdf />
                                    {doc?.id?.documentType?.description}
                                </button >
                            ))}
                            {(!documents || !documents?.documentVecInspectionList || documents?.documentVecInspectionList?.length === 0) && "Nenhum documento"}
                        </div>
                    </div>
                )}
            </div>
        )
    }


    const scoreDocuments = () => {
        return (
            <div className={styles.documentsContainer}>
                {roleValue?.includes("DOCUMENT_SCORE_READ") && (
                    <div>

                        <h5 className={styles.tittle}>Consulta documento de Fraude</h5>
                        <div className={styles.documents}>
                            <div className={styles.documents}>
                                {documents && documents?.documentScore && documents?.documentScore?.map((doc) => (
                                    < button key={doc?.id} onClick={() => {
                                        localStorage.setItem('n', doc?.name);
                                        viewDocument(doc?.id, true, "QDO")
                                    }}>
                                        <AiFillFilePdf />
                                        {doc?.documentType?.description}
                                    </button >
                                ))}
                                {(!documents || !documents?.documentScore || documents?.documentScore?.length === 0) && "Nenhum documento"}
                            </div >
                        </div>
                    </div>
                )}
                {roleValue?.includes("DOCUMENT_SCORE_DATA_READ") && (
                    <div>
                        <h5 className={styles.tittle}>Consulta Score de Fraude</h5>
                        <div className={styles.documents}>
                            {documents && documents?.documentScoreData && documents?.documentScoreData?.map((doc) => (
                                < button key={doc?.id} onClick={() => {
                                    localStorage.setItem('n', doc?.name);
                                    viewDocument(doc?.id, true, "CLS")
                                }}>
                                    <AiFillFilePdf />
                                    {doc?.cpfCnpj ? doc?.cpfCnpj + " - " + doc?.documentType?.description : doc?.documentType?.description}
                                </button >
                            ))}
                            {(!documents || !documents?.documentScoreData || documents?.documentScoreData?.length === 0) && "Nenhum documento"}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const getAllDocuments = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_ALL_DOCUMENTS}${data?.id}`, {
        }).then((response) => {
            setDocuments(response?.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        getAllDocuments();
    }, [])

    useEffect(() => {
        const names = []
        const infos = []
        if (roleValue?.includes("DOCUMENT_READ") || roleValue?.includes("DOCUMENT_SPECIFIC_READ") || roleValue?.includes("DOCUMENT_CLIENT_READ")) {
            names.push("Assinatura", "Seguro")
            infos.push(<div className={`contentModal`}>{signatureDocuments()}</div>, <div className={`contentModal`}>{insuranceDocuments()}</div>)
        }

        const containsScore = data?.proposalStages?.filter(item => item?.stages?.code === "DOCUMENT_SCORE") ||
            data?.proposalStages?.filter(item => item?.stages?.code === "DOCUMENT_SCORE_DATA_CADASTRAL")

        if (
            (
                roleValue?.includes("DOCUMENT_SCORE_READ") ||
                roleValue?.includes("DOCUMENT_PLDOCUMENT_SCORE_DATA_READATE_READ"))
            && containsScore?.length > 0) {
            names.push("Score")
            infos.push(<div className={`contentModal`}>{scoreDocuments()}</div>)
        }

        const containsVehicle = data?.proposalStages?.filter(item => item?.stages?.code === "VEHICLE_INSPECTION") ||
            data?.proposalStages?.filter(item => item?.stages?.code === "VEHICLE_PLATE_CONSULT")
        if (
            (
                roleValue?.includes("DOCUMENT_INSPECTION_READ") ||
                roleValue?.includes("DOCUMENT_PLATE_READ")
            ) && containsVehicle?.length > 0) {
            names.push("Veículo")
            infos.push(<div className={`contentModal`}>{vehicleDocuments()}</div>)
        }



        setTabName(names);
        setTabInfo(infos);
    }, [documents])

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');
        if (!value) return "";
        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }

    const proposalData = () => {
        return (
            <div className={` ${styles.columnInformation}`}>
                <div className={styles.rowsInformation}>
                    <div>
                        <p>Tipo produto</p>
                        <strong>{data?.data?.productType?.description}</strong>
                    </div>
                    <div>
                        <p>Proposta</p>
                        <strong>{data?.data?.proposalCode}</strong>
                    </div>
                    <div>
                        <p>CPF/CNPJ</p>
                        <strong>{data?.data?.insuredCpfCnpj && formatCnpjCpf(data?.data?.insuredCpfCnpj)}</strong>
                    </div>
                    <div>
                        <p>Núm. do contrato</p>
                        <strong>{data?.data?.contractCode}</strong>
                    </div>
                    <div>
                        <p>Origem</p>
                        <strong>{data?.data?.dataOrigin?.description}</strong>
                    </div>
                    {
                        data?.proposalStages?.map((work, index) => (
                            work?.status?.code?.toLowerCase() === 'blocked' &&
                            work?.active && roleValue?.includes("REPROCESS_BLOCKED_PROPOSAL_UPDATE") &&
                            <div>
                                {!loadingReprocess && viewButtonReprocessBlocked ?
                                    <button
                                        className={styles.reprocessBlocked}
                                        key={work?.id?.proposalId}
                                        onClick={() => reprocessProposalBlocked(work?.id?.proposalId)}>
                                        <AiOutlineReload />
                                        Reprocessar
                                    </button>
                                    : <>
                                        {loadingReprocess && <button disabled={true} className={styles.reprocessBlocked}> Aguarde...</button>
                                        }
                                        {!loadingReprocess && !viewButtonReprocessBlocked && (
                                            <button disabled={true} className={styles.reprocessBlocked}>
                                                <AiOutlineReload />
                                                Reprocessar
                                            </button>

                                        )}
                                    </>}
                            </div>
                        ))}

                </div>
            </div>
        )
    }

    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} >
            <div className={`${styles.allDocumentsContainer}`}>
                {loading && <Loading />}
                {!loading && (
                    <>
                        <h5 className={styles.tittle}>Proposta</h5>
                        {proposalData()}
                        <br />
                        <h5 className={styles.tittle}>Todos os documentos</h5>
                        <br />
                        <Tab
                            tabsName={tabName}
                            tabsInfo={tabInfo}
                        />
                    </>)}
            </div>
        </Modal>
    )
}

export default AllDocumentsModal