import styles from './Toggle.module.css'

function Toggle({onChange, text, checked = false}){
  return (
    <div className={styles.ToggleButtonCover}>
        <span>{text}</span>
        <label className={styles.switch}>
            <input type="checkbox" checked={checked}  onChange={onChange}></input>
            <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
    </div>
  )
}

export default Toggle