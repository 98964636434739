import { AiOutlineSearch } from 'react-icons/ai';

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkJwt } from "../../../helpers/Jwt-helper";
import api from "../../../services/api";
import InputFilter from "../../form/InputFilter/InputFilter";
import Container from "../../layout/container/Container";
import TableCombat from "../../project/tableCombat/TableCombat";
import styles from './Combat.module.css';
import Loading from '../../layout/loading/Loading';

const Combat = () => {
    const navigate = useNavigate();
    const [removeLoading, setRemoveLoading] = useState(true);
    const [dataTable, setDataTable] = useState([])
    const [currentPage, setCurrentPage] = useState(0);

    const [filter, setFilter] = useState({});

    function handleChange(event) {
        setFilter({ ...filter, [event.target.name]: event.target.value })
    }

    const submit = (event) => {
        event.preventDefault();
        checkJwt(navigate).then((response) => {
            if (response) {
                search();
            }
        })
    }

    const search = async () => {
        try {
            setRemoveLoading(false);
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_AUTH_TOKEN_COMBAT}/${filter?.proposalNumber}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
            }).then((response) => {
                setDataTable(response.data);
                setRemoveLoading(true);
            })
                .catch((error) => {
                    setDataTable([])
                    console.log(error)
                    setRemoveLoading(true)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    return (
        <div className={styles.combatContainer}>
            <div className={`${styles.card} ${styles.column}`}  >
                <h2>Filtro</h2>
                <div className={styles.filter}>
                    <form onSubmit={submit}>
                        <InputFilter type="text" name={"proposalNumber"} text="Num. da proposta" handleOnChange={handleChange} required={true} maxLength={10}/>
                        <button>
                            <AiOutlineSearch />
                            Buscar
                        </button>
                    </form>
                </div>
            </div>
            <div className={`${styles.card}`}  >
                <h2>Combate</h2>
                {!removeLoading && <Loading />}
                <Container>
                    {removeLoading && (
                        <TableCombat
                            object={dataTable}
                            setDataTable={setDataTable}
                            removeLoading={removeLoading}
                            search={search}
                        />

                    )}
                </Container>
            </div>
        </div>
    )
}

export default Combat