import { useCallback, useState } from "react";
import Modal from "react-responsive-modal";
import styles from "./ViewSubscribersModal.module.css";
import { checkJwt } from "../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useEffect } from "react";
import Loading from "../../layout/loading/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ConfirmResendModal from "../confirmResendModal/ConfirmResendModal";
import moment from "moment";

// useContext
import { useContext } from "react";
import { RoleContext } from "../../hooks/HookUseContext";
import ClearSaleChart from "../clearSaleChart/ClearSaleChart";
import { AiFillCheckSquare, AiFillCloseSquare } from "react-icons/ai";
import ModalDetailsDocumentDataCadastral from "../modalDetailsDocumentDataCadastral/ModalDetailsDocumentDataCadastral";

function ViewSubscribersModal({ data, updateTable }) {
  const { roleValue } = useContext(RoleContext);
  const navigate = useNavigate();
  const [object, setObject] = useState([]);
  const [removeLoading, setRemoveLoading] = useState(true);
  const [dataPerson, setDataPerson] = useState(null);
  const [openConfirmResendModal, setOpenConfirmResendModal] = useState(false);
  const [haveResend, setHaveResend] = useState(false);

  const [informationStage, setInformationStage] = useState("");
  const [modalInformationQuodOrClearSale, setModalInformationQuodOrClearSale] =
    useState(false);
  const onCloseModalInformationQuodOrClearSale = () =>
    setModalInformationQuodOrClearSale(false);

  const MAX_ATTEMPTS = 4;

  function phoneMask(phone) {
    phone = phone.replace(/\D/g, "");
    phone = phone.replace(/^(\d)/, "+$1");
    phone = phone.replace(/(.{3})(\d)/, "$1($2");
    phone = phone.replace(/(.{6})(\d)/, "$1)$2");
    if (phone.length === 12) {
      phone = phone.replace(/(.{1})$/, "-$1");
    } else if (phone.length === 13) {
      phone = phone.replace(/(.{2})$/, "-$1");
    } else if (phone.length === 14) {
      phone = phone.replace(/(.{3})$/, "-$1");
    } else if (phone.length === 15) {
      phone = phone.replace(/(.{4})$/, "-$1");
    } else if (phone.length > 15) {
      phone = phone.replace(/(.{4})$/, "-$1");
    }
    phone = phone.replace("+55", "55 ");
    phone = phone.replace(")", ") ");
    return phone;
  }

  const onResend = (data, idProposal) => {
    checkJwt(navigate).then((response) => {
      if (response) {
        if (data?.resendAmount >= MAX_ATTEMPTS) {
          toast.warn("Limite de reenvios Atingido!");
          return;
        } else if (data?.resendAmount >= 1) {
          setDataPerson(data);
          setOpenConfirmResendModal(true);
          return;
        } else {
          patchResend(data, idProposal);
          return;
        }
      }
    });
  };
  async function patchResend(data, idProposal) {
    setRemoveLoading(false);
    const ResendBodyDto = {
      cpf: data?.cpf,
      unicoId: data?.unicoId,
      proposalId: idProposal,
      order: data?.order,
      step: data?.step,
      signatureId: data?.signatureId,
    };
    api
      .patch(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SUBSCRIBERS_RESEND}`,
        ResendBodyDto,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setObject(response?.data?.object);
        setRemoveLoading(true);
        setHaveResend(true);
        toast.success(`${response?.data?.message}`);
      })
      .catch((error) => {
        console.log(error);
        setRemoveLoading(true);
        getSubscribers();
        setHaveResend(true);
        let message = "Ouve um erro no reenvio!";

        if (error?.response?.data?.message)
          message = error?.response?.data?.message;

        if (error?.response?.data) message = error?.response?.data;

        toast.error(`Deu erro! ${message}`);
      });
  }
  const getSubscribers = useCallback(async () => {
    try {
      setRemoveLoading(false);
      await api
        .get(
          `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_SUBSCRIBERS_RESEND}/${data?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
            },
          }
        )
        .then((response) => {
          setObject(response?.data?.object);
          setRemoveLoading(true);
        })
        .catch((error) => {
          console.log(error);
          setRemoveLoading(true);
        });
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  }, [data]);
  useEffect(() => {
    getSubscribers();
  }, [getSubscribers]);

  function formatCnpjCpf(value) {
    if (!value) return value;

    const cnpjCpf = value.replace(/\D/g, "");

    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    }

    return cnpjCpf.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }
  return (
    <div className={styles.modalContainer}>
      {modalInformationQuodOrClearSale === true && (
        <ModalDetailsDocumentDataCadastral
          open={modalInformationQuodOrClearSale}
          onCloseModal={onCloseModalInformationQuodOrClearSale}
        />
      )}
      {openConfirmResendModal === true && (
        <ConfirmResendModal
          setOpenModal={setOpenConfirmResendModal}
          eventConfirm={patchResend}
          data={dataPerson}
          proposalId={data?.id}
          MAX_ATTEMPTS={MAX_ATTEMPTS}
        />
      )}
      {!removeLoading ? (
        <div className={styles.loaderContainer}>
          {" "}
          <Loading />
        </div>
      ) : (
        <div className={styles.subscribers}>
          <div className={styles.columnInformation}>
            <h3 className={styles.tittle}>Informação(es) do(s) títular(es)</h3>
            {object && object?.holderList && object?.holderList?.length > 0
              ? object?.holderList?.map((holder) => (
                  <div key={holder?.cpf} className={styles.rowsInformation}>
                    <div>
                      <p>Nome</p>
                      <strong>{holder?.name}</strong>
                    </div>
                    <div>
                      <p>Cpf</p>
                      <strong>
                        {holder?.cpf && formatCnpjCpf(holder?.cpf)}
                      </strong>
                    </div>
                    <div>
                      <p>Telefone</p>
                      <strong>
                        {holder?.phone ? phoneMask(holder?.phone) : ""}
                      </strong>
                    </div>
                    <div>
                      <p>Status</p>
                      <strong>{holder?.description}</strong>
                    </div>
                    <div>
                      <p>Score</p>
                      {holder?.score ? (
                        <>
                          {holder?.score > 0 ? (
                            <strong className={styles.approved}>
                              {holder?.score}
                            </strong>
                          ) : (
                            <strong className={styles.disapproved}>
                              {holder?.score}
                            </strong>
                          )}
                        </>
                      ) : (
                        <strong>Nenhuma</strong>
                      )}
                    </div>
                    <div>
                      <p>Imagem Rosto X Documento</p>
                      {holder?.faceMatchDescription === "Reprovado" ? (
                        <strong className={styles.failedDescription}>
                          {holder?.faceMatchDescription}
                        </strong>
                      ) : holder?.faceMatchDescription === "Aprovado" ? (
                        <strong className={styles.approvedDescription}>
                          {holder?.faceMatchDescription}
                        </strong>
                      ) : (
                        <strong>
                          {holder?.faceMatchDescription || "Nenhuma"}
                        </strong>
                      )}
                    </div>
                    <div>
                      <p>Cpf X Documento</p>
                      {holder?.ocrDescription === "Reprovado" ? (
                        <strong className={styles.failedDescription}>
                          {holder?.ocrDescription}
                        </strong>
                      ) : holder?.ocrDescription === "Aprovado" ? (
                        <strong className={styles.approvedDescription}>
                          {holder?.ocrDescription}
                        </strong>
                      ) : (
                        <strong>{holder?.ocrDescription || "Nenhuma"}</strong>
                      )}
                    </div>
                    <div>
                      <p>Data</p>
                      <strong>
                        {holder?.updatedAt
                          ? moment(holder?.updatedAt).format("DD/MM/YYYY HH:mm")
                          : "Nenhuma"}{" "}
                      </strong>
                    </div>
                    <div>
                      <p>Email</p>
                      <strong>{holder?.email} </strong>
                    </div>
                    <div>
                      <p>Tipo Cliente</p>
                      <strong>
                        {holder?.involved === "HOLDER_PROPONENT" &&
                          "Proponente"}{" "}
                      </strong>
                      <strong>
                        {holder?.involved === "HOLDER_CLIENT" && "Cliente"}{" "}
                      </strong>
                    </div>
                    <div>
                      <p>Envio do link por E-mail</p>
                      <input
                        className={styles.customCheckbox}
                        type="checkbox"
                        checked={holder?.isLinkSent && holder?.isLinkSent}
                      />
                    </div>
                    <div style={{ gridColumn: 4 }}>
                      {!roleValue?.includes(
                        "DIGITAL_SIGNATURE_RESENT_CREATE"
                      ) ||
                      holder?.description === "Assinado" ||
                      holder?.description === "Pendente" ||
                      holder?.description === "Em divergência" ||
                      holder?.resendAmount >= MAX_ATTEMPTS ? (
                        <button disabled>Reenviar</button>
                      ) : (
                        <button onClick={() => onResend(holder, data?.id)}>
                          Reenviar
                        </button>
                      )}
                    </div>
                    {/*<div>
                                        <p>Quod</p>
                                        {false ? (

                                            <div className={styles.quoudOk}><AiFillCheckSquare /></div>
                                        ) : <div className={styles.quoudNotOk}><AiFillCloseSquare /></div>}
                                        <button onClick={() => onOpenModalInformationQuodOrClearSale("QUOUD")} className={styles.btnDetails}>Detalhes</button>
                                    </div>
                                    <div>
                                        <p>ClearSale</p>
                                        <div className={styles.chart}><ClearSaleChart /></div>
                                        <button onClick={() => onOpenModalInformationQuodOrClearSale("CLEARSALE")} className={styles.btnDetails}>Detalhes</button>
                                        </div>*/}
                  </div>
                ))
              : "Não há dados"}

            <h3 className={styles.tittle}>Informação(es) do(s) assinante(s)</h3>
            {object &&
            object?.subscriberList &&
            object?.subscriberList?.length > 0
              ? object?.subscriberList?.map((subscriber) => (
                  <div key={subscriber?.cpf} className={styles.rowsInformation}>
                    <div>
                      <p>Nome</p>
                      <strong>{subscriber?.name}</strong>
                    </div>
                    <div>
                      <p>Cpf</p>
                      <strong>
                        {subscriber?.cpf && formatCnpjCpf(subscriber?.cpf)}
                      </strong>
                    </div>
                    <div>
                      <p>Telefone</p>
                      <strong>
                        {subscriber?.phone ? phoneMask(subscriber?.phone) : ""}
                      </strong>
                    </div>
                    <div>
                      <p>Status</p>
                      <strong>{subscriber?.description}</strong>
                    </div>
                    <div>
                      <div>
                        <p>Score</p>
                        {subscriber?.score ? (
                          <>
                            {subscriber?.score > 0 ? (
                              <strong className={styles.approved}>
                                {subscriber?.score}
                              </strong>
                            ) : (
                              <strong className={styles.disapproved}>
                                {subscriber?.score}
                              </strong>
                            )}
                          </>
                        ) : (
                          <strong>Nenhuma</strong>
                        )}
                      </div>
                    </div>
                    <div>
                      <p>Imagem Rosto X Documento</p>
                      {subscriber?.faceMatchDescription === "Reprovado" ? (
                        <strong className={styles.failedDescription}>
                          {subscriber?.faceMatchDescription}
                        </strong>
                      ) : subscriber?.faceMatchDescription === "Aprovado" ? (
                        <strong className={styles.approvedDescription}>
                          {subscriber?.faceMatchDescription}
                        </strong>
                      ) : (
                        <strong>
                          {subscriber?.faceMatchDescription || "Nenhuma"}
                        </strong>
                      )}
                    </div>
                    <div>
                      <p>Cpf X Documento</p>
                      {subscriber?.ocrDescription === "Reprovado" ? (
                        <strong className={styles.failedDescription}>
                          {subscriber?.ocrDescription}
                        </strong>
                      ) : subscriber?.ocrDescription === "Aprovado" ? (
                        <strong className={styles.approvedDescription}>
                          {subscriber?.ocrDescription}
                        </strong>
                      ) : (
                        <strong>
                          {subscriber?.ocrDescription || "Nenhuma"}
                        </strong>
                      )}
                    </div>
                    <div>
                      <p>Data</p>
                      <strong>
                        {subscriber?.updatedAt
                          ? moment(subscriber?.updatedAt).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "Nenhuma data"}{" "}
                      </strong>
                    </div>
                    <div>
                      <p>Email</p>
                      <strong>{subscriber?.email} </strong>
                    </div>
                    <div style={{ gridColumn: 4 }}>
                      {!roleValue?.includes(
                        "DIGITAL_SIGNATURE_RESENT_CREATE"
                      ) ||
                      subscriber?.description === "Assinado" ||
                      subscriber?.description === "Pendente" ||
                      subscriber?.description === "Em divergência" ||
                      subscriber?.resendAmount >= MAX_ATTEMPTS ? (
                        <button disabled>Reenviar</button>
                      ) : (
                        <button onClick={() => onResend(subscriber, data?.id)}>
                          Reenviar
                        </button>
                      )}
                    </div>
                  </div>
                ))
              : "Não há dados"}
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewSubscribersModal;
