import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-responsive-modal';
import api from '../../../../services/api';
import Loading from '../../../layout/loading/Loading';
import { checkJwt } from '../../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import styles from './ModalRegularizeDigitalSignature.module.css';
import { AiFillFileImage, AiFillFilePdf } from 'react-icons/ai';
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { RoleContext } from '../../../hooks/HookUseContext';
import ModalConfirmDeleteDocument from './modalConfirmDeleteDocument/ModalConfirmDeleteDocument';

const ModalRegularizeDigitalSignature = ({ setOpenModal, dataProposalStages, cpfCnpj, unicoId }) => {
    const [open, setOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingButtonUpdate, setLoadingButtonUpdate] = useState(false);
    const [data, setData] = useState(null);
    const [documentid, setDocumentId] = useState(null);
    const { roleValue } = useContext(RoleContext);
    const inputRef = useRef(null);

    const codesClientDocuments = ["COSTUMER_PHOTO", "CONSENT_TERM", "DIGITAL_SIGNATURE", "CNH"];
    const codeNameMapping = {
        "COSTUMER_PHOTO": "Foto do Cliente",
        "CONSENT_TERM": "Termo de Consentimento",
        "DIGITAL_SIGNATURE": "Assinatura Digital",
        "CNH": "CNH",
    };

    const [addDocument, setAddCoument] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const navigate = useNavigate();

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };
    console.log(selectedValue)

    const checkTokenViewPDF = (id, code) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewPDF(id, code);
            }
        })
    }

    async function viewPDF(id, code) {
        window.open(`../viewpdf/${id}/${code}`, '_blank', 'noopener,noreferrer');
    }

    const checkTokenViewDocument = (id, isPdf, code) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                viewDocument(id, isPdf, code);
            }
        })
    }
    async function viewDocument(id, isPdf, code) {
        if (isPdf) {
            viewPDF(id, code)
        } else {
            window.open(`../viewimage/${id}`, '_blank', 'noopener,noreferrer');
        }
    }

    async function handleUpdateDocuments() {
        setLoadingButtonUpdate(true);
        await api.put(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_DIGITAL_SIGNATURE_REGULARIZE_DOCUMENTS_TO_UPDATE}${unicoId}/${dataProposalStages?.id?.proposalId}/${cpfCnpj}`, {
        }).then((response) => {
            toast.success(`Documentos Atualizados!`);
            getDocumentsRegularize(dataProposalStages?.id?.proposalId);
            setLoadingButtonUpdate(false)
        }).catch((error) => {
            console.log(error)
            toast.error(error?.response?.data?.message || error?.response?.data || `Houve um erro na atualização dos documentos!`);
            setLoadingButtonUpdate(false)
        })
    }

    async function getDocumentsRegularize(id) {
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_DIGITAL_SIGNATURE_REGULARIZE}${id}/${cpfCnpj}`, {
        }).then((response) => {
            setData(response?.data);
            setLoading(false);
        }).catch((error) => {
            setData(null);
            setLoading(false);
        })
    }

    async function updateDocument(content, fileExtension) {
        console.log(documentid)
        await api.post(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_DIGITAL_SIGNATURE_REGULARIZE_DOCUMENT_TO_UPDATE}`, {
            id: documentid,
            content,
            extension: fileExtension,
            proposalId: dataProposalStages?.id?.proposalId
        }, {
        }).then((response) => {
            toast.success(`Documento Atualizado!`);
            getDocumentsRegularize(dataProposalStages?.id?.proposalId);
        }).catch((error) => {
            console.log(error)
            toast.error(error?.response?.data?.message || error?.response?.data || `Houve um erro na atualização do documento!`);
        })
    }

    async function newDocument(content, fileExtension) {
        await api.post(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_DIGITAL_SIGNATURE_REGULARIZE_DOCUMENT_TO_CREATE}`, {
            content,
            extension: fileExtension,
            proposalId: dataProposalStages?.id?.proposalId,
            code: selectedValue,
            cpfCnpj
        }, {
        }).then((response) => {
            toast.success(`Documento Atualizado!`);
            getDocumentsRegularize(dataProposalStages?.id?.proposalId);
        }).catch((error) => {
            console.log(error)
            toast.error(error?.response?.data?.message || error?.response?.data || `Houve um erro na atualização do documento!`);
        })
    }



    const convertFileToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result.split(',')[1]);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };


    const handleClickUploadInternal = (event) => {
        event.stopPropagation();

        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleClickDeleteInternal = (event, id) => {
        event.stopPropagation();
        openModalConfirmDeleteDocumentEvent(id);
    };

    const openModalConfirmDeleteDocumentEvent = (id) => {
        setDocumentId(id);
        setModalOpen(true);
    }
    const getFileExtension = (fileName) => {
        return fileName.split('.').pop(); // Obtém a última parte após o ponto (a extensão)
    };

    const updateInputRef = (ref) => {
        inputRef.current = ref;
    };

    const handleFileChange = async (event, document, isNew) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

            if (allowedImageTypes.includes(selectedFile.type)) {
                toast.success(`Arquivo selecionado: ${selectedFile.name}`);
                const base64String = await convertFileToBase64(selectedFile);
                const fileExtension = getFileExtension(selectedFile.name);
                if (base64String && fileExtension) {
                    if (!isNew)
                        updateDocument(base64String, fileExtension);

                    if (isNew)
                        newDocument(base64String, fileExtension)
                }

            } else {
                toast.error('Por favor, selecione um arquivo válido. jpeg, jpg, png ou pdf');
            }
        }
        event.target.value = null;
    };



    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getDocumentsRegularize(dataProposalStages?.id?.proposalId);
            }
        })
    }, [])

    useEffect(() => {
        if (data && data.documentsSingleDto)
            setSelectedValue(() => {
                const firstAvailableCode = codesClientDocuments.find(code =>
                    !data.documentsSingleDto.some(item => item?.documentType?.code === code)
                );

                return firstAvailableCode || '';
            })
    }, [data])

    const viewDocumentsUpdateAllDtos = () => {
        return (
            <div className={`${styles.actionButtonsPDF}`}>
                <h2>Atualização automática</h2>
                {data && data?.documentsUpdateAllDto && data?.documentsUpdateAllDto.length > 0 ? data?.documentsUpdateAllDto?.map((document) => (
                    <div key={document?.id} className={styles.teste}>
                        {roleValue?.includes("DOCUMENT_READ") && (
                            <>
                                <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id, "SIG")}>
                                    <AiFillFilePdf />
                                    {document?.documentType?.description}
                                </button>
                            </>
                        )}
                        {roleValue?.includes("DOCUMENT_SPECIFIC_READ") &&
                            (document?.documentType?.code === "BSP" ||
                                document?.documentType?.code === "TSP") &&
                            (
                                <button key={document?.id} onClick={() => checkTokenViewPDF(document?.id, "SIG")}>
                                    <AiFillFilePdf />
                                    {document?.documentType?.description}
                                </button>
                            )
                        }
                    </div>
                )) : <button disabled className={styles.disabled}>Não há documentos</button>}
                {data && data?.documentsUpdateAllDto && data?.documentsUpdateAllDto.length > 0 && (
                    <>
                        {!loadingButtonUpdate && (
                            <button className={styles.btn} onClick={handleUpdateDocuments}>Atualizar</button>
                        )}
                        {loadingButtonUpdate && (
                            <>
                                <br />
                                <button style={
                                    {
                                        opacity: ".5",
                                        cursor: "not-allowed",
                                        background: "#00763d",
                                        padding: "1em",
                                        color: "#FFF"
                                    }}>
                                    Aguarde...
                                </button>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }

    const getFileFromMachine = (document) => {
        return (
            <>
                {(document?.documentType.code === "COSTUMER_PHOTO" ||
                    document?.documentType.code === "CONSENT_TERM" ||
                    document?.documentType.code === "DIGITAL_SIGNATURE" ||
                    document?.documentType.code === "CNH") && (
                        <span className={styles.upload} onClick={(e) => {
                            handleClickUploadInternal(e)
                            setDocumentId(document?.id)
                        }}>
                            <input
                                ref={(ref) => updateInputRef(ref)}
                                type="file"
                                accept="image/*, application/pdf"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileChange(e, document, false)}
                            />
                            <FaCloudUploadAlt />
                        </span>
                    )}
            </>
        );
    };

    const deleteFile = (document) => {
        return (
            <>
                {(document?.documentType.code === "DIGITAL_SIGNATURE") && (
                    <span className={styles.deleteFile}
                        onClick={(e) => {
                            handleClickDeleteInternal(e, document?.id)
                        }}>
                        <MdDelete />
                    </span>
                )}
            </>
        )
    }


    const viewDocumentsSingleDto = () => {
        return (
            <div className={`${styles.actionButtonsPDF}`}>
                <h2>Atualização manual</h2>
                {data && data.documentsSingleDto && data.documentsSingleDto.length > 0 ? (
                    data.documentsSingleDto.map((document) => (
                        <div key={document.id} className={styles.teste}>
                            {roleValue.includes("DOCUMENT_CLIENT_READ") && (
                                document?.extension.includes("pdf") ? (
                                    <button className={styles.pdf} key={document?.id} onClick={() => checkTokenViewDocument(document?.id, true, "SIG")}>
                                        <AiFillFilePdf />
                                        {`${document?.documentType.description}`}
                                        <div className={styles.file}>
                                            {deleteFile(document)}
                                            {getFileFromMachine(document)}
                                        </div>
                                    </button>
                                ) : (
                                    <button className={styles.image} key={document?.id} onClick={() => checkTokenViewDocument(document?.id, false, "SIG")}>
                                        <AiFillFileImage />
                                        {`${document?.documentType.description}`}
                                        <div className={styles.file}>
                                            {deleteFile(document)}
                                            {getFileFromMachine(document)}
                                        </div>
                                    </button>
                                )
                            )}
                        </div>
                    ))
                ) : (
                    <button disabled className={styles.disabled}>Não há documentos</button>
                )}

                {data && data.documentsSingleDto && codesClientDocuments.some(code => !data.documentsSingleDto.some(item => item?.documentType?.code === code)) && (
                    <>
                        <div className={styles.addDocumentContainer}>
                            <button onClick={() => setAddCoument(true)}><IoIosAdd /> Add doc</button>
                            {addDocument && (
                                <div className={styles.selectedFileNewContainer}>
                                    <select value={selectedValue} onChange={handleSelectChange}>
                                        {codesClientDocuments.map(code => {
                                            const isCodePresent = data.documentsSingleDto.some(item => item?.documentType?.code === code);
                                            const optionName = codeNameMapping[code] || code;
                                            if (!isCodePresent && !selectedValue)
                                                setSelectedValue(code)
                                            return (
                                                <option key={code} value={code} disabled={isCodePresent}>
                                                    {optionName} {isCodePresent ? "Doc presente" : ""}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {selectedValue && (
                                        <span className={`${styles.upload} ${styles.uploadNew}`} onClick={(e) => {
                                            handleClickUploadInternal(e)
                                        }}>
                                            <input
                                                ref={(ref) => updateInputRef(ref)}
                                                type="file"
                                                accept="image/*, application/pdf"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange(e, document, true)}
                                            />
                                            <FaCloudUploadAlt />
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}


            </div>
        );
    };


    return (
        <Modal open={open} center onClose={closedModal}>
            {modalOpen === true && <ModalConfirmDeleteDocument
                documentId={documentid}
                setOpenModal={setModalOpen}
                getDocumentsRegularize={getDocumentsRegularize}
                dataProposalStages={dataProposalStages}

            />}
            {loading && <Loading />}

            {!loading && (
                <div className={styles.container}>
                    <div >
                        <div className={styles.documents}>
                            {viewDocumentsUpdateAllDtos()}
                            {viewDocumentsSingleDto()}
                        </div>
                    </div>
                </div>
            )}

        </Modal >
    )
}

export default ModalRegularizeDigitalSignature