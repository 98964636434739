import styles from './SelectFilter.module.css'

function SelectFilter({text, name, options, handleOnChange, value, textDefault, required = false, customClass}){
    return (
        <div className={`${styles.formControl} ${styles[customClass]}`}>
            <label htmlFor={name}>{text}</label>
            <select name={name} id={name} onChange={handleOnChange} value={value || ''} required={required}  >
                <option value={''}>{textDefault}</option>
                { options.map((option) => (
                    <option value={option.id} key={option.id}>{option.name}</option>
                ))}
            </select>
        </div>
    );
}

export default SelectFilter;