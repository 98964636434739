import { useContext, useState } from 'react';
import Loading from '../../layout/loading/Loading';
import styles from './TableCombat.module.css';
import 'react-toastify/dist/ReactToastify.min.css';

import ConfirmCombatModal from '../confirmCombatModal/ConfirmCombatModal';

import moment from 'moment';

// useContext
import { RoleContext } from '../../hooks/HookUseContext';

const TableCombat = ({ object, setDataTable, removeLoading, search }) => {

    const { roleValue } = useContext(RoleContext);

    const [currentData, setCurrentData] = useState(object);
    const [dataToSendModal, setDataToSendModal] = useState(null);

    const [openCombatModal, setOpenCombatModal] = useState(false);

    const handleActionCombat = (data) => {
        setDataToSendModal(data);
        setOpenCombatModal(true);
    }

    return (
        <div className={styles.divContainer}>
            {!removeLoading && <Loading />}
            {openCombatModal && (<ConfirmCombatModal data={dataToSendModal} setDataTable={setDataTable} setOpenModal={setOpenCombatModal} search={search} />)}
            <table className={styles.tableCombatContainer}>
                <thead>
                    <tr>
                        <th>Proposta</th>
                        <th>Código Atividade</th>
                        <th>Descricao Atividade</th>
                        <th>Descricao produto</th>
                        <th>Tipo produto</th>
                        <th>Login digitador</th>
                        <th>Data cadastro</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {(!currentData || currentData?.length === 0) && (
                        <tr className={styles.noData}>
                            <td>Não há dados</td>
                        </tr>
                    )}
                    {(currentData && currentData?.length !== 0) && (
                        <tr>
                            <td>
                                <strong>Proposta</strong>
                                {currentData?.proposalCode}
                            </td>
                            <td>
                                <strong>Código Atividade</strong>
                                {currentData?.numberActivity}
                            </td>
                            <td>
                                <strong>Descrição da atividade</strong>
                                {currentData?.descriptionActivity}
                            </td>
                            <td>
                                <strong>Descricao produto</strong>
                                {currentData?.productType?.description}
                            </td>
                            <td>
                                <strong>Tipo produto</strong>
                                {currentData?.productType?.code}
                            </td>
                            <td>
                                <strong>Login digitador</strong>
                                {currentData?.loginDigitator}
                            </td>
                            <td>
                                <strong>Data cadastro</strong>
                                {currentData?.dateRegistration ? moment(currentData?.dateRegistration).format('DD/MM/YYYY') : ''}
                            </td>
                            <td>
                                {currentData?.canFight === true && roleValue?.includes("COMBAT_UPDATE") ?
                                    (
                                        <button onClick={() => handleActionCombat(currentData)}>Combater</button>
                                    ) : <button disabled>Combater</button>
                                }
                            </td>

                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TableCombat