import { useCallback, useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import { checkJwt } from '../../../helpers/Jwt-helper';
import api from '../../../services/api';
import styles from './RejectProposalModal.module.css';

import { useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


function RejectProposalModal({ setOpenModal, data, updateData, setRemoveLoading }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const buttonStartRef = useRef(null);

    const closedModal = useCallback(() => {
        setOpen(false);
        setOpenModal(false);
    }, [setOpen, setOpenModal])

    const handleRejectProposal = () => {
        checkJwt(navigate).then((response) => {
            if (response) {
                rejectProposal(data.id);
            }
        })
    }

    async function rejectProposal(id) {
        setRemoveLoading(false);
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL_REJECT}${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            toast.success(`${response?.data?.message || "Sucesso!"}`);
            updateData(response?.data?.object, 200);
            setRemoveLoading(true);
            closedModal();
        }).catch((error) => {
            setRemoveLoading(true);
            console.log(error);
            updateData(error?.response?.data?.object, 0);
            closedModal();
            toast.error(`Deu erro! ${error?.response?.data}`);
        })
    }
    useEffect(() => {
        return () => closedModal();
    }, [closedModal])
    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalRejectProposalContainer} onLoad={() => buttonStartRef.current.focus()}>
                <AiOutlineWarning />
                <h2>Você está rejeitando uma Proposta!</h2>
                <p>Você tem certeza que deseja rejeitar a</p>
                <strong>proposta {data?.data?.proposalCode}?</strong>
                <div className={styles.actionsButtons}>
                    <button onClick={closedModal} selected ref={buttonStartRef} onLoadStart={() => buttonStartRef.current.focus()}>Não rejeitar!</button>
                    <button onClick={() => handleRejectProposal()}>Sim, rejeitar!</button>
                </div>
            </div>
        </Modal>
    );
}

export default RejectProposalModal;