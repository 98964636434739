import React, { useState } from "react";
import { checkJwt } from "../../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";
import api from "../../../../services/api";
import { toast } from "react-toastify";

const AttachDocumentsButton = ({ item, updateProposalStage }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const attachDocuments = (item) => {
    checkJwt(navigate).then((response) => {
      if (response) {
        attachDocumentsFuncao(item?.id?.proposalId, item?.stages?.code);
      }
    });
  };

  async function attachDocumentsFuncao(proposalId, stage) {
    setLoading(true);
    await api
      .post(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_STAGE_ATTACH_DOCUMENTS}`,
        {
          proposalId,
          stage,
        },
        {}
      )
      .then((response) => {
        toast.success("Sucesso");
        setLoading(false);
        updateProposalStage(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        handleError(error);
      });
  }

  const handleError = (error) => {
    if (error.response && error.response.data) {
      let errorMessage = error?.response?.data?.message;

      if (!errorMessage) {
        errorMessage = error?.response?.data;
        console.log(error?.response?.data);
      }
      toast.error(`
                ${errorMessage}
            `);
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error(error?.response?.data);
      console.error("Erro desconhecido:", error);
    }
  };
  return (
    <>
      {!loading ? (
        <button onClick={() => attachDocuments(item)}>Anexar doc</button>
      ) : (
        <button disabled>Aguarde...</button>
      )}
    </>
  );
};

export default AttachDocumentsButton;
