// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageForbidden_pageNotFoundContainer__aQG2H{\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    text-align: center;\r\n    flex-wrap: wrap;\r\n    margin: 1em;\r\n}\r\n\r\n.PageForbidden_pageNotFoundContainer__aQG2H h1, \r\n.PageForbidden_pageNotFoundContainer__aQG2H h2{\r\n    font-size:5vw;\r\n}\r\n.PageForbidden_pageNotFoundContainer__aQG2H p{\r\n    font-size:2.9vw;\r\n}\r\n.PageForbidden_pageNotFoundContainer__aQG2H p a{\r\n    text-decoration: none;\r\n    color: rgb(46, 46, 46);\r\n}\r\n\r\n.PageForbidden_pageNotFoundContainer__aQG2H p a:hover{\r\n    color: rgb(65, 65, 65);\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/pages/forbidden/PageForbidden.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;;IAEI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".pageNotFoundContainer{\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    text-align: center;\r\n    flex-wrap: wrap;\r\n    margin: 1em;\r\n}\r\n\r\n.pageNotFoundContainer h1, \r\n.pageNotFoundContainer h2{\r\n    font-size:5vw;\r\n}\r\n.pageNotFoundContainer p{\r\n    font-size:2.9vw;\r\n}\r\n.pageNotFoundContainer p a{\r\n    text-decoration: none;\r\n    color: rgb(46, 46, 46);\r\n}\r\n\r\n.pageNotFoundContainer p a:hover{\r\n    color: rgb(65, 65, 65);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageNotFoundContainer": "PageForbidden_pageNotFoundContainer__aQG2H"
};
export default ___CSS_LOADER_EXPORT___;
