import Container from "../../layout/container/Container";
import styles from "./BusinessDirectory.module.css";
import Tab from "../../tab/Tab";
import { useContext, useEffect, useState } from "react";
import { RoleContext } from "../../hooks/HookUseContext";
import TableShops from "../../project/businessDirectory/tableShops/TableShops";
import TableCompany from "../../project/businessDirectory/tableCompany/tableCompany/TableCompany";
import TableCompanyActions from "../../project/businessDirectory/tableCompanyActions/TableCompanyActions";
import TableAuth from "../../project/businessDirectory/tableAuth/TableAuth";
const BusinessDirectory = () => {
  const { roleValue } = useContext(RoleContext);
  const [tabsName, setTabsName] = useState([]);
  const [tabsInfo, setTabsInfo] = useState([]);
  useEffect(() => {
    let name = [];
    let info = [];

    if (roleValue?.includes("STORE_READ")) {
      name.push("Lojas");
      info.push(<TableShops />);
    }
    if (roleValue?.includes("COMPANY_READ")) {
      name.push("Companhias");
      info.push(<TableCompany />);
    }

    if (roleValue?.includes("COMPANY_READ")) {
      name.push("Actions");
      info.push(<TableCompanyActions />);
    }

    if (roleValue?.includes("USERAUTH_READ")) {
      name.push("Auth");
      info.push(<TableAuth />);
    }

    setTabsName(name);
    setTabsInfo(info);
  }, []);
  return (
    <div className={styles.container}>
      <div className={`${styles.card} ${styles.column}`}>
        <Container>
          <Tab tabsName={tabsName} tabsInfo={tabsInfo} />
        </Container>
      </div>
    </div>
  );
};

export default BusinessDirectory;
