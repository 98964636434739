// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubmitButton_btn__941jA{\r\n    color: #fff;\r\n    background-color: #198754;\r\n    border-color: #198754;\r\n    padding: .7em 1.2em;\r\n    text-decoration: none;\r\n    transition: .5s;\r\n    border-radius: .25rem;\r\n    text-align: center;\r\n    cursor: pointer;\r\n    border: none;\r\n    margin: 1em;\r\n    margin-top: 0;\r\n    margin-bottom: 1em;\r\n}\r\n\r\n.SubmitButton_btn__941jA:hover{\r\n    background-color: #006335;\r\n    border-color: #006335;\r\n}\r\n\r\n.SubmitButton_right__Q5j23{\r\n    float: right;\r\n}\r\n\r\n.SubmitButton_left__uWuIa{\r\n    float: left;\r\n}\r\n ", "",{"version":3,"sources":["webpack://./src/components/form/submitButton/SubmitButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".btn{\r\n    color: #fff;\r\n    background-color: #198754;\r\n    border-color: #198754;\r\n    padding: .7em 1.2em;\r\n    text-decoration: none;\r\n    transition: .5s;\r\n    border-radius: .25rem;\r\n    text-align: center;\r\n    cursor: pointer;\r\n    border: none;\r\n    margin: 1em;\r\n    margin-top: 0;\r\n    margin-bottom: 1em;\r\n}\r\n\r\n.btn:hover{\r\n    background-color: #006335;\r\n    border-color: #006335;\r\n}\r\n\r\n.right{\r\n    float: right;\r\n}\r\n\r\n.left{\r\n    float: left;\r\n}\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "SubmitButton_btn__941jA",
	"right": "SubmitButton_right__Q5j23",
	"left": "SubmitButton_left__uWuIa"
};
export default ___CSS_LOADER_EXPORT___;
