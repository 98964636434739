import React from 'react';
import { VscError, VscDebugStart } from 'react-icons/vsc'
import { AiOutlineWarning, AiFillFrown, AiOutlineLoading3Quarters, AiOutlineCheckCircle } from 'react-icons/ai'
import { TiCancelOutline } from 'react-icons/ti'
import { GrStatusDisabled } from 'react-icons/gr'
import { ImBlocked } from 'react-icons/im';
import { IoAnalyticsOutline } from "react-icons/io5";

function renderIcon(type) {
    switch (type) {
        case 'error':
            return (<VscError />);
        case 'pending':
            return (<AiOutlineWarning />);
        case 'success_warning':
            return (<AiOutlineCheckCircle />);
        case 'reprocessed':
            return (<AiOutlineWarning />);
        case 'complete':
            return (<AiOutlineCheckCircle />);
        case 'started':
            return (<VscDebugStart />);
        case 'rejected':
            return (<AiFillFrown />);
        case 'disabled':
            return (<GrStatusDisabled />);
        case 'wait':
            return (<AiOutlineLoading3Quarters />);
        case 'canceled':
            return (<TiCancelOutline />);
        case 'reproved':
            return (<TiCancelOutline />);
        case 'success_disapproved':
            return (<AiOutlineCheckCircle />);
        case 'blocked':
            return <ImBlocked />;
        case 'analysis':
            return <IoAnalyticsOutline />
        default:
            return (<></>);
    }
}

export default renderIcon;
