import { useEffect, useState } from "react";
import api from "../../../services/api";
import Loading from "../../layout/loading/Loading";
import moment from "moment";
import { checkJwt } from "../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";

const InsuranceContract = ({ item, formatCnpjCpf, openModalEvent }) => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);


    const getIncidents = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT}`,
            {
                params: {
                    code: item?.stages?.code,
                    proposalId: item?.id?.proposalId
                }
            }, {
        }).then((response) => {
            setData(response?.data.insuranceStatusesResponseDto)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null); setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getIncidents();
            }
        })
    }, [])
    return (
        <>
            {loading && <Loading />}
            {!loading && (<>
                <h2>{item?.stages?.description}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Função status
                        </div>
                        <div className="cell cell-tittle">
                            Descrição
                        </div>
                        <div className="cell cell-tittle">
                            Mensagem
                        </div>
                        <div className="cell cell-tittle">
                            Nome
                        </div>
                        <div className="cell cell-tittle">
                            Cpf/Cnpj
                        </div>
                        <div className="cell cell-tittle">
                            Número pólice
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                        <div className="cell cell-tittle">
                            Atualizado em
                        </div>
                    </div>

                    <div className="row" >
                        <div className="cell  cell-text">
                            <strong>Status </strong>
                            {data?.insuranceStatus}
                        </div>
                        <div className="cell cell-text">
                            <strong>Função status </strong>
                            {data?.funcaoStatus}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.message?.description)}>
                            <strong>Descrição </strong>
                            {data?.message?.description}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.message?.descriptionDetails)}>
                            <strong>Mensagem </strong>
                            {data?.message?.descriptionDetails}
                        </div>
                        <div className="cell cell-text" style={{ maxWidth: "200px", overflow: "visible", 'whiteSpace': "normal" }}>
                            <strong>Nome </strong>
                            {data?.insuredName}
                        </div>
                        <div className="cell cell-text">
                            <strong>Cpf/Cnpj </strong>
                            {data?.insuredCpfCnpj && formatCnpjCpf(data?.insuredCpfCnpj)}
                        </div>
                        <div className="cell cell-text">
                            <strong> Número pólice </strong>
                            {data?.policyNumber}
                        </div>
                        <div className="cell cell-text" style={{ maxWidth: "200px", overflow: "visible", 'whiteSpace': "normal" }}>
                            <strong>Criado em </strong>
                            {data?.createdAt && moment(data?.createdAt).format('DD/MM/YYYY HH:mm')}

                        </div>
                        <div className="cell cell-text" style={{ maxWidth: "300px", overflow: "visible", 'whiteSpace': "normal" }}>
                            <strong>Atualizado em </strong>
                            {data?.updatedAt && moment(data?.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>
            </>)}
        </>
    )
}

export default InsuranceContract