import { useEffect, useState } from "react";
import api from "../../../services/api";
import Loading from "../../layout/loading/Loading";
import moment from "moment";
import { checkJwt } from "../../../helpers/Jwt-helper";
import { useNavigate } from "react-router-dom";

const DocumentScoreDataCadastral = ({ item, formatCnpjCpf, openModalEvent }) => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);


    const getIncidents = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT}`,
            {
                params: {
                    code: item?.stages?.code,
                    proposalId: item?.id?.proposalId
                }
            }, {
        }).then((response) => {
            setData(response?.data.clearSaleTransactionIssueDto)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null); setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getIncidents();
            }
        })
    }, [])
    return (
        <>
            {loading && <Loading />}
            {!loading && (<>
                <h2>{item?.stages?.description}</h2>
                <div className="table">
                    <div className="row header">
                        <div className="cell cell-tittle">
                            Tipo
                        </div>
                        <div className="cell cell-tittle">
                            Parceiro
                        </div>
                        <div className="cell cell-tittle">
                            Status
                        </div>
                        <div className="cell cell-tittle">
                            Descrição
                        </div>
                        <div className="cell cell-tittle">
                            Criado em
                        </div>
                    </div>

                    <div className="row">
                        <div className="cell  cell-text">
                            <strong>Tipo </strong>
                            {data?.type}
                        </div>
                        <div className="cell cell-text">
                            <strong>Parceiro </strong>
                            {data?.partner}
                        </div>
                        <div className="cell cell-text">
                            <strong>Status </strong>
                            {data?.status}
                        </div>
                        <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(data?.descriprion)}>
                            <strong>Mensagem </strong>
                            {data?.descriprion}
                        </div>
                        <div className="cell cell-text">
                            <strong>Criado em </strong>
                            {data?.createdAt && moment(data?.createdAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>

                {data && data?.issues && (
                    <>
                        <h2 style={{ fontSize: "20px" }}>{"Issues"}</h2>
                        <div className="table">
                            <div className="row header">
                                <div className="cell cell-tittle">
                                    Nome
                                </div>
                                <div className="cell cell-tittle">
                                    Documento
                                </div>
                                <div className="cell cell-tittle">
                                    Status
                                </div>
                                <div className="cell cell-tittle">
                                    Descrição
                                </div>
                            </div>
                            {data && data?.issues && data?.issues.map((issue, index) => (
                                <div className="row" key={index}>
                                    <div className="cell  cell-text">
                                        <strong>Nome </strong>
                                        {issue?.name}
                                    </div>
                                    <div className="cell cell-text">
                                        <strong>Documento </strong>
                                        {issue?.document && formatCnpjCpf(issue?.document)}
                                    </div>
                                    <div className="cell cell-text" >
                                        <strong>Status </strong>
                                        {issue?.status}
                                    </div>
                                    <div className="cell cell-text" style={{ textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => openModalEvent(issue?.description)}>
                                        <strong>Descrição</strong>
                                        {issue?.description}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </>
                )}

            </>)}
        </>
    )
}

export default DocumentScoreDataCadastral