import styles from './SubmitButton.module.css'

function SubmitButton({text, position}){
    return (
        <div>
            <button className={`${styles.btn}  ${styles[position]}`}> 
                {text}
            </button>
        </div>
    );
}

export default SubmitButton