import { useContext, useEffect, useState } from 'react';
import styles from './Plate.module.css';
import api from '../../../services/api';
import Tab from '../../tab/Tab';
import Loading from '../../layout/loading/Loading';
import { AiFillFilePdf } from 'react-icons/ai';
import { RoleContext } from '../../hooks/HookUseContext';
import stylesModal from '../tableMonitoringModal/ModalMonitoring.module.css';

const Plate = ({ data, stage, proposalData }) => {
    const { roleValue } = useContext(RoleContext);
    const [loading, setLoading] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const [plateData, setPlateData] = useState(null);

    const getBase64Pdf = async (id, name) => {
        localStorage.setItem('n', name);
        viewPDF(id);
    }

    async function viewPDF(id) {
        window.open(`../viewpdf/${id}/PLA/${data?.id}`, '_blank', 'noopener,noreferrer');
    }


    const getPlate = async () => {
        try {
            setLoading(true)
            await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PLATE_INFORMATION}${data?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'
                },
            }).then((response) => {
                setLoading(false);
                setPlateData(response?.data)
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    useEffect(() => {
        getPlate();
    }, [])

    const molicar = () => {
        return (
            <div className={stylesModal.content}>
                <br />
                {plateData && plateData?.molicar ? plateData?.molicar?.map((moli, index) => (
                    renderList(moli?.molicarCode, moli?.description, moli?.value, index)
                )) : 'Nenhum molicar'}
            </div>
        )
    }
    const fipe = () => {
        return (
            <div className={stylesModal.content}>
                {plateData && plateData?.fipe ? plateData?.fipe?.map((fipe, index) => (
                    renderList(fipe?.fipeCode, fipe?.description, fipe?.value, index)
                )) : 'Nenhum fipe'}
            </div>
        )
    }
    function formatCurrency(value) {
        return new Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);
    }

    const renderList = (code, description, value, index) => {
        return (
            <div className={styles.listMolicarAndFipe} key={index}>
                <p><strong>Código:</strong> {code}</p>
                <p><strong>Descrição:</strong> {description}</p>
                <p><strong>Valor:</strong> {value ? formatCurrency(value?.toString()) : 'Valor não definido'}</p>
            </div>
        )
    }
    const stateDebts = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Ipva</p>
                            <strong>{plateData?.stateDebts?.ipva}</strong>
                        </div>
                        <div>
                            <p>Licenciamento</p>
                            <strong>{plateData?.stateDebts?.licensing}</strong>
                        </div>
                        <div>
                            <p>Dpvat</p>
                            <strong>{plateData?.stateDebts?.dpvat}</strong>
                        </div>
                        <div>
                            <p>Multas</p>
                            <strong>{plateData?.stateDebts?.fines}</strong>
                        </div>
                        <div>
                            <p>Multas detran</p>
                            <strong>{plateData?.stateDebts?.detranFines}</strong>
                        </div>
                        <div>
                            <p>Multas cetes</p>
                            <strong>{plateData?.stateDebts?.cetesbFines}</strong>
                        </div>
                        <div>
                            <p>Multas municipais</p>
                            <strong>{plateData?.stateDebts?.municipalFines}</strong>
                        </div>
                        <div>
                            <p>Multas renainf</p>
                            <strong>{plateData?.stateDebts?.renainfFines}</strong>
                        </div>
                        <div>
                            <p>Multas dersa</p>
                            <strong>{plateData?.stateDebts?.dersaFines}</strong>
                        </div>
                        <div>
                            <p>Multas der</p>
                            <strong>{plateData?.stateDebts?.derFines}</strong>
                        </div>
                        <div>
                            <p>Polícia Rodoviária/Multas Federais/Polícia Rodoviária/Multas Federais</p>
                            <strong>{plateData?.stateDebts?.policeHighWayFederalFines}</strong>
                        </div>
                        <div>
                            <p>Data licenciamento</p>
                            <strong>{plateData?.stateDebts?.dateLicensing}</strong>
                        </div>
                        <div>
                            <p>Exercício licenciamento</p>
                            <strong>{plateData?.stateDebts?.exerciseLicensing}</strong>
                        </div>
                    </div>
                </div>
            </div>)
    }
    const plateInformation = () => {
        return (
            <div className={stylesModal.content}>

                <div className={styles.statusInformation}>
                    <div>
                        <p>Status</p>
                        {"APROVADO" === plateData?.policyStatus && <strong style={{ color: "#109010" }}>{plateData?.policyStatus}</strong>}
                        {"REPROVADO" === plateData?.policyStatus && <strong style={{ color: "#ad0026" }}>{plateData?.policyStatus}</strong>}
                        {("PENDENCIADO" === plateData?.policyStatus || "PENDENCIAR" === plateData?.policyStatus) && <strong style={{ color: "	#ffc107" }}>{plateData?.policyStatus}</strong>}
                        {(plateData?.vehicleData?.typeVehicle?.toString().toUpperCase() === "NOVO"
                            ||
                            ("APROVADO" !== plateData?.policyStatus && "REPROVADO" !== plateData?.policyStatus && "PENDENCIADO" !== plateData?.policyStatus && "PENDENCIAR" !== plateData?.policyStatus)) && <strong style={{ color: "	#ffc107" }}>{plateData?.policyStatus?.toString()?.toUpperCase()}</strong>}

                    </div>
                    <div style={{ width: "500px", left: "0" }}>
                        <p>Mensagem</p>
                        {<strong  >{plateData?.messagePolicy?.toUpperCase()}</strong>}
                    </div>
                </div>

                <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Proprietário</p>
                            <strong>{plateData?.owner?.name?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Numero Doc. do Proprietário</p>
                            <strong>{plateData?.owner?.document?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Placa</p>
                            <strong>{plateData?.vehicleData?.plate?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Chassi</p>
                            <strong>{plateData?.vehicleData?.chassi?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Renavam</p>
                            <strong>{plateData?.vehicleData?.renavam?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Modelo</p>
                            <strong>{plateData?.vehicleData?.model?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Ano Modelo</p>
                            <strong>{plateData?.vehicleData?.yearModel?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Ano Fabricação</p>
                            <strong>{plateData?.vehicleData?.yearManufacture?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Cor</p>
                            <strong>{plateData?.vehicleData?.color?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Combustível</p>
                            <strong>{plateData?.vehicleData?.fuel?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Uf</p>
                            <strong>{plateData?.vehicleData?.uf?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Cidade</p>
                            <strong>{plateData?.vehicleData?.city?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Colocação de origem</p>
                            <strong>{plateData?.vehicleData?.originPlacing?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Tipo veículo</p>
                            <strong>{plateData?.vehicleData?.typeVehicle?.toString().toUpperCase()}</strong>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    const impediments = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Situação do veículo</p>
                            <strong>{plateData?.restrictionsImpediments?.situationVehicle?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Roubo</p>
                            <strong>{plateData?.restrictionsImpediments?.theft?.toString().toUpperCase()}</strong>
                        </div>
                    </div>
                </div>
                <h2 className={styles.tittle} style={{ marginTop: "1em" }}>Intenção de Gravame</h2>
                <div className={styles.columnInformation} >
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Número de contrato</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.numberContract?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Cod. agente financeiro</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.financialAgentCode?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Data efetiva do contrato</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.contractEffectiveDate?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Documento lessee</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.documentLessee?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Restrição financeira</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.financialRestriction?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Agente financeiro</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.agentFinance?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Nome financiado</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.nameFunded?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Documento financiado</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.documentFunded?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Data incluída</p>
                            <strong>{plateData?.restrictionsImpediments?.engraveIntention?.includedDate?.toString().toUpperCase()}</strong>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


    const plateDocuments = () => {
        return (
            <div className={stylesModal.content}>
                {roleValue?.includes("DOCUMENT_PLATE_READ") && plateData && plateData?.documentResponseDtos ? plateData?.documentResponseDtos.map((doc, index) => (
                    <div className={styles.actionButtonsPDF} key={doc?.id?.documentType?.id || index}>
                        {!loadingDocument ? (

                            <button onClick={() => getBase64Pdf(doc?.id?.documentType?.id, doc?.name)}>
                                <AiFillFilePdf />
                                {doc?.id?.documentType?.description}
                            </button>
                        ) : <button disabled>Aguarde...</button>}
                    </div>
                )) : 'Nenhum documento'}


            </div>
        )
    }

    const restrictions = () => {
        return (
            <div className={stylesModal.content}>
                {plateData && plateData?.restrictionsImpediments && plateData?.restrictionsImpediments?.restrictions ? plateData?.restrictionsImpediments?.restrictions.map((restriction, index) => (
                    <div key={index} className={styles.list}>
                        <p>Restrição {index + 1}</p>
                        <strong>{restriction}</strong>
                    </div>
                )) : 'Nenhuma restrição'}
            </div>
        )
    }

    const recordsAuctions = () => {
        return (
            <div className={stylesModal.content}>
                {plateData && plateData?.recordsAuction && plateData?.recordsAuction && plateData?.recordsAuction?.length > 0 ? plateData?.recordsAuction.map((record, index) => (
                    <div key={index} className={styles.list}>
                        <p><strong>Leiloeiro:</strong> {record?.auctioneer?.toString().toUpperCase()}</p>
                        <p><strong>Comitente:</strong> {record?.principal?.toString().toUpperCase()}</p>
                        <p><strong>Lote:</strong> {record?.batch?.toString().toUpperCase()}</p>
                        <p><strong>Data leilao:</strong> {record?.dateAuction?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do veiculo:</strong> {record?.conditionsVehicle?.toString().toUpperCase()}</p>
                        <p><strong>Situacao do chassi:</strong> {record?.situationChassis?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do motor:</strong> {record?.conditionsMotor?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do cambio:</strong> {record?.conditionsExchange?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes mecanica:</strong> {record?.conditionsMechanics?.toString().toUpperCase()}</p>
                        <p><strong>observacao:</strong> {record?.observation?.toString().toUpperCase()}</p>
                    </div>
                )) : 'Nenhum registro de leilão'}
            </div>
        )
    }

    const recordsAuctionsBase2 = () => {
        return (
            <div className={stylesModal.content}>
                {plateData && plateData?.recordsLeilaoBase2 && plateData?.recordsLeilaoBase2 && plateData?.recordsLeilaoBase2?.length > 0 ? plateData?.recordsLeilaoBase2.map((record, index) => (
                    <div key={index} className={styles.list}>
                        <p><strong>Leiloeiro:</strong> {record?.auctioneer?.toString().toUpperCase()}</p>
                        <p><strong>Comitente:</strong> {record?.principal?.toString().toUpperCase()}</p>
                        <p><strong>Lote:</strong> {record?.batch?.toString().toUpperCase()}</p>
                        <p><strong>Data leilao:</strong> {record?.dateAuction?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do veiculo:</strong> {record?.conditionsVehicle?.toString().toUpperCase()}</p>
                        <p><strong>Situacao do chassi:</strong> {record?.situationChassis?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do motor:</strong> {record?.conditionsMotor?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do cambio:</strong> {record?.conditionsExchange?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes mecanica:</strong> {record?.conditionsMechanics?.toString().toUpperCase()}</p>
                        <p><strong>observacao:</strong> {record?.observation?.toString().toUpperCase()}</p>

                        <p><strong>Placa:</strong> {record?.plate?.toString().toUpperCase()}</p>
                        <p><strong>Chassi:</strong> {record?.chassi?.toString().toUpperCase()}</p>
                        <p><strong>Cor:</strong> {record?.color?.toString().toUpperCase()}</p>
                    </div>
                )) : 'Nenhum registro de leilão base 2'}
            </div>
        )
    }

    const recordsAuctionsBase3 = () => {
        return (
            <div className={stylesModal.content}>
                {plateData && plateData?.recordsLeilaoBase3 && plateData?.recordsLeilaoBase3 && plateData?.recordsLeilaoBase3?.length > 0 ? plateData?.recordsLeilaoBase3.map((record, index) => (
                    <div key={index} className={styles.list}>
                        <p><strong>Leiloeiro:</strong> {record?.auctioneer?.toString().toUpperCase()}</p>
                        <p><strong>Comitente:</strong> {record?.principal?.toString().toUpperCase()}</p>
                        <p><strong>Lote:</strong> {record?.batch?.toString().toUpperCase()}</p>
                        <p><strong>Data leilao:</strong> {record?.dateAuction?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do veiculo:</strong> {record?.conditionsVehicle?.toString().toUpperCase()}</p>
                        <p><strong>Situacao do chassi:</strong> {record?.situationChassis?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do motor:</strong> {record?.conditionsMotor?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes do cambio:</strong> {record?.conditionsExchange?.toString().toUpperCase()}</p>
                        <p><strong>Condicoes mecanica:</strong> {record?.conditionsMechanics?.toString().toUpperCase()}</p>
                        <p><strong>observacao:</strong> {record?.observation?.toString().toUpperCase()}</p>

                        <p><strong>Placa:</strong> {record?.plate?.toString().toUpperCase()}</p>
                        <p><strong>Chassi:</strong> {record?.chassi?.toString().toUpperCase()}</p>
                        <p><strong>Cor:</strong> {record?.color?.toString().toUpperCase()}</p>
                    </div>
                )) : 'Nenhum registro de leilão base 3'}
            </div>
        )
    }

    const riskCommercial = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Risco</p>
                            <strong>{plateData?.riskCommercial?.risk?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Mensagem</p>
                            <strong>{plateData?.riskCommercial?.message?.toString().toUpperCase()}</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const indications = () => {
        return (
            <div className={stylesModal.content}>
                <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Roubo</p>
                            <strong>{plateData?.alertTheftTheft?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Alerta gravame</p>
                            <strong>{plateData?.alertEncumbrance?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Indicio sinistro</p>
                            <strong>{plateData?.indicationSinister?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Registros remarketing</p>
                            <strong>{plateData?.recordsRemarketing?.toString().toUpperCase()}</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const fieldsFail = () => {
        return (
            <div className={stylesModal.content}>

                <div className={styles.statusInformation}>
                    <div>
                        <p>Status</p>
                        {"APROVADO" === plateData?.policyStatus && <strong style={{ color: "#109010" }}>{plateData?.policyStatus}</strong>}
                        {"REPROVADO" === plateData?.policyStatus && <strong style={{ color: "#ad0026" }}>{plateData?.policyStatus}</strong>}
                        {("PENDENCIADO" === plateData?.policyStatus || "PENDENCIAR" === plateData?.policyStatus) && <strong style={{ color: "	#ffc107" }}>{plateData?.policyStatus}</strong>}
                    </div>
                    <div style={{ width: "500px" }}>
                        <p>Mensagem</p>
                        <strong>{plateData?.fieldsThatFail?.messageDisapprove?.toString().toUpperCase()}</strong>
                    </div>
                </div>

                <h2 className={styles.tittle} style={{ marginTop: "1em" }}>Campos de reprova</h2>
                <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                    <div className={styles.rowsInformationPlate}>
                        <div>
                            <p>Fipe parcial</p>
                            <strong>{plateData?.fieldsThatFail?.fipePartial?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Aceitação seguro</p>
                            <strong>{plateData?.fieldsThatFail?.acceptanceInsurance?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Soma débitos</p>
                            <strong>{plateData?.fieldsThatFail?.sumStateDebts?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Risco</p>
                            <strong>{plateData?.fieldsThatFail?.risk?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Tipo veículo</p>
                            <strong>{plateData?.fieldsThatFail?.typeVehicle?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Situação chassi</p>
                            <strong>{plateData?.fieldsThatFail?.situationChassi?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Categoria</p>
                            <strong>{plateData?.fieldsThatFail?.category?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Alerta de frota</p>
                            <strong>{plateData?.fieldsThatFail?.fleetAlert?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Indicio sinistro</p>
                            <strong>{plateData?.fieldsThatFail?.indicationSinister?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Gravame</p>
                            <strong>{plateData?.fieldsThatFail?.alertRecorde?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Roubo furto</p>
                            <strong>{plateData?.fieldsThatFail?.theft?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Leilao com score remarketing</p>
                            <strong>{plateData?.fieldsThatFail?.score?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Exercício licenciamento</p>
                            <strong>{plateData?.fieldsThatFail?.exerciseLicensing?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Data venda</p>
                            <strong>{plateData?.fieldsThatFail?.dateSale?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Documento</p>
                            <strong>{plateData?.fieldsThatFail?.document?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Documento</p>
                            <strong>{plateData?.fieldsThatFail?.document?.toString().toUpperCase()}</strong>
                        </div>
                        <div>
                            <p>Códigos judiciais</p>
                            <strong>
                                {plateData?.fieldsThatFail?.alertJudicial && plateData.fieldsThatFail.alertJudicial.length > 0 && (
                                    <>
                                        {`[${plateData.fieldsThatFail.alertJudicial.join(", ")}]`}
                                        {plateData.fieldsThatFail.alertJudicial.join(";;")}
                                    </>
                                )}
                            </strong>
                        </div>

                    </div>
                </div>

            </div>

        )
    }
    return (
        <div className={styles.plateContainer}>
            {loading && <Loading />}

            {!loading && (<>
                <Tab
                    tabsName={[
                        'Proposta',
                        'Reprova',
                        'Informações',
                        'Documentos',
                        'Débitos',
                        'Molicar',
                        "Fipe",
                        "Impedimentos",
                        "Restrições",
                        "Risco Comercial",
                        "Indicios",
                        "Registros leilão"

                    ]}
                    tabsInfo={[
                        <>{proposalData()}</>,
                        <>{fieldsFail()}</>,
                        <>{plateInformation()}</>,
                        <>{plateDocuments()}</>,
                        <>{stateDebts()}</>,
                        <>{molicar()}</>,
                        <>{fipe()}</>,
                        <>{impediments()}</>,
                        <>{restrictions()}</>,
                        <>{riskCommercial()}</>,
                        <>{indications()}</>,
                        <>
                            <Tab
                                tabsName={[
                                    "Registro Leilão",
                                    "Registro Leilão Base 2",
                                    "Registro Leilão Base 3"
                                ]}
                                tabsInfo={[
                                    <>{recordsAuctions()}</>,
                                    <>{recordsAuctionsBase2()}</>,
                                    <>{recordsAuctionsBase3()}</>
                                ]}
                            />
                        </>
                    ]}
                    customClass={'menuClass'}
                />
            </>)}
        </div>
    )
}

export default Plate