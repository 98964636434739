import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import styles from './ModalConfirmDeleteDocument.module.css';
import { CiWarning } from 'react-icons/ci';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ModalConfirmDeleteDocument = ({ setOpenModal, documentId, getDocumentsRegularize, dataProposalStages }) => {
    const [open, setOpen] = useState(true);

    const closeModal = () => {
        setOpen(false);
        setOpenModal(false);
    };

    const handleDelete = () => {
        updateDocument();
    };

    async function updateDocument() {
        await api.delete(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_DIGITAL_SIGNATURE_REGULARIZE_DOCUMENT_DELETE}${documentId}`, {
        }).then((response) => {
            toast.success(`Documento Deletado!`);
            getDocumentsRegularize(dataProposalStages?.id?.proposalId);
            closeModal();
        }).catch((error) => {
            console.log(error)
            toast.error(`Houve um erro para deletar o documento!`);
        })
    }

    return (
        <Modal open={open} center onClose={closeModal}>
            <div className={styles.modalContainer}>
                <CiWarning className={styles.warningIcon} />
                <span>Você deseja deletar esse documento?</span>
                <div className={styles.buttonContainer}>
                    <button className={styles.deleteButton} onClick={handleDelete}>
                        Deletar
                    </button>
                    <button className={styles.cancelButton} onClick={closeModal}>
                        Não deletar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirmDeleteDocument;
