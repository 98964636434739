import { checkJwt } from "../../../../../helpers/Jwt-helper";
import api from "../../../../../services/api";
import { getCompaniesList } from "../../tableCompany/tableCompany/services/companyService";
import { toast } from "react-toastify";

export const getCompaniesListToStore = async (setData, setLoading) => {
  getCompaniesList(setData, setLoading);
};

export const handleSearch = (
  setDataFilter,
  filters,
  setCurrentPage,
  navigate
) => {
  checkJwt(navigate).then((response) => {
    setDataFilter({ filters });
    setCurrentPage(0);
  });
};

export const handleInputChange = (e, setFilters, filters) => {
  const { name, value } = e.target;
  setFilters({
    ...filters,
    [name]: value,
  });
};
export const handleCurrentPage = (page, setCurrentPage, navigate) => {
  checkJwt(navigate).then((response) => {
    if (response) {
      setCurrentPage(page);
    }
  });
};

export const getAuth = async (page, username, active, setData, setLoading) => {
  try {
    setData([]);
    setLoading(true);
    await api
      .get(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${
          process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS_PAGE
        }?page=${page}&size=${10}`,
        {
          params: {
            username,
            active,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setData([]);
        console.log(error);
      });
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const activeOrDesactive = async (
  setData,
  currentData,
  data,
  active,
  setSelected
) => {
  try {
    await api
      .put(
        `${
          process.env.REACT_APP_MONITORING_BASE_URL
        }${process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS_ACTIVATE_OR_DESACTIVATE.replace(
          "{id}",
          currentData?.id
        ).replace("{active}", active)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        console.log(data);
        const updatedList = getListUpdated(response, data);
        setSelected(active);
        setData(updatedList);
      })
      .catch((error) => {
        console.log(error);
        setSelected(!active);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
    setSelected(!active);
  }
};

export const updateAuth = async (setData, data, formData, currentData) => {
  try {
    const promises = [];

    if (formData.formData?.username) {
      promises.push(
        updateUserOrPasswordAuth(
          process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS_UPDATE_USER,
          currentData,
          formData,
          data,
          setData
        )
      );
    }

    if (formData?.password) {
      promises.push(
        updateUserOrPasswordAuth(
          process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS_UPDATE_PASSWORD,
          currentData,
          formData,
          data,
          setData
        )
      );
    }

    await Promise.all(promises);

    toast.success("Atualizado");
  } catch (error) {
    console.log(error);
    handleError(error);
  }
};

const updateUserOrPasswordAuth = async (
  path,
  currentData,
  formData,
  data,
  setData
) => {
  const response = await api.patch(
    `${process.env.REACT_APP_MONITORING_BASE_URL}${path}`,
    {
      id: currentData?.id,
      username: formData?.username,
      password: formData?.password,
      userId: "",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );

  const updatedList = getListUpdated(response, data);
  setData(updatedList);
};

export const createAuth = async (
  formData,
  closedModal,
  getAuth,
  setData,
  setLoading
) => {
  try {
    await api
      .post(
        `${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PARTNER_NOTIFIER_USER_AUTHS_CREATE}`,
        {
          username: formData?.username,
          password: formData?.password,
          userId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => {
        getAuth(0, null, null, setData, setLoading);
        closedModal();
        toast.success("Criado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const fieldsValidation = (formData, setErrors) => {
  const newErrors = {};
  if (!formData?.username) newErrors.username = true;
  if (!formData?.password) newErrors.password = true;
  if (!formData?.confirmPassword) newErrors.confirmPassword = true;

  if (formData?.password !== formData?.confirmPassword) {
    newErrors.password = true;
    newErrors.confirmPassword = true;
    toast.warning("Senhas devem ser iguais");
  }

  setErrors(newErrors);

  return newErrors;
};

function getListUpdated(response, data) {
  const updatedObject = response?.data;
  console.log(response?.data);
  const updatedList = data?.content?.map((item) => {
    if (item.id === updatedObject.id) {
      return updatedObject;
    }
    return item;
  });
  return {
    ...data,
    content: updatedList,
  };
}

const handleError = (error) => {
  if (error.response && error.response.data) {
    let errorMessage = error?.response?.data?.message;

    if (!errorMessage) {
      errorMessage = error?.response?.data;
      console.log(error?.response?.data);
    }
    toast.error(`
              ${errorMessage}
          `);
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error(error?.response?.data);
    console.error("Erro desconhecido:", error);
  }
};
