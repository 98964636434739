import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import styles from './ModalRegularizeInsuranceContract.module.css';
import TableRegularizeInsuranceContract from './tableRegularizeInsuranceContract/TableRegularizeInsuranceContract';
import api from '../../../../services/api';
import Loading from '../../../layout/loading/Loading';
import { checkJwt } from '../../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ModalRegularizeInsuranceContract = ({ setOpenModal, dataProposalStages }) => {
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingReprocess, setLoadingReprocess] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }

    async function getAllInsuranceContractingProcess(id) {
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_INSURANCE_CONTRACTING_PROCESS}${id}`, {
        }).then((response) => {
            setData(response?.data);
            setLoading(false);
        }).catch((error) => {
            setData(null);
            setLoading(false);
        })
    }

    async function reprocess(id) {
        setLoadingReprocess(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_INSURANCE_CONTRACTING_PROCESS_REPROCESS}${id}`, {
        }).then((response) => {
            setData(response?.data);
            setLoadingReprocess(false);
            getAllInsuranceContractingProcess(id);
            toast.success(response.data);
        }).catch((error) => {
            setData(null);
            toast.error(error?.response?.data?.message || error?.response?.data);
            setLoadingReprocess(false);
            getAllInsuranceContractingProcess(id);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getAllInsuranceContractingProcess(dataProposalStages?.id?.proposalId);
            }
        })
    }, [])

    return (
        <Modal open={open} center onClose={closedModal}>
            {loading && <Loading />}

            {!loading && (
                <div className={styles.container}>
                    <h2>Regularização da contratação do Seguro</h2>
                    <div className={`contentModal `}>
                        {data && Array.isArray(data?.contractingProcessDtos) ? (
                            <TableRegularizeInsuranceContract data={data?.contractingProcessDtos} />
                        ) : "Nenhum dado"}
                    </div>
                    <div className={styles.message}>
                        {data?.warningMessage}
                    </div>
                    {!loadingReprocess && data && data?.canRegularize ? (
                        <button onClick={() => {
                            checkJwt(navigate).then((response) => {
                                if (response) {
                                    reprocess(dataProposalStages?.id?.proposalId);
                                }
                            })
                        }}>Regularizar</button>
                    ) : <>
                        {loadingReprocess ? (
                            <button disabled>Aguarde...</button>
                        ) : <button disabled>Regularizar</button>}

                    </>
                    }

                </div>
            )}

        </Modal >
    )
}

export default ModalRegularizeInsuranceContract