import styles from './Login.module.css';
import Input from '../../form/input/Input';
import { useEffect, useState } from 'react';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { useNavigate } from 'react-router-dom'
import Message from '../../layout/Message/Message'
import loginAxios from '../../../services/loginAxios';
import { jwtDecrypt } from '../../../helpers/Jwt-helper'
import { checkJwt } from '../../../helpers/Jwt-helper';
import jwt_decode from "jwt-decode";


// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';

function Login() {
  const { setRoleValue } = useContext(RoleContext);
  const history = useNavigate();
  const [login, setLogin] = useState({});
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    checkJwt(history).then((response) => {
      if (response) history("/monitoring");
    })
  }, [history])

  async function getRoleWithFunctinalitiesAndTokenFromApplication() {
    try {
      const bearer_token = `Bearer ${localStorage.getItem('token')}`;

      if (!localStorage.getItem('token')) return;

      const config = {
        headers: {
          Authorization: bearer_token,
          "Access-Control-Allow-Origin": "*",
          "system": process.env.REACT_APP_SYSTEM_ID
        }
      };
      await loginAxios.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_AUTH_TOKEN_DERIVE}`, config
      ).then((response) => {
        const { permissions } = jwt_decode(response?.data.access_token);
        setRoleValue(permissions);
        localStorage.setItem("app:token-auth", response?.data.access_token);
        history('/monitoring')
      })
        .catch((error) => {
          console.log(error)
          localStorage.removeItem("app:token-auth")
          setMessage(`${error.message}`);
          removeLocalStorageInfoAll();
          setShowMessage(true)
          setLogin('')
        })
    } catch (error) {
      console.log(error)
      console.log(error.response)
      localStorage.removeItem("app:token-auth")
      setMessage(`${error.message}`);
      removeLocalStorageInfoAll();
      setShowMessage(true)
      setLogin('')
    }
  }
  const submit = (event) => {
    event.preventDefault();
    let data = new URLSearchParams({
      client_id: process.env.REACT_APP_CLOAK_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLOAK_CLIENT_SECRET,
      grant_type: "password",
      username: login.login,
      password: login.password,
    }).toString();
    loginPost(data);

  }

  function handleChange(event) {
    setLogin({ ...login, [event.target.name]: event.target.value })
  }

  async function loginPost(data) {
    await loginAxios.post(`${process.env.REACT_APP_CLOAK_PATH_BASE}${process.env.REACT_APP_CLOAK_PATH_LOGIN}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) => {
      if (response.data && response.data.access_token) {
        setLocalStorageKeycloackInfo(response);
        const { email, name } = jwtDecrypt();
        localStorage.setItem("user", JSON.stringify({ email, name }));
        getRoleWithFunctinalitiesAndTokenFromApplication()
      }
    })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          setMessage("Login ou Senha Inválidos.");
        }
        else {
          setMessage(`${error.message}`);
        }
        removeLocalStorageInfoAll();
        setShowMessage(true)
        setLogin('')
      })
  }

  function setLocalStorageKeycloackInfo(response) {
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("expires_in", response.data.expires_in);
    localStorage.setItem("refresh_token", response.data.refresh_token);
  }
  function removeLocalStorageInfoAll() {
    localStorage.clear();
  }
  return (
    <div className={styles.loginContainer}>
      <div className={styles.card}>
        {showMessage && !login && (
          <Message type="error" message={message} />
        )}
        <form onSubmit={submit}>
          <Input type="text" text="Login" name="login" placeholder="Digite seu login" handleOnChange={handleChange} value={login.login ? login.login : ''} />
          <Input type="password" text="Senha" name="password" placeholder="Digite sua senha" handleOnChange={handleChange} value={login.password ? login.password : ''} />
          <SubmitButton text="Entrar" position="right" />
        </form>
      </div>
    </div>
  );
}

export default Login;