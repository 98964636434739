import Modal from 'react-responsive-modal';
import styles from './ModalPartnerInformation.module.css';
import { useEffect, useState } from 'react';
import moment from "moment";
import api from '../../../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { checkJwt } from '../../../../../../helpers/Jwt-helper';
import Loading from '../../../../../layout/loading/Loading';

const ModalPartnerInformation = ({ setOpenModal, item }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setOpen(false);
        setOpenModal(false);
    };

    const getDetailPartner = async () => {
        setLoading(true);
        await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_INCIDENTS_INCIDENT_PARTNER_NOTIFIER_DETAIL}${item?.proposalCode}`, {
        }).then((response) => {
            setData(response?.data)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setData(null);
            setLoading(false);
        })
    }

    useEffect(() => {
        checkJwt(navigate).then((response) => {
            if (response) {
                getDetailPartner();
            }
        })
    }, [])
    return (

        <Modal open={open} center onClose={closeModal}>
            <div className={`${styles.modalPartnerInformationContainer}`}>
                {loading && <Loading />}
                {!loading && (
                    <div>
                        <h2>Informações Detalhadas</h2>
                        <div className={styles.columnInformation} style={{ marginTop: "1em" }}>
                            <div className={styles.rowsInformation}>
                                <div>
                                    <p>Proposta</p>
                                    <strong>{data?.proposalCode}</strong>
                                </div>
                                <div>
                                    <p>Tentativas</p>
                                    <strong>{data?.attempts}</strong>
                                </div>
                                <div>
                                    <p>Data</p>
                                    <strong>{data?.date && moment(data?.date).format('DD/MM/YYYY HH:mm')}</strong>
                                </div>
                                <div>
                                    <p>Login</p>
                                    <strong>{data?.login}</strong>
                                </div>
                                <div>
                                    <p>Código de operação</p>
                                    <strong>{data?.operationCode}</strong>
                                </div>
                                <div>
                                    <p>Origem código quatro</p>
                                    <strong>{data?.originFourCode}</strong>
                                </div>
                                <div>
                                    <p>Status</p>
                                    <strong>{data?.status}</strong>
                                </div>
                                <div>
                                    <p>Processo</p>
                                    <strong>{data?.processStepActivityNumber?.processStep?.description}</strong>
                                </div>
                                <div>
                                    <p>Data da última atividade</p>
                                    <strong>{data?.dateTimeLastActivity && moment(data?.dateTimeLastActivity).format('DD/MM/YYYY HH:mm')}</strong>
                                </div>
                                <div>
                                    <p>Criado em</p>
                                    <strong>{data?.createdAt && moment(data?.createdAt).format('DD/MM/YYYY HH:mm')}</strong>
                                </div>
                                <div>
                                    <p>Atualizado em</p>
                                    <strong>{data?.updatedAt && moment(data?.updatedAt).format('DD/MM/YYYY HH:mm')}</strong>
                                </div>
                                <div>
                                    <p>Enviado ao Parceiro</p>
                                    <strong>{data?.sentPartner === true ? "Sim" : "Não"}</strong>
                                </div>
                                <div>
                                    <p>Nome cliente</p>
                                    <strong>{data?.clientName}</strong>
                                </div>
                                <div>
                                    <p>Número de operação</p>
                                    <strong>{data?.operationNumber}</strong>
                                </div>
                                <div>
                                    <p>Valor de lançamento</p>
                                    <strong>{data?.releaseValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                </div>
                                <div>
                                    <p>Status chamada esteira</p>
                                    <strong>{data?.statusCallTreadmill}</strong>
                                </div>
                                <div>
                                    <p>Ação da esteira</p>
                                    <strong>{data?.treadmillAction}</strong>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "1em" }}>
                            <p>Feedback</p>
                            <strong>{data?.feedback}</strong>
                        </div>
                    </div>
                )}
            </div>
        </Modal>

    )
}

export default ModalPartnerInformation