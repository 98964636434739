import styles from './PageNotFound.module.css'

function PageNotFound(){
    return (
        <div className={styles.pageNotFoundContainer}>
            <h1>404</h1>
            <h2>Página não encontrada</h2>
            <p><a href="/monitoring">Voltar para a página inicial</a></p>
        </div>
    )
}

export default PageNotFound;