import styles from './TableMonitoring.module.css';
import { useContext, useState } from 'react';
import ModalMonitoring from '../../project/tableMonitoringModal/ModalMonitoring';
import Pagination from '../../layout/pagination/Pagination';
import moment from 'moment';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom';
import Workflow from './workflow/Workflow';
import AllDocumentsModal from '../allDocumentsModal/AllDocumentsModal';
import { RoleContext } from '../../hooks/HookUseContext';
import renderIcon from '../../../helpers/IconHelper';
import { AiOutlineEye } from 'react-icons/ai';

function TableMonitoring({ object, handleCurrentPage, currentPage, handleUpdateTable }) {
    const navigate = useNavigate();
    const { roleValue } = useContext(RoleContext);
    //#region função para ordenação da tabela
    function sortTable(n) {
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("tableMonitoring");
        switching = true;
        dir = "asc";
        while (switching) {
            switching = false;
            rows = table.rows;
            for (i = 1; i < (rows.length - 1); i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                if (dir === "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                switchcount++;
            } else {
                if (switchcount === 0 && dir === "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }
    //#endregion


    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAllDocuments, setModalOpenAllDocuments] = useState(false);
    const [modalData, setModalData] = useState();
    const [stage, setCurrentStage] = useState('')
    const [stageCode, setCurrentStageCode] = useState('')
    const [currentStatus, setCurrentStatus] = useState();

    let PageSize = object?.size;
    let currentTableData = object?.content || [];

    const updateTable = (response) => {
        handleUpdateTable();
    }

    function openModalEvent(data, stage, stageCode, statusCode) {
        setModalData(data);
        setCurrentStage(stage)
        setCurrentStageCode(stageCode)
        setCurrentStatus(statusCode);
        setModalOpen(true);
    }

    function openModalEventAllDocuments(data) {
        setModalData(data);
        setModalOpenAllDocuments(true);
    }


    return (
        <div className={styles.divContainer}>
            {modalOpen === true && <ModalMonitoring setOpenModal={setModalOpen} currentData={modalData} setModalData={setModalData} renderIcon={renderIcon} updateTable={updateTable} stage={stage} stageCode={stageCode} currentStatus={currentStatus}/>}
            {modalOpenAllDocuments === true && <AllDocumentsModal setOpenModal={setModalOpenAllDocuments} data={modalData} />}
            <table className={styles.tableMonitoringContainer} key={"tableMonitoring"} id={"tableMonitoring"}>
                <thead>
                    <tr>
                        <th onClick={() => sortTable(0)} >Proposta</th>
                        <th onClick={() => sortTable(1)}>Etapa</th>
                        <th onClick={() => sortTable(2)}>Workflow</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentTableData && currentTableData?.length === 0 && (
                        <tr className={styles.noData}>
                            <td>Não há dados</td>
                        </tr>
                    )}
                    {currentTableData && currentTableData?.length > 0 && (
                        currentTableData.map((data) => (
                            <tr key={data.id}>
                                <td>
                                    <strong>Proposta </strong>
                                    {data?.data.proposalCode}
                                </td>
                                <td>
                                    <strong>Etapa </strong>
                                    {data?.proposalStages?.map((work) => (
                                        <p key={work?.stages?.id}>
                                            {work?.active && work.stages.description}
                                        </p>
                                    ))}
                                </td>
                                <td >
                                    <strong>Worflow</strong>
                                    <div className={styles.workflow}>
                                        <Workflow data={data} styles={styles} renderIcon={renderIcon} navigate={navigate} openModalEvent={openModalEvent} />
                                    </div>
                                </td>
                                <td>
                                    {(
                                        roleValue?.includes("DOCUMENT_READ") ||
                                        roleValue?.includes("DOCUMENT_SPECIFIC_READ") ||
                                        roleValue?.includes("DOCUMENT_CLIENT_READ") ||
                                        roleValue?.includes("DOCUMENT_PLATE_READ") ||
                                        roleValue?.includes("DOCUMENT_INSPECTION_READ")
                                    ) && (

                                            <div className='tooltip'>
                                                <p onClick={() => openModalEventAllDocuments(data)}>
                                                    <AiOutlineEye />
                                                </p>
                                                <span className={`tooltiptext `}>Visualizar todos os documentos</span>
                                            </div>
                                        )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <Pagination
                className={styles.pagination_bar}
                currentPage={currentPage === 0 ? 1 : currentPage + 1}
                totalCount={object.totalElements ? object.totalElements : []}
                pageSize={PageSize ? PageSize : 1}
                onPageChange={page => handleCurrentPage(page - 1)}
            />
        </div>
    );
}

export default TableMonitoring