import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import styles from "./CreateAuth.module.css";
import { formatCnpjCpf } from "../../../../../../helpers/Format";
import {
  createAuth,
  fieldsValidation,
  getCompaniesListToStore,
} from "../../services/authService";
import Loading from "../../../../../layout/loading/Loading";

const CreateAuth = ({ setOpenModal, getAuth, setData }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  function closedModal() {
    setOpen(false);
    setOpenModal(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = fieldsValidation(formData, setErrors);

    if (Object.keys(newErrors).length === 0) {
      createAuth(formData, closedModal, getAuth, setData, setLoading);
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={closedModal}
      showCloseIcon={false}
      classNames={{
        modal: `${styles.modalContainer}`,
      }}
    >
      {loading && <Loading />}
      {!loading && (
        <div className={styles.modalEditContainer}>
          <h2 className={styles.modalTitle}>Criar Auth</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.username ? styles.error : ""
                }`}
                maxLength={50}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Senha
              </label>
              <input
                type="password"
                id="username"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.password ? styles.error : ""
                }`}
                maxLength={50}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Confirme sua senha
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`${styles.input} ${
                  errors.confirmPassword ? styles.error : ""
                }`}
                maxLength={50}
              />
            </div>

            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={closedModal}
              >
                Cancelar
              </button>
              <button type="submit" className={styles.submitButton}>
                Criar
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default CreateAuth;
