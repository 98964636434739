import React, { useState } from 'react'
import { IoReload } from "react-icons/io5";

const ReloadStages = ({ item, updatedProposalStage }) => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {!loading ? (
                <IoReload onClick={() => {
                    setLoading(true);
                    updatedProposalStage(item?.id?.proposalId, item?.stages?.code, setLoading);
                }} />
            ) : ''}
        </>
    )
}

export default ReloadStages