import styles from './Monitoring.module.css';
//import { AiFillFilePdf } from "react-icons/ai";
import InputFilter from '../../form/InputFilter/InputFilter';
import SelectFilter from '../../form/SelectFilter/SelectFilter';
import { useEffect, useState } from 'react';
import TableMonitoring from '../../project/tableMonitoring/TableMonitoring';
import Container from '../../layout/container/Container';
import Loading from "../../layout/loading/Loading";
import { AiOutlineSearch, AiFillFrown, AiOutlineWarning, AiOutlineCheckCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { VscError, VscDebugStart } from 'react-icons/vsc';
import { GrStatusDisabled } from 'react-icons/gr';
import { ImBlocked } from 'react-icons/im';
import { TiCancelOutline } from 'react-icons/ti';
import api from '../../../services/api';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';
import { IoAnalyticsOutline } from 'react-icons/io5';
function Monitoring() {
    const navigate = useNavigate();
    const [filterMonitoring, setFilterMonitoring] = useState({});
    const [removeLoading, setRemoveLoading] = useState(false);
    const [dataTable, setDataTable] = useState([])
    const [currentPage, setCurrentPage] = useState(0);

    const [listProducts, setListProducts] = useState([]);
    const [listOrigin] = useState([{ id: "CASSIOPAE", name: "Cassiopae" }, { id: "FUNCAO", name: "Função" }]);
    const [listStages, setListStages] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [listProductTypes, setListProductTypes] = useState([]);
    const [filteredData, setFilteredData] = useState('');

    const MINUTE_MS = process.env.REACT_APP_RELOAD_TABLE * 1000 * 60;

    const daysToFilter = process.env.REACT_APP_DAYS_TO_FILTER;

    const currentDate = new Date(Date.now());
    const pastDate = new Date(Date.now());

    pastDate.setDate(currentDate.getDate() - daysToFilter || 3);

    useEffect(() => {
        async function getProposals() {
            try {
                setRemoveLoading(false);
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL}?page=${currentPage}&size=${10}`,
                    {
                        params: {
                            "stageId": filteredData?.stageId,
                            "proposalNumber": filteredData?.proposalNumber || null,
                            "contractNumber": filteredData?.contractNumber || null,
                            "productId": filteredData?.productId,
                            "statusId": filteredData?.statusId,
                            "productTypeId": filterMonitoring?.productType?.id,
                            "cpfCnpj": filteredData?.cpfCnpj || null,
                            "dataInicio": filteredData?.dataInicio ? filteredData?.dataInicio : moment(pastDate).format('DD/MM/YYYY'),
                            "dataFim": filteredData?.dataFim ? filteredData?.dataFim : moment(currentDate).format('DD/MM/YYYY'),
                            "dataOrigin": filteredData?.dataOrigin
                        }
                    }, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'

                    },
                }).then((response) => {
                    setDataTable(response.data);
                    setRemoveLoading(true);
                })
                    .catch((error) => {
                        setDataTable([])
                        console.log(error)
                        setRemoveLoading(true)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }
        const interval = setInterval(() => {
            getProposals();
        }, MINUTE_MS);

        getProposals();
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [MINUTE_MS, filteredData])

    useEffect(() => {
        async function getProducts() {
            try {
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PRODUCTS}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    let optionsProducts = [];
                    for (let i = 0; i < response?.data?.content.length; i++) {
                        optionsProducts.push(
                            {
                                id: response?.data?.content[i].id,
                                name: response?.data?.content[i].description
                            }
                        )
                    }
                    setListProducts(optionsProducts)
                })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        async function getStages() {
            try {
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_STAGES}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    let optionsStages = [];
                    for (let i = 0; i < response?.data?.content.length; i++) {
                        optionsStages.push(
                            {
                                id: response?.data?.content[i].id,
                                name: response?.data?.content[i].description
                            }
                        )
                    }
                    setListStages(optionsStages)
                })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        async function getStatus() {
            try {
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_STATUS}?page=${currentPage}&size=${50}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    let optionsStatus = [];
                    for (let i = 0; i < response?.data?.content.length; i++) {
                        optionsStatus.push(
                            {
                                id: response?.data?.content[i].id,
                                name: response?.data?.content[i].name
                            }
                        )
                    }
                    setListStatus(optionsStatus)
                })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        async function getProductTypes() {
            try {
                await api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_PRODUCT_TYPES}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    let optionsProductTypes = [];
                    for (let i = 0; i < response?.data?.length; i++) {
                        optionsProductTypes.push(
                            {
                                id: response?.data[i].id,
                                name: response?.data[i].description
                            }
                        )
                    }
                    setListProductTypes(optionsProductTypes)
                })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }
        getStatus();
        getStages();
        getProducts();
        getProductTypes();
    }, [])

    const updateTable = () => {
        checkJwt(navigate).then((response) => {
            if (response) {
                setRemoveLoading(false);
                api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL}?page=${currentPage}&size=${10}`,
                    {
                        params: {
                            "stageId": filteredData?.stageId,
                            "proposalNumber": filteredData?.proposalNumber || null,
                            "contractNumber": filteredData?.contractNumber || null,
                            "productId": filteredData?.productId,
                            "statusId": filteredData?.statusId,
                            "productTypeId": filterMonitoring?.productType?.id,
                            "cpfCnpj": filteredData?.cpfCnpj || null,
                            "dataInicio": filteredData?.dataInicio ? filteredData?.dataInicio : moment(pastDate).format('DD/MM/YYYY'),
                            "dataFim": filteredData?.dataFim ? filteredData?.dataFim : moment(currentDate).format('DD/MM/YYYY'),
                            "dataOrigin": filteredData?.dataOrigin
                        }
                    }, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'

                    },
                }).then((response) => {
                    setDataTable(response.data);
                    setRemoveLoading(true);
                })
                    .catch((error) => {
                        setDataTable([])
                        console.log(error)
                        setRemoveLoading(true)
                    })
            }
        })

    }

    const submit = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        checkJwt(navigate).then((response) => {
            if (response) {
                handleSubmit();
            }
        })
    }

    const handleCurrentPage = (page) => {
        checkJwt(navigate).then((response) => {
            if (response) {
                setCurrentPage(page);
                setRemoveLoading(false);
                api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_PROPOSAL}?page=${page}&size=${10}`,
                    {
                        params: {
                            "stageId": filteredData?.stageId,
                            "proposalNumber": filteredData?.proposalNumber || null,
                            "contractNumber": filteredData?.contractNumber || null,
                            "productId": filteredData?.productId,
                            "statusId": filteredData?.statusId,
                            "productTypeId": filterMonitoring?.productType?.id,
                            "cpfCnpj": filteredData?.cpfCnpj || null,
                            "dataInicio": filteredData?.dataInicio ? filteredData?.dataInicio : moment(pastDate).format('DD/MM/YYYY'),
                            "dataFim": filteredData?.dataFim ? filteredData?.dataFim : moment(currentDate).format('DD/MM/YYYY'),
                            "dataOrigin": filteredData?.dataOrigin
                        }
                    }, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'

                    },
                }).then((response) => {
                    setDataTable(response.data);
                    setRemoveLoading(true);
                }).catch((error) => {
                    setDataTable([])
                    console.log(error)
                    setRemoveLoading(true)
                })
            }
        })
    }
    function handleSelectStage(event) {
        setFilterMonitoring({
            ...filterMonitoring, stage: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }
    function handleSelectProduct(event) {
        setFilterMonitoring({
            ...filterMonitoring, product: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }
    function handleSelectStatus(event) {
        setFilterMonitoring({
            ...filterMonitoring, status: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }
    function handleSelectProductType(event) {
        setFilterMonitoring({
            ...filterMonitoring, productType: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }

    function handleSelectOrigin(event) {
        setFilterMonitoring({
            ...filterMonitoring, dataOrigin: {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            }
        })
    }

    function handleChange(event) {
        setFilterMonitoring({ ...filterMonitoring, [event.target.name]: event.target.value })
    }

    function handleSubmit() {

        let periodOf;
        let periodTo;

        if (filterMonitoring?.periodOf) {
            periodOf = moment(filterMonitoring?.periodOf).format('DD/MM/YYYY');
        }
        if (filterMonitoring?.periodTo) {
            periodTo = moment(filterMonitoring?.periodTo).format('DD/MM/YYYY');
        }
        let data = {
            "stageId": filterMonitoring?.stage?.id,
            "proposalNumber": filterMonitoring?.proposalNumber,
            "contractNumber": filterMonitoring?.contractNumber,
            "productId": filterMonitoring?.product?.id,
            "statusId": filterMonitoring?.status?.id,
            "productTypeId": filterMonitoring?.productType?.id,
            "cpfCnpj": onlyNumbers(filterMonitoring?.cpf_cnpj),
            "dataInicio": periodOf,
            "dataFim": periodTo,
            "dataOrigin": filterMonitoring?.dataOrigin?.id
        }
        setFilteredData(data);
    }
    function onlyNumbers(valor) {
        if (valor == null) return null;
        return valor.toString()
            .replaceAll(".", "")
            .replaceAll("-", "");
    }
    return (
        <div className={styles.monitoringContainer}>
            <div className={`${styles.card} ${styles.column}`}  >
                <h2>Filtro</h2>
                <div className={styles.filter}>
                    <form onSubmit={submit}>
                        <SelectFilter text={"Etapa"} name={"stage"} options={listStages} handleOnChange={handleSelectStage} value={filterMonitoring?.stage?.id || ''} textDefault={"Todos"} />
                        <InputFilter type="text" name={"proposalNumber"} text="Num. da proposta" handleOnChange={handleChange} required={false} />
                        <InputFilter type="text" name={"contractNumber"} text="Num. do contrato" handleOnChange={handleChange} required={false} />
                        <SelectFilter text={"Status"} name={"status"} options={listStatus} handleOnChange={handleSelectStatus} value={filterMonitoring?.status?.id || ''} textDefault={"Todos"} />
                        <SelectFilter text={"Tipo de produto"} name={"productType"} options={listProductTypes} handleOnChange={handleSelectProductType} value={filterMonitoring?.productType?.id || ''} textDefault={"Todos"} />
                        <InputFilter type="text" name={"cpf_cnpj"} text="CPF/CNPJ" handleOnChange={handleChange} required={false} maskType="cpf_cnpj" />
                        <InputFilter type="date" name={"periodOf"} text="Periodo (de)" handleOnChange={handleChange} value={filterMonitoring?.periodOf || new Date(pastDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />
                        <InputFilter type="date" name={"periodTo"} text="Periodo (até)" handleOnChange={handleChange} value={filterMonitoring?.periodTo || new Date(currentDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />
                        <button>
                            <AiOutlineSearch />
                            Buscar
                        </button>
                    </form>
                </div>

                <h3>Informações dos icones</h3>
                <div className={styles.iconsMeanings}>
                    <div className={styles.error}>
                        Error <VscError />
                    </div>
                    <div className={styles.pending}>
                        Aviso/Reprocessado <AiOutlineWarning />
                    </div>
                    <div className={styles.success}>
                        Sucesso <AiOutlineCheckCircle />
                    </div>
                    <div className={styles.initiated}>
                        Iniciado <VscDebugStart />
                    </div>
                    <div className={styles.rejected}>
                        Rejeitado <AiFillFrown />
                    </div>
                    <div className={styles.disabled}>
                        Desabilitado<GrStatusDisabled />
                    </div>
                    <div className={styles.wait}>
                        Aguardando <AiOutlineLoading3Quarters />
                    </div>
                    <div className={styles.blocked}>
                        Bloqueado <ImBlocked />
                    </div>
                    <div className={styles.canceled}>
                        Cancelado/Reprovado <TiCancelOutline />
                    </div>
                    <div className={styles.analysis}>
                        Em análise <IoAnalyticsOutline />
                    </div>
                    <div className={styles.success_disapproved}>
                        Sucesso reprovado <AiOutlineCheckCircle />
                    </div>
                    <div className={styles.success_warning}>
                        Sucesso alerta <AiOutlineCheckCircle />
                    </div>
                </div>
            </div>
            <div className={styles.card}>
                <h2>Monitoramento</h2>
                {!removeLoading && <Loading />}
                <Container>
                    {removeLoading && dataTable && (
                        <TableMonitoring object={dataTable} handleCurrentPage={handleCurrentPage} currentPage={currentPage} handleUpdateTable={updateTable} />
                    )}
                </Container>
            </div>
        </div>
    )
}

export default Monitoring;