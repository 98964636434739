// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_loginContainer__6Iu2f{\r\n    width: 100%;\r\n    display: flex;\r\n    height: 80vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color:#FFF;\r\n}\r\n\r\n.Login_card__uADA1{\r\n    box-shadow: 0px 0px 9px 5px #676867ab;\r\n    border-radius: 5px;\r\n    width: 42%;\r\n    height: auto;\r\n}\r\n\r\n.Login_form__Pgtad{\r\n    width: 100%;\r\n    margin:  2em 0;\r\n}\r\n\r\n@media (pointer: coarse){\r\n    .Login_card__uADA1{\r\n        height: auto;\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/components/pages/login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".loginContainer{\r\n    width: 100%;\r\n    display: flex;\r\n    height: 80vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color:#FFF;\r\n}\r\n\r\n.card{\r\n    box-shadow: 0px 0px 9px 5px #676867ab;\r\n    border-radius: 5px;\r\n    width: 42%;\r\n    height: auto;\r\n}\r\n\r\n.form{\r\n    width: 100%;\r\n    margin:  2em 0;\r\n}\r\n\r\n@media (pointer: coarse){\r\n    .card{\r\n        height: auto;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "Login_loginContainer__6Iu2f",
	"card": "Login_card__uADA1",
	"form": "Login_form__Pgtad"
};
export default ___CSS_LOADER_EXPORT___;
