import { useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import Modal from 'react-responsive-modal';
import styles from './ConfirmResendModal.module.css';

const ConfirmResendModal = ({ setOpenModal, eventConfirm, data, proposalId, MAX_ATTEMPTS }) => {
    const [open, setOpen] = useState(true);

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }
    const onEventConfirm = () => {
        eventConfirm(data, proposalId)
        closedModal();
    }
    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalCreateContainer}>
                <AiOutlineWarning />
                <h2>Você está refazendo um novo reenvio!</h2>
                <p>Você tem certeza que deseja fazer o reenvio?</p>
                <p><strong>Limites: {MAX_ATTEMPTS} </strong> / <strong>Tentivas: {data?.resendAmount || 0}</strong></p>
                <div className={styles.actionsButtons}>
                    {<button onClick={closedModal}>Não desejo reenviar</button>}
                    <button onClick={() => onEventConfirm()}>Sim, desejo reenviar!</button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmResendModal