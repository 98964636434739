import styles from './PageForbidden.module.css'

function PageForbidden() {
    return (
        <div className={styles.pageNotFoundContainer}>
            <h1>403</h1>
            <h2>Sem permissão</h2>
            <p>Entre em contato com o administrador e peça permissões de acesso ao perfil.</p>
        </div>
    )
}

export default PageForbidden;