// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateUnavailabilityModal_modalContainer__ReW\\+j h2{\r\n\r\n    margin-left: 1rem;\r\n    color:#1c7932;\r\n    font-size: 20px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/project/createUnavailabilityModal/CreateUnavailabilityModal.module.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,aAAa;IACb,eAAe;AACnB","sourcesContent":[".modalContainer h2{\r\n\r\n    margin-left: 1rem;\r\n    color:#1c7932;\r\n    font-size: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "CreateUnavailabilityModal_modalContainer__ReW+j"
};
export default ___CSS_LOADER_EXPORT___;
