import styles from './ChangeSignatureToManualModal.module.css'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai'
import api from '../../../services/api';
import { checkJwt } from '../../../helpers/Jwt-helper';
import { useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useRef } from 'react';


function ChangeSignatureToManualModal({ setOpenModal, data, updateData, setRemoveLoading }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const buttonStartRef = useRef(null);

    const closedModal = useCallback(() => {
        setOpen(false);
        setOpenModal(false);
    }, [setOpen, setOpenModal])

    const handleChangeSignatureToManual = () => {
        checkJwt(navigate).then((response) => {
            if (response) {
                changeSignatureToManual(data.id);
            }
        })
    }

    async function changeSignatureToManual(id) {
        setRemoveLoading(false);
        await api.patch(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_CHANGE_SIGNATURE_TO_MANUAL}${id}/to-manual`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            toast.success(`${response?.data?.message || "Sucesso!"}`);
            updateData(response?.data?.object, 200);
            setRemoveLoading(true);
            closedModal();
        }).catch((error) => {
            setRemoveLoading(true);
            console.log(error);
            updateData(error?.response?.data?.object, 0);
            closedModal();
            toast.error(`Deu erro! ${error?.response?.data?.message}`);
        })
    }
    useEffect(() => {
        return () => closedModal();
    }, [closedModal])
    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalChangeSignatureToManualModalContainer} onLoad={() => buttonStartRef.current.focus()}>
                <AiOutlineWarning />
                <h2>Você está colocando uma assinatura digital como manual!</h2>
                <p>Você tem certeza que deseja colocar como manual a assinatura da</p>
                <strong>proposta {data?.data?.proposalCode}?</strong>
                <div className={styles.actionsButtons}>
                    <button onClick={closedModal} selected ref={buttonStartRef} onLoadStart={() => buttonStartRef.current.focus()}>Não quero colocar como manual!</button>
                    <button onClick={() => handleChangeSignatureToManual()}>Sim, colocar como manual!</button>
                </div>
            </div>
        </Modal>
    );
}

export default ChangeSignatureToManualModal;