import React, { useContext, useEffect, useState } from 'react'
import { RoleContext } from '../../../hooks/HookUseContext';
import PartnerScreen from './partnerScreen/PartnerScreen';
import Tab from '../../../tab/Tab';
import Incidents from './incidentsScreen/Incidents';
import AutomaticReprocessingScreen from './automaticReprocessingScreen/AutomaticReprocessingScreen';
import ChangeStatusScreen from './changeStatusScreen/ChangeStatusScreen';

const Main = () => {
    const { roleValue } = useContext(RoleContext);
    const [tabName, setTabName] = useState([]);
    const [tabInfo, setTabInfo] = useState([]);


    useEffect(() => {
        const names = []
        const infos = []

        if (roleValue?.includes("INCIDENTS_READ")) {
            names.push("Incidentes")
            infos.push(<>{<Incidents />}</>)
        }

        if (roleValue?.includes("INCIDENTS_PARTNER_NOTIFICATION_READ")) {
            names.push("Notificação Parceiro")
            infos.push(<>{<PartnerScreen />}</>)
        }

        if (roleValue?.includes("INCIDENTS_AUTOMATIC_REPROCESSING_READ")) {
            names.push("Reprocessamento Automático")
            infos.push(<>{<AutomaticReprocessingScreen />}</>)
        }

        if (roleValue?.includes("CHANGE_STATUS_PROPOSAL_STAGE")) {
            names.push("Alterar Status")
            infos.push(<>{<ChangeStatusScreen />}</>)
        }


        setTabName(names);
        setTabInfo(infos);
    }, [])


    return (
        <div style={{ width: "100%" }}>
            <Tab
                tabsName={tabName}
                tabsInfo={tabInfo}
            />
        </div>
    )
}

export default Main