import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import styles from './ViewImage.module.css';
import { FaFileDownload } from 'react-icons/fa';
// useContext
import { useContext } from 'react';
import { RoleContext } from '../../hooks/HookUseContext';

export default function ViewImage() {
    const { roleValue } = useContext(RoleContext);
    const { id } = useParams();
    const [url, setUrl] = useState(null);
    const [base64, setBase64] = useState(null);
    const [name, setName] = useState(null);

    const downloadImage = () => {
        const a = document.createElement('a')
        a.download = `${name}`
        a.href = 'data:image/gif;base64,' + base64;
        a.click()

    }

    const getDocument = useCallback(() => {
        if (id) {
            try {
                api.get(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_MONITORING_DOCUMENT_BY_ID}/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Cache-Control': 'no-cache'
                    },
                }).then((response) => {
                    setUrl(`data:application/pdf;base64,${response?.data?.content}`);
                    setName(response?.data?.name);
                    setBase64(response?.data?.content);
                })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        }
    }, [id])
    useEffect(() => {
        getDocument();
    }, [getDocument]);

    return (

        <div className={styles.container}>
            {url ? (
                <>
                    {roleValue?.includes("DOCUMENT_IMAGEM_COPY") && base64 && name && <button className={styles.download} onClick={downloadImage}><FaFileDownload />Baixar</button>}
                    {(roleValue?.includes("DOCUMENT_CLIENT_READ")) ? (
                        <div className={styles.containerCanvas}>
                            <embed src={url} type="image/jpg" width="100%" height="100%" />
                        </div>
                    ) : 'Sem permisão para visualizar o documento!'}
                </>
            ) : "Carregando..."}

        </div>
    )
}


