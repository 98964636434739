import { useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import Modal from 'react-responsive-modal';
import styles from './ConfirmCombatModal.module.css';
import { checkJwt } from '../../../helpers/Jwt-helper';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../../layout/loading/Loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ConfirmCombatModal = ({ data, setDataTable, setOpenModal, search }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [removeLoading, setRemoveLoading] = useState(true);

    function closedModal() {
        setOpen(false);
        setOpenModal(false);
    }

    const handleCombat = () => {
        checkJwt(navigate).then((response) => {
            if (response) {
                combat();
            }
        })
    }

    const combat = async () => {
        try {
            setRemoveLoading(false);
            await api.post(`${process.env.REACT_APP_MONITORING_BASE_URL}${process.env.REACT_APP_AUTH_TOKEN_COMBAT}/${data?.proposalCode}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache'

                },
            }).then((response) => {
                setDataTable(response.data);
                setRemoveLoading(true);
                toast.success(`Sucesso para fazer o combate!`)
                setTimeout(search, 5000);
                closedModal();
            })
                .catch((error) => {
                    setDataTable([])
                    console.log(error)
                    setRemoveLoading(true)
                    toast.error(`${error?.response?.data?.message || error?.response?.data?.STATUS?.ERROS?.ERRO[0]?.MENSAGEM || error?.response?.data} `);
                })
        } catch (error) {
            console.log(error)
            console.log(error.response)
        }
    }

    return (
        <Modal open={open} center onClose={closedModal} showCloseIcon={false} classNames={
            {
                modal: `${styles.modalContainer}`,
            }
        }>
            <div className={styles.modalCreateContainer}>
                {!removeLoading && <Loading />}
                {removeLoading && (
                    <>
                        <AiOutlineWarning />
                        <h2>Você está fazendo uma ação de combate!</h2>
                        <p>Você tem certeza?</p>
                        <div className={styles.actionsButtons}>
                            {<button onClick={closedModal}>Não desejo fazer o combate</button>}
                            <button onClick={handleCombat}>Sim, desejo fazer o combate!</button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ConfirmCombatModal