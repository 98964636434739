// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalPartnerInformation_modalPartnerInformationContainer__kqRh5 .ModalPartnerInformation_columnInformation__Xp\\+8A {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1em;\r\n    width: 100%;\r\n}\r\n\r\n.ModalPartnerInformation_modalPartnerInformationContainer__kqRh5 .ModalPartnerInformation_columnInformation__Xp\\+8A .ModalPartnerInformation_rowsInformation__sDveY {\r\n    display: grid;\r\n    grid-template-columns: repeat(4, 1fr);\r\n    gap: 1em;\r\n    align-items: flex-start;\r\n\r\n}\r\n\r\n.ModalPartnerInformation_modalPartnerInformationContainer__kqRh5 h2 {\r\n    color: #00763d;\r\n}", "",{"version":3,"sources":["webpack://./src/components/pages/incidents/screens/partnerScreen/modalPartnerInformation/ModalPartnerInformation.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,QAAQ;IACR,uBAAuB;;AAE3B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".modalPartnerInformationContainer .columnInformation {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1em;\r\n    width: 100%;\r\n}\r\n\r\n.modalPartnerInformationContainer .columnInformation .rowsInformation {\r\n    display: grid;\r\n    grid-template-columns: repeat(4, 1fr);\r\n    gap: 1em;\r\n    align-items: flex-start;\r\n\r\n}\r\n\r\n.modalPartnerInformationContainer h2 {\r\n    color: #00763d;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalPartnerInformationContainer": "ModalPartnerInformation_modalPartnerInformationContainer__kqRh5",
	"columnInformation": "ModalPartnerInformation_columnInformation__Xp+8A",
	"rowsInformation": "ModalPartnerInformation_rowsInformation__sDveY"
};
export default ___CSS_LOADER_EXPORT___;
